body {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.625em !important;
  /* overflow: hidden !important; */
}

.home-sliderr .slide-in-h2 {
  font-size: 34px !important;
  width: 500px !important;
}

.home-sliderr .slide-in-p {
  font-size: 20px !important;
  width: 480px !important;
}

.hero-slider-content {
  /* display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; */
  padding-top: 100px !important;
}

.hero-slider-image {
  padding-top: 120px !important;
}

.container {
  max-width: 1140px;
}

.container .h1 {
  text-align: center;
}

.home {
  text-align: center;
  padding: 50px 0px;
}

.control-dots,
.carousel-status {
  display: block !important;
}

/* Header css  */
.scrolled .navbar {
  background-color: white !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Add shadow */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  /* Include box-shadow transition */
}

.scrolled .navbar a {
  color: gray !important;
  transition: color 0.3s ease;
}

.scrolled-2 .navbar a {
  color: gray !important;
  transition: color 0.3s ease;
}

.hamburger {
  display: none;
}

@media (max-width: 990px) {
  .hamburger {
    display: block;
  }
}

/* ScrollToTop.css */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  /* Adjust as needed */
  right: 20px;
  /* Adjust as needed */
  background-color: #4975d0;
  /* Example background color */
  color: #fff;
  /* Example text color */
  padding: 10px 15px;
  border-radius: 10%;
  cursor: pointer;
  z-index: 1000;
  /* Ensure it's above other content */
}

.scroll-to-top:hover {
  background-color: #6a82b2;
  /* Example hover color */
}

.screenshot-wrap {
  padding: 0 15px;
  /* Adjust the padding as needed */
}

.screenshot-wrap img {
  width: 100%;
  border-radius: 10px;
  /* border: 1px solid red; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  /* Add shadow */
}

.hero-slider-animation {
  display: flex;
  align-items: center;
}

.laris-hero-slider-content {
  /* display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; */
  padding-top: 150px !important;
}

.hero-slider-padding {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
}
.laris-hero-slider-padding {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
}
.laris-slider-background {
  height: 50rem;
}

.hero-slider-padding .slick-prev:before {
  color: #1b2e4b !important;
}
.hero-slider-padding .slick-next:before {
  color: #1b2e4b !important;
}

.webBuilder_slider_container {
  padding: 20px 20px 0px 20px; /* Adjust the padding as needed */
}

.webBuilder_slider_content h2 {
  color: #ffffff;
}
.webBuilder_slider_content p {
  color: #ffffff;
}
.webBuilder_slider_content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.webbuilder-choose-cards {
  text-align: center !important;
  padding: 30px !important;
  min-height: 330px !important;
  border-radius: 5px !important;
  margin-bottom: 30px !important;
}

.webbuilder-choose-cards a {
  cursor: pointer !important;
  color: #fff !important;
}
.webbuilder-choose-cards p span {
  cursor: pointer !important;
  font-weight: bold !important;
  color: #1b2e4b !important;
  font-size: 14px !important;
}

.webbuilder-choose-cards:hover h5 {
  color: #ffffff !important;
}
.webbuilder-choose-cards:hover p span {
  color: #adcbf7 !important;
}

.webbuilder-cards-icon {
  position: relative;
  display: inline-block;
}

.webbuilder-cards-text {
  visibility: hidden;
  width: 120px;
  /* background-color: #1b2e4b; */
  background-color: #1b2e4b;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  padding: 2px;
  position: absolute;
  z-index: 1;
  bottom: 130%; /* Adjust as needed */
  left: 50%;
  margin-left: -65px; /* Half of the width */
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Adding shadow effect */
}

.webbuilder-cards-text::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1b2e4b transparent transparent transparent;
}

.webbuilder-cards-icon:hover .webbuilder-cards-text {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 768px) {
  .hero-slider-wrapper {
    padding-top: 50px !important;
  }

  .hero-slider-wrapper h2 {
    font-size: 26px !important;
  }

  .hero-slider-content {
    padding-top: 10px !important;
  }

  .laris-hero-slider-content {
    padding-top: 10px !important;
  }

  .home-sliderr .slide-in-h2 {
    font-size: 25px !important;
  }

  .home-sliderr .slide-in-p {
    width: 100% !important;
  }
  .hero-slider-content {
    padding-top: 0px !important;
    margin-top: 40px !important;
  }

  .hero-slider-image {
    padding-top: 40px !important;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

/* .slide-in {
    animation: slideInFromBottom 1.5s ease forwards;
} */
.slide-in-p {
  animation: slideInFromBottom 4s ease forwards;
}

.slide-in-image {
  animation: slideInFromBottom 4s ease forwards;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

/* .slide-in-h2 {
    animation: slideInFromLeft 1.5s ease forwards;
} */

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.slide-in-h2 {
  animation: slideInFromTop 2s ease forwards;
}

.price-table-wrapper .pricing-table {
  width: 100%;
  padding: 32px;
}

.price-table-wrapper .pricing-table .expand-btn {
  bottom: 0;
  width: 100%;
  text-align: center;
  background-image: linear-gradient(#f5f5f559, whitesmoke);
}

.p-4 {
  padding: 0 16px;
}

.price-table-wrapper .pricing-table thead,
.pricing-table tbody {
  padding: 32px 0;
}

.price-table-wrapper .pricing-table thead td {
  padding: 32px 0px;
  max-width: 100px;
  /* vertical-align: bottom; */
}

.price-table-wrapper .pricing-table td p {
  margin-bottom: 0px;
}

.price-table-wrapper .pricing-table td h5 {
  /*padding: 32px 0px;*/
  /*max-width: 100px;*/
  margin-bottom: 1px;
  /* vertical-align: bottom; */
}

.price-table-wrapper .pricing-table tbody tr {
  border-bottom: dotted 3px #dddddd;
}

/* .price-table-wrapper .pricing-table tbody tr td {
    border-bottom: dotted 3px #dddddd;
  }
   */
.price-table-wrapper .pricing-table thead td h2 {
  font-size: 40px;
}

.price-table-wrapper .pricing-table td p {
  font-size: 14px;
  color: gray;
}

.price-table-wrapper .pricing-table td {
  padding: 16px 0;
  max-width: 100px;
}

.price-table-wrapper .pricing-table td .pkg-name {
  text-align: center;
  font-weight: bold;
  padding: 8px;
}

.green {
  color: #167931;
}

.blue {
  color: #14378a;
}

.pink {
  color: #811d45;
}

.bg-green {
  /* background-color: #167931; */
  background: linear-gradient(
    90deg,
    rgba(164, 254, 93, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}

.bg-blue {
  /* background-color: #14378a; */
  background: linear-gradient(
    90deg,
    rgba(46, 214, 255, 1) 0%,
    rgba(122, 146, 249, 1) 100%
  );
}

.bg-pink {
  /* background-color: #811d45; */
  background: linear-gradient(
    90deg,
    rgba(246, 112, 175, 1) 0%,
    rgba(173, 125, 244, 1) 100%
  );
}

.price-table-wrapper .pricing-table td .vll {
  width: 50px;
  height: 2px;
  margin: 16px 0;
  background: #000;
}

.price-table-wrapper .pricing-table td .vl {
  width: 28px;
  height: 2px;
  margin: 8px auto;
}

.price-table-wrapper .pricing-table td .price-box {
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 8px;
  color: white;
  border-radius: 999px;
  padding: 4px;
  margin-top: 16px;
}

.price-table-wrapper .pricing-table td .cur,
.price-table-wrapper .pricing-table td .price-box .price-dur {
  font-size: 14px;
  line-height: 1;
}

.price-table-wrapper .pricing-table td .price-box .price {
  font-size: 34px;
  line-height: 1;
}

.price-table-wrapper .pricing-table td .price-box .price-dur {
  align-self: self-end;
}

.price-table-wrapper .pricing-table .tab-check {
  text-align: center;
  font-size: 24px;
  vertical-align: middle;
}

@media (max-width: 766px) {
  .price-table-wrapper .pricing-table td .price-box .price {
    font-size: 24px;
    line-height: 1;
  }
}

/* 
.hero-slider-animation .animated {
    animation: 2s anim-lineUp ease-out;
  }
  
  @keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  } */

/* @charset "UTF-8"; */

/* line 6, src/assets/scss/generic/_generic.reset.scss */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
/* line 27, src/assets/scss/generic/_generic.reset.scss */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/* line 31, src/assets/scss/generic/_generic.reset.scss */
body {
  line-height: 1;
}

/* line 34, src/assets/scss/generic/_generic.reset.scss */
ol,
ul {
  list-style: none;
}

/* line 37, src/assets/scss/generic/_generic.reset.scss */
blockquote,
q {
  quotes: none;
}

/* line 40, src/assets/scss/generic/_generic.reset.scss */
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/* line 45, src/assets/scss/generic/_generic.reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* line 3, src/assets/scss/generic/_generic.box-sizing.scss */
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 10, src/assets/scss/generic/_generic.box-sizing.scss */
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

@media (min-width: 320px) and (max-width: 768px) {
  /* line 19, src/assets/scss/generic/_generic.box-sizing.scss */
  html {
    font-size: 88%;
  }
}

/* line 2, node_modules/bootstrap/scss/_root.scss */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

/* line 19, node_modules/bootstrap/scss/_reboot.scss */
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 25, node_modules/bootstrap/scss/_reboot.scss */
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* line 35, node_modules/bootstrap/scss/_reboot.scss */
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

/* line 46, node_modules/bootstrap/scss/_reboot.scss */
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 0.938rem;
  font-weight: 400;
  line-height: 1.75;
  color: #696969;
  text-align: left;
  background-color: #fff;
}

/* line 66, node_modules/bootstrap/scss/_reboot.scss */
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

/* line 76, node_modules/bootstrap/scss/_reboot.scss */
hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

/* line 92, node_modules/bootstrap/scss/_reboot.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.938rem;
}

/* line 101, node_modules/bootstrap/scss/_reboot.scss */
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* line 114, node_modules/bootstrap/scss/_reboot.scss */
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

/* line 123, node_modules/bootstrap/scss/_reboot.scss */
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

/* line 129, node_modules/bootstrap/scss/_reboot.scss */
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* line 136, node_modules/bootstrap/scss/_reboot.scss */
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

/* line 143, node_modules/bootstrap/scss/_reboot.scss */
dt {
  font-weight: 700;
}

/* line 147, node_modules/bootstrap/scss/_reboot.scss */
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

/* line 152, node_modules/bootstrap/scss/_reboot.scss */
blockquote {
  margin: 0 0 1rem;
}

/* line 156, node_modules/bootstrap/scss/_reboot.scss */
b,
strong {
  font-weight: bolder;
}

/* line 161, node_modules/bootstrap/scss/_reboot.scss */
small {
  font-size: 80%;
}

/* line 170, node_modules/bootstrap/scss/_reboot.scss */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

/* line 178, node_modules/bootstrap/scss/_reboot.scss */
sub {
  bottom: -0.25em;
}

/* line 179, node_modules/bootstrap/scss/_reboot.scss */
sup {
  top: -0.5em;
}

/* line 186, node_modules/bootstrap/scss/_reboot.scss */
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
a:hover {
  color: #0056b3;
  text-decoration: none;
}

/* line 202, node_modules/bootstrap/scss/_reboot.scss */
a:not([href]) {
  color: inherit;
  text-decoration: none;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

/* line 217, node_modules/bootstrap/scss/_reboot.scss */
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

/* line 225, node_modules/bootstrap/scss/_reboot.scss */
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

/* line 242, node_modules/bootstrap/scss/_reboot.scss */
figure {
  margin: 0 0 1rem;
}

/* line 252, node_modules/bootstrap/scss/_reboot.scss */
img {
  vertical-align: middle;
  border-style: none;
}

/* line 257, node_modules/bootstrap/scss/_reboot.scss */
svg {
  overflow: hidden;
  vertical-align: middle;
}

/* line 269, node_modules/bootstrap/scss/_reboot.scss */
table {
  border-collapse: collapse;
}

/* line 273, node_modules/bootstrap/scss/_reboot.scss */
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

/* line 281, node_modules/bootstrap/scss/_reboot.scss */
th {
  text-align: inherit;
}

/* line 292, node_modules/bootstrap/scss/_reboot.scss */
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

/* line 301, node_modules/bootstrap/scss/_reboot.scss */
button {
  border-radius: 0;
}

/* line 310, node_modules/bootstrap/scss/_reboot.scss */
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

/* line 315, node_modules/bootstrap/scss/_reboot.scss */
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/* line 326, node_modules/bootstrap/scss/_reboot.scss */
button,
input {
  overflow: visible;
}

/* line 331, node_modules/bootstrap/scss/_reboot.scss */
button,
select {
  text-transform: none;
}

/* line 339, node_modules/bootstrap/scss/_reboot.scss */
[role="button"] {
  cursor: pointer;
}

/* line 346, node_modules/bootstrap/scss/_reboot.scss */
select {
  word-wrap: normal;
}

/* line 354, node_modules/bootstrap/scss/_reboot.scss */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/* line 367, node_modules/bootstrap/scss/_reboot.scss */
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

/* line 374, node_modules/bootstrap/scss/_reboot.scss */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

/* line 382, node_modules/bootstrap/scss/_reboot.scss */
input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

/* line 389, node_modules/bootstrap/scss/_reboot.scss */
textarea {
  overflow: auto;
  resize: vertical;
}

/* line 395, node_modules/bootstrap/scss/_reboot.scss */
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

/* line 410, node_modules/bootstrap/scss/_reboot.scss */
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

/* line 422, node_modules/bootstrap/scss/_reboot.scss */
progress {
  vertical-align: baseline;
}

/* line 427, node_modules/bootstrap/scss/_reboot.scss */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/* line 432, node_modules/bootstrap/scss/_reboot.scss */
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

/* line 445, node_modules/bootstrap/scss/_reboot.scss */
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* line 454, node_modules/bootstrap/scss/_reboot.scss */
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

/* line 463, node_modules/bootstrap/scss/_reboot.scss */
output {
  display: inline-block;
}

/* line 467, node_modules/bootstrap/scss/_reboot.scss */
summary {
  display: list-item;
  cursor: pointer;
}

/* line 472, node_modules/bootstrap/scss/_reboot.scss */
template {
  display: none;
}

/* line 478, node_modules/bootstrap/scss/_reboot.scss */
[hidden] {
  display: none !important;
}

/* line 7, node_modules/bootstrap/scss/_type.scss */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.938rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 1.25;
  color: #1b2e4b;
}

/* line 16, node_modules/bootstrap/scss/_type.scss */
h1,
.h1 {
  font-size: 2.5795rem;
}

/* line 17, node_modules/bootstrap/scss/_type.scss */
h2,
.h2 {
  font-size: 2.2043rem;
}

/* line 18, node_modules/bootstrap/scss/_type.scss */
h3,
.h3 {
  font-size: 1.6415rem;
}

/* line 19, node_modules/bootstrap/scss/_type.scss */
h4,
.h4 {
  font-size: 1.407rem;
}

/* line 20, node_modules/bootstrap/scss/_type.scss */
h5,
.h5 {
  font-size: 1.1725rem;
}

/* line 21, node_modules/bootstrap/scss/_type.scss */
h6,
.h6 {
  font-size: 0.938rem;
}

/* line 23, node_modules/bootstrap/scss/_type.scss */
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

/* line 29, node_modules/bootstrap/scss/_type.scss */
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

/* line 34, node_modules/bootstrap/scss/_type.scss */
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

/* line 39, node_modules/bootstrap/scss/_type.scss */
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

/* line 44, node_modules/bootstrap/scss/_type.scss */
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

/* line 55, node_modules/bootstrap/scss/_type.scss */
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* line 67, node_modules/bootstrap/scss/_type.scss */
small,
.small {
  font-size: 80%;
  font-weight: 400;
}

/* line 73, node_modules/bootstrap/scss/_type.scss */
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

/* line 84, node_modules/bootstrap/scss/_type.scss */
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

/* line 89, node_modules/bootstrap/scss/_type.scss */
.list-inline {
  padding-left: 0;
  list-style: none;
}

/* line 92, node_modules/bootstrap/scss/_type.scss */
.list-inline-item {
  display: inline-block;
}

/* line 95, node_modules/bootstrap/scss/_type.scss */
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

/* line 106, node_modules/bootstrap/scss/_type.scss */
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

/* line 112, node_modules/bootstrap/scss/_type.scss */
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

/* line 117, node_modules/bootstrap/scss/_type.scss */
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

/* line 122, node_modules/bootstrap/scss/_type.scss */
.blockquote-footer::before {
  content: "\2014\00A0";
}

/* line 7, node_modules/bootstrap/scss/_forms.scss */
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e9f2;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  /* line 7, node_modules/bootstrap/scss/_forms.scss */
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 28, node_modules/bootstrap/scss/_forms.scss */
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

/* line 34, node_modules/bootstrap/scss/_forms.scss */
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

/* line 14, node_modules/bootstrap/scss/mixins/_forms.scss */
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* line 43, node_modules/bootstrap/scss/_forms.scss */
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

/* line 54, node_modules/bootstrap/scss/_forms.scss */
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

/* line 66, node_modules/bootstrap/scss/_forms.scss */
input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* line 72, node_modules/bootstrap/scss/_forms.scss */
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

/* line 84, node_modules/bootstrap/scss/_forms.scss */
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

/* line 97, node_modules/bootstrap/scss/_forms.scss */
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

/* line 105, node_modules/bootstrap/scss/_forms.scss */
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

/* line 112, node_modules/bootstrap/scss/_forms.scss */
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

/* line 125, node_modules/bootstrap/scss/_forms.scss */
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

/* line 137, node_modules/bootstrap/scss/_forms.scss */
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

/* line 152, node_modules/bootstrap/scss/_forms.scss */
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

/* line 160, node_modules/bootstrap/scss/_forms.scss */
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

/* line 170, node_modules/bootstrap/scss/_forms.scss */
select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

/* line 176, node_modules/bootstrap/scss/_forms.scss */
textarea.form-control {
  height: auto;
}

/* line 185, node_modules/bootstrap/scss/_forms.scss */
.form-group {
  margin-bottom: 1rem;
}

/* line 189, node_modules/bootstrap/scss/_forms.scss */
.form-text {
  display: block;
  margin-top: 0.25rem;
}

/* line 199, node_modules/bootstrap/scss/_forms.scss */
.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

/* line 205, node_modules/bootstrap/scss/_forms.scss */
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

/* line 217, node_modules/bootstrap/scss/_forms.scss */
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

/* line 223, node_modules/bootstrap/scss/_forms.scss */
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

/* line 229, node_modules/bootstrap/scss/_forms.scss */
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

/* line 235, node_modules/bootstrap/scss/_forms.scss */
.form-check-label {
  margin-bottom: 0;
}

/* line 239, node_modules/bootstrap/scss/_forms.scss */
.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

/* line 246, node_modules/bootstrap/scss/_forms.scss */
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

/* line 45, node_modules/bootstrap/scss/mixins/_forms.scss */
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

/* line 53, node_modules/bootstrap/scss/mixins/_forms.scss */
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

/* line 69, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* line 87, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}

/* line 113, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

/* line 122, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

/* line 126, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

/* line 135, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

/* line 138, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

/* line 144, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

/* line 151, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

/* line 155, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #28a745;
}

/* line 165, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

/* line 170, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

/* line 45, node_modules/bootstrap/scss/mixins/_forms.scss */
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

/* line 53, node_modules/bootstrap/scss/mixins/_forms.scss */
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

/* line 69, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* line 87, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}

/* line 113, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* line 122, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

/* line 126, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

/* line 135, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

/* line 138, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

/* line 144, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

/* line 151, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* line 155, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #dc3545;
}

/* line 165, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

/* line 170, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* line 275, node_modules/bootstrap/scss/_forms.scss */
.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 283, node_modules/bootstrap/scss/_forms.scss */
.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  /* line 289, node_modules/bootstrap/scss/_forms.scss */
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }

  /* line 297, node_modules/bootstrap/scss/_forms.scss */
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  /* line 306, node_modules/bootstrap/scss/_forms.scss */
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  /* line 313, node_modules/bootstrap/scss/_forms.scss */
  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  /* line 317, node_modules/bootstrap/scss/_forms.scss */
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  /* line 324, node_modules/bootstrap/scss/_forms.scss */
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  /* line 331, node_modules/bootstrap/scss/_forms.scss */
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  /* line 339, node_modules/bootstrap/scss/_forms.scss */
  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  /* line 343, node_modules/bootstrap/scss/_forms.scss */
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

/* line 1, src/assets/scss/elements/_elements.forms.scss */
.form-control {
  height: calc(2.4em + 0.75rem + 2px);
  font-size: 14px;
}

/* line 4, src/assets/scss/elements/_elements.forms.scss */
.form-control:focus,
.form-control:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 11, src/assets/scss/elements/_elements.forms.scss */
*::-webkit-input-placeholder {
  color: #8392a5 !important;
  font-size: 13px;
}

/* line 15, src/assets/scss/elements/_elements.forms.scss */
*:-moz-placeholder {
  /* FF 4-18 */
  color: #8392a5 !important;
  font-size: 13px;
  opacity: 1;
}

/* line 21, src/assets/scss/elements/_elements.forms.scss */
*::-moz-placeholder {
  /* FF 19+ */
  color: #8392a5 !important;
  font-size: 13px;
  opacity: 1;
}

/* line 27, src/assets/scss/elements/_elements.forms.scss */
*:-ms-input-placeholder {
  /* IE 10+ */
  color: #8392a5 !important;
  font-size: 13px;
}

/* line 32, src/assets/scss/elements/_elements.forms.scss */
*::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8392a5 !important;
  font-size: 13px;
}

/* line 37, src/assets/scss/elements/_elements.forms.scss */
*::-webkit-input-placeholder {
  /* modern browser */
  color: #8392a5 !important;
  font-size: 13px;
}

*::-moz-placeholder {
  /* modern browser */
  color: #8392a5 !important;
  font-size: 13px;
}

*:-ms-input-placeholder {
  /* modern browser */
  color: #8392a5 !important;
  font-size: 13px;
}

*::-ms-input-placeholder {
  /* modern browser */
  color: #8392a5 !important;
  font-size: 13px;
}

*::placeholder {
  /* modern browser */
  color: #8392a5 !important;
  font-size: 13px;
}

/* line 3, src/assets/scss/elements/_elements.links.scss */
a {
  color: #0c2888;
  text-decoration: none;
}

/* line 7, src/assets/scss/elements/_elements.links.scss */
a:hover {
  color: #0c2888;
}

/* line 3, src/assets/scss/elements/_elements.lists.scss */
ul:last-child {
  margin: 0;
}

/* line 7, src/assets/scss/elements/_elements.lists.scss */
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

/* line 15, src/assets/scss/elements/_elements.lists.scss */
dt {
  font-weight: bold;
}

/* line 19, src/assets/scss/elements/_elements.lists.scss */
dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* line 3, src/assets/scss/elements/_elements.quotes.scss */
blockquote {
  quotes: "“" "”";
}

/* line 6, src/assets/scss/elements/_elements.quotes.scss */
blockquote p {
  text-indent: -0.42em;
}

/* line 9, src/assets/scss/elements/_elements.quotes.scss */
blockquote p:before {
  content: open-quote;
}

/* line 13, src/assets/scss/elements/_elements.quotes.scss */
blockquote p:after {
  content: no-close-quote;
}

/* line 17, src/assets/scss/elements/_elements.quotes.scss */
blockquote p:last-of-type {
  margin-bottom: 0;
}

/* line 20, src/assets/scss/elements/_elements.quotes.scss */
blockquote p:last-of-type:after {
  content: close-quote;
}

/**
 * 1. Ensure tables fill up as much space as possible.
 */
/* line 6, src/assets/scss/elements/_elements.tables.scss */
table {
  width: 100%;
  /* [1] */
}

/* line 7, node_modules/bootstrap/scss/_grid.scss */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  /* line 7, node_modules/bootstrap/scss/_grid.scss */
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  /* line 7, node_modules/bootstrap/scss/_grid.scss */
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  /* line 7, node_modules/bootstrap/scss/_grid.scss */
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  /* line 7, node_modules/bootstrap/scss/_grid.scss */
  .container {
    max-width: 1140px;
  }
}

/* line 13, node_modules/bootstrap/scss/_grid.scss */
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  /* line 24, node_modules/bootstrap/scss/_grid.scss */
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  /* line 24, node_modules/bootstrap/scss/_grid.scss */
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  /* line 24, node_modules/bootstrap/scss/_grid.scss */
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  /* line 24, node_modules/bootstrap/scss/_grid.scss */
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

/* line 53, node_modules/bootstrap/scss/_grid.scss */
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

/* line 59, node_modules/bootstrap/scss/_grid.scss */
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

/* line 63, node_modules/bootstrap/scss/_grid.scss */
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

/* line 8, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

/* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

/* line 49, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

/* line 61, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

/* line 63, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-1 {
  margin-left: 8.33333%;
}

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-2 {
  margin-left: 16.66667%;
}

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-3 {
  margin-left: 25%;
}

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-4 {
  margin-left: 33.33333%;
}

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-5 {
  margin-left: 41.66667%;
}

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-6 {
  margin-left: 50%;
}

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-7 {
  margin-left: 58.33333%;
}

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-8 {
  margin-left: 66.66667%;
}

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-9 {
  margin-left: 75%;
}

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-10 {
  margin-left: 83.33333%;
}

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  /* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 49, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 61, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  /* line 63, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-0 {
    margin-left: 0;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-3 {
    margin-left: 25%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-6 {
    margin-left: 50%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-9 {
    margin-left: 75%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  /* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 49, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 61, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  /* line 63, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-0 {
    margin-left: 0;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-1 {
    margin-left: 8.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-2 {
    margin-left: 16.66667%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-3 {
    margin-left: 25%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-4 {
    margin-left: 33.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-5 {
    margin-left: 41.66667%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-6 {
    margin-left: 50%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-7 {
    margin-left: 58.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-8 {
    margin-left: 66.66667%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-9 {
    margin-left: 75%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-10 {
    margin-left: 83.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  /* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 49, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 61, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  /* line 63, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-0 {
    margin-left: 0;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-3 {
    margin-left: 25%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-6 {
    margin-left: 50%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-9 {
    margin-left: 75%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  /* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 49, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* line 61, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  /* line 63, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-0 {
    margin-left: 0;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-3 {
    margin-left: 25%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-6 {
    margin-left: 50%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-9 {
    margin-left: 75%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

/* line 1, src/assets/scss/objects/_objects.custom-padding.scss */
.ptb-100 {
  padding: 100px 0;
}

/* line 4, src/assets/scss/objects/_objects.custom-padding.scss */
.pt-100 {
  padding-top: 100px;
}

/* line 7, src/assets/scss/objects/_objects.custom-padding.scss */
.pb-100 {
  padding-bottom: 100px;
}

/* line 11, src/assets/scss/objects/_objects.custom-padding.scss */
.ptb-80 {
  padding: 80px 0;
}

/* line 14, src/assets/scss/objects/_objects.custom-padding.scss */
.pt-80 {
  padding-top: 80px;
}

/* line 17, src/assets/scss/objects/_objects.custom-padding.scss */
.pb-80 {
  padding-bottom: 80px;
}

/* line 20, src/assets/scss/objects/_objects.custom-padding.scss */
.ptb-60 {
  padding: 60px 0;
}

/* line 23, src/assets/scss/objects/_objects.custom-padding.scss */
.pt-60 {
  padding-top: 60px;
}

/* line 26, src/assets/scss/objects/_objects.custom-padding.scss */
.pb-60 {
  padding-bottom: 60px;
}

/* line 30, src/assets/scss/objects/_objects.custom-padding.scss */
.ptb-40 {
  padding: 40px 0;
}

/* line 33, src/assets/scss/objects/_objects.custom-padding.scss */
.pt-40 {
  padding-top: 40px;
}

/* line 36, src/assets/scss/objects/_objects.custom-padding.scss */
.pb-40 {
  padding-bottom: 40px;
}

/* line 40, src/assets/scss/objects/_objects.custom-padding.scss */
.ptb-20 {
  padding: 20px 0;
}

/* line 43, src/assets/scss/objects/_objects.custom-padding.scss */
.pt-20 {
  padding-top: 20px;
}

/* line 46, src/assets/scss/objects/_objects.custom-padding.scss */
.pb-20 {
  padding-bottom: 20px;
}

@media (min-width: 320px) and (max-width: 992px) {
  /* line 52, src/assets/scss/objects/_objects.custom-padding.scss */
  .ptb-100 {
    padding: 55px 0;
  }

  /* line 56, src/assets/scss/objects/_objects.custom-padding.scss */
  .pt-100 {
    padding-top: 55px;
  }

  /* line 60, src/assets/scss/objects/_objects.custom-padding.scss */
  .pb-100 {
    padding-bottom: 55px;
  }

  /* line 63, src/assets/scss/objects/_objects.custom-padding.scss */
  .ptb-80 {
    padding: 45px 0;
  }

  /* line 66, src/assets/scss/objects/_objects.custom-padding.scss */
  .pt-80 {
    padding-top: 45px;
  }

  /* line 69, src/assets/scss/objects/_objects.custom-padding.scss */
  .pb-80 {
    padding-bottom: 45px;
  }

  /* line 72, src/assets/scss/objects/_objects.custom-padding.scss */
  .ptb-60 {
    padding: 30px 0;
  }

  /* line 75, src/assets/scss/objects/_objects.custom-padding.scss */
  .pt-60 {
    padding-top: 30px;
  }

  /* line 78, src/assets/scss/objects/_objects.custom-padding.scss */
  .pb-60 {
    padding-bottom: 30px;
  }

  /* line 81, src/assets/scss/objects/_objects.custom-padding.scss */
  .ptb-40 {
    padding: 20px 0;
  }

  /* line 84, src/assets/scss/objects/_objects.custom-padding.scss */
  .pt-40 {
    padding-top: 20px;
  }

  /* line 87, src/assets/scss/objects/_objects.custom-padding.scss */
  .pb-40 {
    padding-bottom: 20px;
  }
}

/*background image*/
/* line 2, src/assets/scss/objects/_objects.background-image.scss */
.bg-image {
  position: relative;
}

/* line 4, src/assets/scss/objects/_objects.background-image.scss */
.bg-image .background-image-wraper {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  opacity: 0;
}

/* line 15, src/assets/scss/objects/_objects.background-image.scss */
.bg-image .background-image-wraper img {
  display: none;
}

/* line 19, src/assets/scss/objects/_objects.background-image.scss */
.bg-image .background-image-wraper:not([class*="col-"]) {
  width: 100%;
}

/* line 23, src/assets/scss/objects/_objects.background-image.scss */
.light-bg-image {
  position: relative;
}

/* line 25, src/assets/scss/objects/_objects.background-image.scss */
.light-bg-image .background-image-wraper {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  opacity: 0;
}

/* line 36, src/assets/scss/objects/_objects.background-image.scss */
.light-bg-image .background-image-wraper img {
  display: none;
}

/* line 40, src/assets/scss/objects/_objects.background-image.scss */
.light-bg-image .background-image-wraper:not([class*="col-"]) {
  width: 100%;
}

/* line 45, src/assets/scss/objects/_objects.background-image.scss */
[image-overlay] {
  position: relative;
}

/* line 47, src/assets/scss/objects/_objects.background-image.scss */
[image-overlay]:before {
  position: absolute;
  content: "";
  background: rgba(169, 195, 247, 0.9) !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

/* line 60, src/assets/scss/objects/_objects.background-image.scss */
[data-overlay-top] {
  position: relative;
}

/* line 62, src/assets/scss/objects/_objects.background-image.scss */
/* line 62, src/assets/scss/objects/_objects.background-image.scss */
[data-overlay-top]:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 80%;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#212529),
    to(rgba(0, 0, 0, 0))
  );
  background: linear-gradient(to bottom, #212529 0%, rgba(0, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  z-index: 1;
}

/* line 85, src/assets/scss/objects/_objects.background-image.scss */
[data-overly-bottom] {
  position: relative;
}

/* line 87, src/assets/scss/objects/_objects.background-image.scss */
[data-overly-bottom]:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 80%;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    to(#212529)
  );
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #212529 100%);
  bottom: 0;
  left: 0;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* line 111, src/assets/scss/objects/_objects.background-image.scss */
[data-overlay-gradient] {
  position: relative;
}

/* line 113, src/assets/scss/objects/_objects.background-image.scss */
[data-overlay-gradient]:before {
  position: absolute;
  content: "";
  background: #0c2888;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(5%, #0c2888),
    color-stop(95%, #0c2888)
  ) !important;
  background: linear-gradient(to right, #0c2888 5%, #0c2888 95%) !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

/* line 131, src/assets/scss/objects/_objects.background-image.scss */
[image-overlay="1"]:before,
[data-overly-top="1"]:before,
[data-overly-bottom="1"]:before,
[data-overlay-gradient="1"]:before {
  opacity: 0.1;
}

/* line 137, src/assets/scss/objects/_objects.background-image.scss */
[image-overlay="2"]:before,
[data-overly-top="2"]:before,
[data-overly-bottom="2"]:before,
[data-overlay-gradient="2"]:before {
  opacity: 0.2;
}

/* line 142, src/assets/scss/objects/_objects.background-image.scss */
[image-overlay="3"]:before,
[data-overly-top="3"]:before,
[data-overly-bottom="3"]:before,
[data-overlay-gradient="3"]:before {
  opacity: 0.3;
}

/* line 148, src/assets/scss/objects/_objects.background-image.scss */
[image-overlay="4"]:before,
[data-overly-top="4"]:before,
[data-overly-bottom="4"]:before,
[data-overlay-gradient="4"]:before {
  opacity: 0.4;
}

/* line 154, src/assets/scss/objects/_objects.background-image.scss */
[image-overlay="5"]:before,
[data-overly-top="5"]:before,
[data-overly-bottom="5"]:before,
[data-overlay-gradient="5"]:before {
  opacity: 0.5;
}

/* line 160, src/assets/scss/objects/_objects.background-image.scss */
[image-overlay="6"]:before,
[data-overly-top="6"]:before,
[data-overly-bottom="6"]:before,
[data-overlay-gradient="6"]:before {
  opacity: 0.6;
}

/* line 166, src/assets/scss/objects/_objects.background-image.scss */
[image-overlay="7"]:before,
[data-overly-top="7"]:before,
[data-overly-bottom="7"]:before,
[data-overlay-gradient="7"]:before {
  opacity: 0.7;
}

/* line 171, src/assets/scss/objects/_objects.background-image.scss */
[image-overlay="8"]:before,
[data-overly-top="8"]:before,
[data-overly-bottom="8"]:before,
[data-overlay-gradient="8"]:before {
  opacity: 0.8;
}

/* line 177, src/assets/scss/objects/_objects.background-image.scss */
[image-overlay="9"]:before,
[data-overly-top="9"]:before,
[data-overly-bottom="9"]:before,
[data-overlay-gradient="9"]:before {
  opacity: 0.9;
}

/* line 183, src/assets/scss/objects/_objects.background-image.scss */
.bg-image.animated-color-bg > .background-image-wraper {
  opacity: 0.2 !important;
}

/* line 187, src/assets/scss/objects/_objects.background-image.scss */
[image-overlay] *:not(.container):not(.background-image-wraper) {
  z-index: 2;
}

/* line 191, src/assets/scss/objects/_objects.background-image.scss */
.bg-image .card-body {
  z-index: 2;
}

/* line 202, src/assets/scss/objects/_objects.background-image.scss */
.hero-bottom-shape,
.hero-bottom-shape-two {
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* line 213, src/assets/scss/objects/_objects.background-image.scss */
.background-shape-img:before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  /* background-image: url("../img/about-bg-shape.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
}

/* line 1, src/assets/scss/objects/_objects.background.scss */
.color-primary {
  color: #0c2888 !important;
}

/* line 4, src/assets/scss/objects/_objects.background.scss */
.color-secondary {
  color: #0c2888 !important;
}

/* line 7, src/assets/scss/objects/_objects.background.scss */
.color-accent {
  color: #0c2888 !important;
}

/* line 10, src/assets/scss/objects/_objects.background.scss */
.gray-light-bg {
  background: #f5f5f5 !important;
}

/* line 14, src/assets/scss/objects/_objects.background.scss */
.primary-bg {
  background: #0c2888 !important;
}

/* line 17, src/assets/scss/objects/_objects.background.scss */
.secondary-bg {
  background: #0c2888 !important;
}

/* line 20, src/assets/scss/objects/_objects.background.scss */
.accent-bg {
  background: #0c2888 !important;
}

/* line 24, src/assets/scss/objects/_objects.background.scss */
.gradient-bg {
  background: linear-gradient(
    75deg,
    rgba(98, 148, 247, 0.9),
    rgba(12, 40, 136, 0.9)
  ) !important;
}

/* line 20, node_modules/@fortawesome/fontawesome-free/scss/regular.scss */
.far {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.fab {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

/* line 5, node_modules/@fortawesome/fontawesome-free/scss/_larger.scss */
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

/* line 11, node_modules/@fortawesome/fontawesome-free/scss/_larger.scss */
.fa-xs {
  font-size: 0.75em;
}

/* line 15, node_modules/@fortawesome/fontawesome-free/scss/_larger.scss */
.fa-sm {
  font-size: 0.875em;
}

/* line 20, node_modules/@fortawesome/fontawesome-free/scss/_larger.scss */
.fa-1x {
  font-size: 1em;
}

/* line 20, node_modules/@fortawesome/fontawesome-free/scss/_larger.scss */
.fa-2x {
  font-size: 2em;
}

/* line 20, node_modules/@fortawesome/fontawesome-free/scss/_larger.scss */
.fa-3x {
  font-size: 3em;
}

/* line 20, node_modules/@fortawesome/fontawesome-free/scss/_larger.scss */
.fa-4x {
  font-size: 4em;
}

/* line 20, node_modules/@fortawesome/fontawesome-free/scss/_larger.scss */
.fa-5x {
  font-size: 5em;
}

/* line 20, node_modules/@fortawesome/fontawesome-free/scss/_larger.scss */
.fa-6x {
  font-size: 6em;
}

/* line 20, node_modules/@fortawesome/fontawesome-free/scss/_larger.scss */
.fa-7x {
  font-size: 7em;
}

/* line 20, node_modules/@fortawesome/fontawesome-free/scss/_larger.scss */
.fa-8x {
  font-size: 8em;
}

/* line 20, node_modules/@fortawesome/fontawesome-free/scss/_larger.scss */
.fa-9x {
  font-size: 9em;
}

/* line 20, node_modules/@fortawesome/fontawesome-free/scss/_larger.scss */
.fa-10x {
  font-size: 10em;
}

/* line 3, node_modules/@fortawesome/fontawesome-free/scss/_fixed-width.scss */
.fa-fw {
  text-align: center;
  width: 1.25em;
}

/* line 4, node_modules/@fortawesome/fontawesome-free/scss/_list.scss */
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

/* line 9, node_modules/@fortawesome/fontawesome-free/scss/_list.scss */
.fa-ul > li {
  position: relative;
}

/* line 12, node_modules/@fortawesome/fontawesome-free/scss/_list.scss */
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

/* line 4, node_modules/@fortawesome/fontawesome-free/scss/_bordered-pulled.scss */
.fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

/* line 10, node_modules/@fortawesome/fontawesome-free/scss/_bordered-pulled.scss */
.fa-pull-left {
  float: left;
}

/* line 11, node_modules/@fortawesome/fontawesome-free/scss/_bordered-pulled.scss */
.fa-pull-right {
  float: right;
}

/* line 18, node_modules/@fortawesome/fontawesome-free/scss/_bordered-pulled.scss */
.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}

/* line 19, node_modules/@fortawesome/fontawesome-free/scss/_bordered-pulled.scss */
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}

/* line 4, node_modules/@fortawesome/fontawesome-free/scss/_animated.scss */
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

/* line 8, node_modules/@fortawesome/fontawesome-free/scss/_animated.scss */
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* line 4, node_modules/@fortawesome/fontawesome-free/scss/_rotated-flipped.scss */
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* line 5, node_modules/@fortawesome/fontawesome-free/scss/_rotated-flipped.scss */
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* line 6, node_modules/@fortawesome/fontawesome-free/scss/_rotated-flipped.scss */
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

/* line 8, node_modules/@fortawesome/fontawesome-free/scss/_rotated-flipped.scss */
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

/* line 9, node_modules/@fortawesome/fontawesome-free/scss/_rotated-flipped.scss */
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

/* line 10, node_modules/@fortawesome/fontawesome-free/scss/_rotated-flipped.scss */
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

/* line 16, node_modules/@fortawesome/fontawesome-free/scss/_rotated-flipped.scss */
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  -webkit-filter: none;
  filter: none;
}

/* line 4, node_modules/@fortawesome/fontawesome-free/scss/_stacked.scss */
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

/* line 13, node_modules/@fortawesome/fontawesome-free/scss/_stacked.scss */
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

/* line 21, node_modules/@fortawesome/fontawesome-free/scss/_stacked.scss */
.fa-stack-1x {
  line-height: inherit;
}

/* line 25, node_modules/@fortawesome/fontawesome-free/scss/_stacked.scss */
.fa-stack-2x {
  font-size: 2em;
}

/* line 29, node_modules/@fortawesome/fontawesome-free/scss/_stacked.scss */
.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
/* line 4, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-500px:before {
  content: "\f26e";
}

/* line 5, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-accessible-icon:before {
  content: "\f368";
}

/* line 6, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-accusoft:before {
  content: "\f369";
}

/* line 7, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-acquisitions-incorporated:before {
  content: "\f6af";
}

/* line 8, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ad:before {
  content: "\f641";
}

/* line 9, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-address-book:before {
  content: "\f2b9";
}

/* line 10, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-address-card:before {
  content: "\f2bb";
}

/* line 11, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-adjust:before {
  content: "\f042";
}

/* line 12, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-adn:before {
  content: "\f170";
}

/* line 13, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-adobe:before {
  content: "\f778";
}

/* line 14, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-adversal:before {
  content: "\f36a";
}

/* line 15, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-affiliatetheme:before {
  content: "\f36b";
}

/* line 16, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-air-freshener:before {
  content: "\f5d0";
}

/* line 17, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-airbnb:before {
  content: "\f834";
}

/* line 18, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-algolia:before {
  content: "\f36c";
}

/* line 19, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-align-center:before {
  content: "\f037";
}

/* line 20, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-align-justify:before {
  content: "\f039";
}

/* line 21, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-align-left:before {
  content: "\f036";
}

/* line 22, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-align-right:before {
  content: "\f038";
}

/* line 23, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-alipay:before {
  content: "\f642";
}

/* line 24, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-allergies:before {
  content: "\f461";
}

/* line 25, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-amazon:before {
  content: "\f270";
}

/* line 26, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-amazon-pay:before {
  content: "\f42c";
}

/* line 27, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ambulance:before {
  content: "\f0f9";
}

/* line 28, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

/* line 29, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-amilia:before {
  content: "\f36d";
}

/* line 30, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-anchor:before {
  content: "\f13d";
}

/* line 31, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-android:before {
  content: "\f17b";
}

/* line 32, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-angellist:before {
  content: "\f209";
}

/* line 33, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-angle-double-down:before {
  content: "\f103";
}

/* line 34, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-angle-double-left:before {
  content: "\f100";
}

/* line 35, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-angle-double-right:before {
  content: "\f101";
}

/* line 36, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-angle-double-up:before {
  content: "\f102";
}

/* line 37, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-angle-down:before {
  content: "\f107";
}

/* line 38, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-angle-left:before {
  content: "\f104";
}

/* line 39, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-angle-right:before {
  content: "\f105";
}

/* line 40, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-angle-up:before {
  content: "\f106";
}

/* line 41, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-angry:before {
  content: "\f556";
}

/* line 42, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-angrycreative:before {
  content: "\f36e";
}

/* line 43, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-angular:before {
  content: "\f420";
}

/* line 44, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ankh:before {
  content: "\f644";
}

/* line 45, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-app-store:before {
  content: "\f36f";
}

/* line 46, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-app-store-ios:before {
  content: "\f370";
}

/* line 47, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-apper:before {
  content: "\f371";
}

/* line 48, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-apple:before {
  content: "\f179";
}

/* line 49, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-apple-alt:before {
  content: "\f5d1";
}

/* line 50, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-apple-pay:before {
  content: "\f415";
}

/* line 51, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-archive:before {
  content: "\f187";
}

/* line 52, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-archway:before {
  content: "\f557";
}

/* line 53, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

/* line 54, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

/* line 55, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

/* line 56, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

/* line 57, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrow-circle-down:before {
  content: "\f0ab";
}

/* line 58, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrow-circle-left:before {
  content: "\f0a8";
}

/* line 59, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrow-circle-right:before {
  content: "\f0a9";
}

/* line 60, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrow-circle-up:before {
  content: "\f0aa";
}

/* line 61, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrow-down:before {
  content: "\f063";
}

/* line 62, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrow-left:before {
  content: "\f060";
}

/* line 63, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrow-right:before {
  content: "\f061";
}

/* line 64, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrow-up:before {
  content: "\f062";
}

/* line 65, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrows-alt:before {
  content: "\f0b2";
}

/* line 66, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrows-alt-h:before {
  content: "\f337";
}

/* line 67, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-arrows-alt-v:before {
  content: "\f338";
}

/* line 68, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-artstation:before {
  content: "\f77a";
}

/* line 69, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

/* line 70, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-asterisk:before {
  content: "\f069";
}

/* line 71, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-asymmetrik:before {
  content: "\f372";
}

/* line 72, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-at:before {
  content: "\f1fa";
}

/* line 73, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-atlas:before {
  content: "\f558";
}

/* line 74, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-atlassian:before {
  content: "\f77b";
}

/* line 75, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-atom:before {
  content: "\f5d2";
}

/* line 76, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-audible:before {
  content: "\f373";
}

/* line 77, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-audio-description:before {
  content: "\f29e";
}

/* line 78, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-autoprefixer:before {
  content: "\f41c";
}

/* line 79, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-avianex:before {
  content: "\f374";
}

/* line 80, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-aviato:before {
  content: "\f421";
}

/* line 81, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-award:before {
  content: "\f559";
}

/* line 82, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-aws:before {
  content: "\f375";
}

/* line 83, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-baby:before {
  content: "\f77c";
}

/* line 84, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-baby-carriage:before {
  content: "\f77d";
}

/* line 85, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-backspace:before {
  content: "\f55a";
}

/* line 86, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-backward:before {
  content: "\f04a";
}

/* line 87, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bacon:before {
  content: "\f7e5";
}

/* line 88, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bahai:before {
  content: "\f666";
}

/* line 89, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-balance-scale:before {
  content: "\f24e";
}

/* line 90, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-balance-scale-left:before {
  content: "\f515";
}

/* line 91, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-balance-scale-right:before {
  content: "\f516";
}

/* line 92, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ban:before {
  content: "\f05e";
}

/* line 93, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-band-aid:before {
  content: "\f462";
}

/* line 94, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bandcamp:before {
  content: "\f2d5";
}

/* line 95, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-barcode:before {
  content: "\f02a";
}

/* line 96, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bars:before {
  content: "\f0c9";
}

/* line 97, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-baseball-ball:before {
  content: "\f433";
}

/* line 98, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-basketball-ball:before {
  content: "\f434";
}

/* line 99, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bath:before {
  content: "\f2cd";
}

/* line 100, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-battery-empty:before {
  content: "\f244";
}

/* line 101, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-battery-full:before {
  content: "\f240";
}

/* line 102, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-battery-half:before {
  content: "\f242";
}

/* line 103, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-battery-quarter:before {
  content: "\f243";
}

/* line 104, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-battery-three-quarters:before {
  content: "\f241";
}

/* line 105, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-battle-net:before {
  content: "\f835";
}

/* line 106, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bed:before {
  content: "\f236";
}

/* line 107, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-beer:before {
  content: "\f0fc";
}

/* line 108, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-behance:before {
  content: "\f1b4";
}

/* line 109, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-behance-square:before {
  content: "\f1b5";
}

/* line 110, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bell:before {
  content: "\f0f3";
}

/* line 111, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bell-slash:before {
  content: "\f1f6";
}

/* line 112, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bezier-curve:before {
  content: "\f55b";
}

/* line 113, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bible:before {
  content: "\f647";
}

/* line 114, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bicycle:before {
  content: "\f206";
}

/* line 115, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-biking:before {
  content: "\f84a";
}

/* line 116, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bimobject:before {
  content: "\f378";
}

/* line 117, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-binoculars:before {
  content: "\f1e5";
}

/* line 118, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-biohazard:before {
  content: "\f780";
}

/* line 119, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-birthday-cake:before {
  content: "\f1fd";
}

/* line 120, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bitbucket:before {
  content: "\f171";
}

/* line 121, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bitcoin:before {
  content: "\f379";
}

/* line 122, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bity:before {
  content: "\f37a";
}

/* line 123, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-black-tie:before {
  content: "\f27e";
}

/* line 124, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-blackberry:before {
  content: "\f37b";
}

/* line 125, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-blender:before {
  content: "\f517";
}

/* line 126, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-blender-phone:before {
  content: "\f6b6";
}

/* line 127, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-blind:before {
  content: "\f29d";
}

/* line 128, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-blog:before {
  content: "\f781";
}

/* line 129, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-blogger:before {
  content: "\f37c";
}

/* line 130, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-blogger-b:before {
  content: "\f37d";
}

/* line 131, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bluetooth:before {
  content: "\f293";
}

/* line 132, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bluetooth-b:before {
  content: "\f294";
}

/* line 133, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bold:before {
  content: "\f032";
}

/* line 134, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bolt:before {
  content: "\f0e7";
}

/* line 135, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bomb:before {
  content: "\f1e2";
}

/* line 136, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bone:before {
  content: "\f5d7";
}

/* line 137, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bong:before {
  content: "\f55c";
}

/* line 138, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-book:before {
  content: "\f02d";
}

/* line 139, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-book-dead:before {
  content: "\f6b7";
}

/* line 140, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-book-medical:before {
  content: "\f7e6";
}

/* line 141, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-book-open:before {
  content: "\f518";
}

/* line 142, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-book-reader:before {
  content: "\f5da";
}

/* line 143, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bookmark:before {
  content: "\f02e";
}

/* line 144, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bootstrap:before {
  content: "\f836";
}

/* line 145, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-border-all:before {
  content: "\f84c";
}

/* line 146, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-border-none:before {
  content: "\f850";
}

/* line 147, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-border-style:before {
  content: "\f853";
}

/* line 148, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bowling-ball:before {
  content: "\f436";
}

/* line 149, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-box:before {
  content: "\f466";
}

/* line 150, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-box-open:before {
  content: "\f49e";
}

/* line 151, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-box-tissue:before {
  content: "\f95b";
}

/* line 152, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-boxes:before {
  content: "\f468";
}

/* line 153, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-braille:before {
  content: "\f2a1";
}

/* line 154, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-brain:before {
  content: "\f5dc";
}

/* line 155, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bread-slice:before {
  content: "\f7ec";
}

/* line 156, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-briefcase:before {
  content: "\f0b1";
}

/* line 157, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-briefcase-medical:before {
  content: "\f469";
}

/* line 158, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-broadcast-tower:before {
  content: "\f519";
}

/* line 159, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-broom:before {
  content: "\f51a";
}

/* line 160, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-brush:before {
  content: "\f55d";
}

/* line 161, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-btc:before {
  content: "\f15a";
}

/* line 162, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-buffer:before {
  content: "\f837";
}

/* line 163, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bug:before {
  content: "\f188";
}

/* line 164, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-building:before {
  content: "\f1ad";
}

/* line 165, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bullhorn:before {
  content: "\f0a1";
}

/* line 166, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bullseye:before {
  content: "\f140";
}

/* line 167, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-burn:before {
  content: "\f46a";
}

/* line 168, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-buromobelexperte:before {
  content: "\f37f";
}

/* line 169, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bus:before {
  content: "\f207";
}

/* line 170, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-bus-alt:before {
  content: "\f55e";
}

/* line 171, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-business-time:before {
  content: "\f64a";
}

/* line 172, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-buy-n-large:before {
  content: "\f8a6";
}

/* line 173, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-buysellads:before {
  content: "\f20d";
}

/* line 174, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-calculator:before {
  content: "\f1ec";
}

/* line 175, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-calendar:before {
  content: "\f133";
}

/* line 176, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-calendar-alt:before {
  content: "\f073";
}

/* line 177, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-calendar-check:before {
  content: "\f274";
}

/* line 178, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-calendar-day:before {
  content: "\f783";
}

/* line 179, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-calendar-minus:before {
  content: "\f272";
}

/* line 180, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-calendar-plus:before {
  content: "\f271";
}

/* line 181, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-calendar-times:before {
  content: "\f273";
}

/* line 182, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-calendar-week:before {
  content: "\f784";
}

/* line 183, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-camera:before {
  content: "\f030";
}

/* line 184, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-camera-retro:before {
  content: "\f083";
}

/* line 185, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-campground:before {
  content: "\f6bb";
}

/* line 186, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-canadian-maple-leaf:before {
  content: "\f785";
}

/* line 187, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-candy-cane:before {
  content: "\f786";
}

/* line 188, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cannabis:before {
  content: "\f55f";
}

/* line 189, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-capsules:before {
  content: "\f46b";
}

/* line 190, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-car:before {
  content: "\f1b9";
}

/* line 191, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-car-alt:before {
  content: "\f5de";
}

/* line 192, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-car-battery:before {
  content: "\f5df";
}

/* line 193, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-car-crash:before {
  content: "\f5e1";
}

/* line 194, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-car-side:before {
  content: "\f5e4";
}

/* line 195, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-caravan:before {
  content: "\f8ff";
}

/* line 196, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-caret-down:before {
  content: "\f0d7";
}

/* line 197, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-caret-left:before {
  content: "\f0d9";
}

/* line 198, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-caret-right:before {
  content: "\f0da";
}

/* line 199, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-caret-square-down:before {
  content: "\f150";
}

/* line 200, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-caret-square-left:before {
  content: "\f191";
}

/* line 201, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-caret-square-right:before {
  content: "\f152";
}

/* line 202, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-caret-square-up:before {
  content: "\f151";
}

/* line 203, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-caret-up:before {
  content: "\f0d8";
}

/* line 204, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-carrot:before {
  content: "\f787";
}

/* line 205, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cart-arrow-down:before {
  content: "\f218";
}

/* line 206, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cart-plus:before {
  content: "\f217";
}

/* line 207, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cash-register:before {
  content: "\f788";
}

/* line 208, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cat:before {
  content: "\f6be";
}

/* line 209, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cc-amazon-pay:before {
  content: "\f42d";
}

/* line 210, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cc-amex:before {
  content: "\f1f3";
}

/* line 211, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cc-apple-pay:before {
  content: "\f416";
}

/* line 212, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cc-diners-club:before {
  content: "\f24c";
}

/* line 213, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cc-discover:before {
  content: "\f1f2";
}

/* line 214, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cc-jcb:before {
  content: "\f24b";
}

/* line 215, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cc-mastercard:before {
  content: "\f1f1";
}

/* line 216, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cc-paypal:before {
  content: "\f1f4";
}

/* line 217, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cc-stripe:before {
  content: "\f1f5";
}

/* line 218, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cc-visa:before {
  content: "\f1f0";
}

/* line 219, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-centercode:before {
  content: "\f380";
}

/* line 220, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-centos:before {
  content: "\f789";
}

/* line 221, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-certificate:before {
  content: "\f0a3";
}

/* line 222, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chair:before {
  content: "\f6c0";
}

/* line 223, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chalkboard:before {
  content: "\f51b";
}

/* line 224, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chalkboard-teacher:before {
  content: "\f51c";
}

/* line 225, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-charging-station:before {
  content: "\f5e7";
}

/* line 226, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chart-area:before {
  content: "\f1fe";
}

/* line 227, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chart-bar:before {
  content: "\f080";
}

/* line 228, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chart-line:before {
  content: "\f201";
}

/* line 229, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chart-pie:before {
  content: "\f200";
}

/* line 230, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-check:before {
  content: "\f00c";
}

/* line 231, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-check-circle:before {
  content: "\f058";
}

/* line 232, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-check-double:before {
  content: "\f560";
}

/* line 233, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-check-square:before {
  content: "\f14a";
}

/* line 234, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cheese:before {
  content: "\f7ef";
}

/* line 235, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chess:before {
  content: "\f439";
}

/* line 236, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chess-bishop:before {
  content: "\f43a";
}

/* line 237, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chess-board:before {
  content: "\f43c";
}

/* line 238, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chess-king:before {
  content: "\f43f";
}

/* line 239, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chess-knight:before {
  content: "\f441";
}

/* line 240, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chess-pawn:before {
  content: "\f443";
}

/* line 241, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chess-queen:before {
  content: "\f445";
}

/* line 242, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chess-rook:before {
  content: "\f447";
}

/* line 243, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chevron-circle-down:before {
  content: "\f13a";
}

/* line 244, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chevron-circle-left:before {
  content: "\f137";
}

/* line 245, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chevron-circle-right:before {
  content: "\f138";
}

/* line 246, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chevron-circle-up:before {
  content: "\f139";
}

/* line 247, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chevron-down:before {
  content: "\f078";
}

/* line 248, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chevron-left:before {
  content: "\f053";
}

/* line 249, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chevron-right:before {
  content: "\f054";
}

/* line 250, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chevron-up:before {
  content: "\f077";
}

/* line 251, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-child:before {
  content: "\f1ae";
}

/* line 252, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chrome:before {
  content: "\f268";
}

/* line 253, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-chromecast:before {
  content: "\f838";
}

/* line 254, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-church:before {
  content: "\f51d";
}

/* line 255, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-circle:before {
  content: "\f111";
}

/* line 256, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-circle-notch:before {
  content: "\f1ce";
}

/* line 257, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-city:before {
  content: "\f64f";
}

/* line 258, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-clinic-medical:before {
  content: "\f7f2";
}

/* line 259, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-clipboard:before {
  content: "\f328";
}

/* line 260, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-clipboard-check:before {
  content: "\f46c";
}

/* line 261, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-clipboard-list:before {
  content: "\f46d";
}

/* line 262, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-clock:before {
  content: "\f017";
}

/* line 263, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-clone:before {
  content: "\f24d";
}

/* line 264, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-closed-captioning:before {
  content: "\f20a";
}

/* line 265, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cloud:before {
  content: "\f0c2";
}

/* line 266, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cloud-download-alt:before {
  content: "\f381";
}

/* line 267, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cloud-meatball:before {
  content: "\f73b";
}

/* line 268, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cloud-moon:before {
  content: "\f6c3";
}

/* line 269, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cloud-moon-rain:before {
  content: "\f73c";
}

/* line 270, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cloud-rain:before {
  content: "\f73d";
}

/* line 271, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cloud-showers-heavy:before {
  content: "\f740";
}

/* line 272, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cloud-sun:before {
  content: "\f6c4";
}

/* line 273, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cloud-sun-rain:before {
  content: "\f743";
}

/* line 274, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cloud-upload-alt:before {
  content: "\f382";
}

/* line 275, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cloudscale:before {
  content: "\f383";
}

/* line 276, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cloudsmith:before {
  content: "\f384";
}

/* line 277, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cloudversify:before {
  content: "\f385";
}

/* line 278, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cocktail:before {
  content: "\f561";
}

/* line 279, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-code:before {
  content: "\f121";
}

/* line 280, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-code-branch:before {
  content: "\f126";
}

/* line 281, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-codepen:before {
  content: "\f1cb";
}

/* line 282, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-codiepie:before {
  content: "\f284";
}

/* line 283, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-coffee:before {
  content: "\f0f4";
}

/* line 284, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cog:before {
  content: "\f013";
}

/* line 285, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cogs:before {
  content: "\f085";
}

/* line 286, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-coins:before {
  content: "\f51e";
}

/* line 287, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-columns:before {
  content: "\f0db";
}

/* line 288, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-comment:before {
  content: "\f075";
}

/* line 289, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-comment-alt:before {
  content: "\f27a";
}

/* line 290, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-comment-dollar:before {
  content: "\f651";
}

/* line 291, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-comment-dots:before {
  content: "\f4ad";
}

/* line 292, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-comment-medical:before {
  content: "\f7f5";
}

/* line 293, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-comment-slash:before {
  content: "\f4b3";
}

/* line 294, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-comments:before {
  content: "\f086";
}

/* line 295, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-comments-dollar:before {
  content: "\f653";
}

/* line 296, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-compact-disc:before {
  content: "\f51f";
}

/* line 297, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-compass:before {
  content: "\f14e";
}

/* line 298, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-compress:before {
  content: "\f066";
}

/* line 299, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-compress-alt:before {
  content: "\f422";
}

/* line 300, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-compress-arrows-alt:before {
  content: "\f78c";
}

/* line 301, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-concierge-bell:before {
  content: "\f562";
}

/* line 302, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-confluence:before {
  content: "\f78d";
}

/* line 303, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-connectdevelop:before {
  content: "\f20e";
}

/* line 304, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-contao:before {
  content: "\f26d";
}

/* line 305, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cookie:before {
  content: "\f563";
}

/* line 306, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cookie-bite:before {
  content: "\f564";
}

/* line 307, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-copy:before {
  content: "\f0c5";
}

/* line 308, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-copyright:before {
  content: "\f1f9";
}

/* line 309, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cotton-bureau:before {
  content: "\f89e";
}

/* line 310, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-couch:before {
  content: "\f4b8";
}

/* line 311, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cpanel:before {
  content: "\f388";
}

/* line 312, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons:before {
  content: "\f25e";
}

/* line 313, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons-by:before {
  content: "\f4e7";
}

/* line 314, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons-nc:before {
  content: "\f4e8";
}

/* line 315, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

/* line 316, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

/* line 317, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons-nd:before {
  content: "\f4eb";
}

/* line 318, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons-pd:before {
  content: "\f4ec";
}

/* line 319, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

/* line 320, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons-remix:before {
  content: "\f4ee";
}

/* line 321, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons-sa:before {
  content: "\f4ef";
}

/* line 322, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

/* line 323, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

/* line 324, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons-share:before {
  content: "\f4f2";
}

/* line 325, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-creative-commons-zero:before {
  content: "\f4f3";
}

/* line 326, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-credit-card:before {
  content: "\f09d";
}

/* line 327, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-critical-role:before {
  content: "\f6c9";
}

/* line 328, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-crop:before {
  content: "\f125";
}

/* line 329, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-crop-alt:before {
  content: "\f565";
}

/* line 330, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cross:before {
  content: "\f654";
}

/* line 331, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-crosshairs:before {
  content: "\f05b";
}

/* line 332, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-crow:before {
  content: "\f520";
}

/* line 333, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-crown:before {
  content: "\f521";
}

/* line 334, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-crutch:before {
  content: "\f7f7";
}

/* line 335, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-css3:before {
  content: "\f13c";
}

/* line 336, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-css3-alt:before {
  content: "\f38b";
}

/* line 337, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cube:before {
  content: "\f1b2";
}

/* line 338, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cubes:before {
  content: "\f1b3";
}

/* line 339, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cut:before {
  content: "\f0c4";
}

/* line 340, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-cuttlefish:before {
  content: "\f38c";
}

/* line 341, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-d-and-d:before {
  content: "\f38d";
}

/* line 342, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

/* line 343, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dailymotion:before {
  content: "\f952";
}

/* line 344, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dashcube:before {
  content: "\f210";
}

/* line 345, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-database:before {
  content: "\f1c0";
}

/* line 346, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-deaf:before {
  content: "\f2a4";
}

/* line 347, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-delicious:before {
  content: "\f1a5";
}

/* line 348, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-democrat:before {
  content: "\f747";
}

/* line 349, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-deploydog:before {
  content: "\f38e";
}

/* line 350, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-deskpro:before {
  content: "\f38f";
}

/* line 351, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-desktop:before {
  content: "\f108";
}

/* line 352, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dev:before {
  content: "\f6cc";
}

/* line 353, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-deviantart:before {
  content: "\f1bd";
}

/* line 354, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dharmachakra:before {
  content: "\f655";
}

/* line 355, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dhl:before {
  content: "\f790";
}

/* line 356, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-diagnoses:before {
  content: "\f470";
}

/* line 357, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-diaspora:before {
  content: "\f791";
}

/* line 358, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dice:before {
  content: "\f522";
}

/* line 359, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dice-d20:before {
  content: "\f6cf";
}

/* line 360, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dice-d6:before {
  content: "\f6d1";
}

/* line 361, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dice-five:before {
  content: "\f523";
}

/* line 362, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dice-four:before {
  content: "\f524";
}

/* line 363, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dice-one:before {
  content: "\f525";
}

/* line 364, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dice-six:before {
  content: "\f526";
}

/* line 365, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dice-three:before {
  content: "\f527";
}

/* line 366, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dice-two:before {
  content: "\f528";
}

/* line 367, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-digg:before {
  content: "\f1a6";
}

/* line 368, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-digital-ocean:before {
  content: "\f391";
}

/* line 369, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-digital-tachograph:before {
  content: "\f566";
}

/* line 370, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-directions:before {
  content: "\f5eb";
}

/* line 371, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-discord:before {
  content: "\f392";
}

/* line 372, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-discourse:before {
  content: "\f393";
}

/* line 373, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-disease:before {
  content: "\f7fa";
}

/* line 374, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-divide:before {
  content: "\f529";
}

/* line 375, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dizzy:before {
  content: "\f567";
}

/* line 376, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dna:before {
  content: "\f471";
}

/* line 377, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dochub:before {
  content: "\f394";
}

/* line 378, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-docker:before {
  content: "\f395";
}

/* line 379, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dog:before {
  content: "\f6d3";
}

/* line 380, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dollar-sign:before {
  content: "\f155";
}

/* line 381, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dolly:before {
  content: "\f472";
}

/* line 382, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dolly-flatbed:before {
  content: "\f474";
}

/* line 383, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-donate:before {
  content: "\f4b9";
}

/* line 384, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-door-closed:before {
  content: "\f52a";
}

/* line 385, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-door-open:before {
  content: "\f52b";
}

/* line 386, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dot-circle:before {
  content: "\f192";
}

/* line 387, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dove:before {
  content: "\f4ba";
}

/* line 388, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-download:before {
  content: "\f019";
}

/* line 389, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-draft2digital:before {
  content: "\f396";
}

/* line 390, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-drafting-compass:before {
  content: "\f568";
}

/* line 391, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dragon:before {
  content: "\f6d5";
}

/* line 392, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-draw-polygon:before {
  content: "\f5ee";
}

/* line 393, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dribbble:before {
  content: "\f17d";
}

/* line 394, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dribbble-square:before {
  content: "\f397";
}

/* line 395, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dropbox:before {
  content: "\f16b";
}

/* line 396, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-drum:before {
  content: "\f569";
}

/* line 397, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-drum-steelpan:before {
  content: "\f56a";
}

/* line 398, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-drumstick-bite:before {
  content: "\f6d7";
}

/* line 399, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-drupal:before {
  content: "\f1a9";
}

/* line 400, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dumbbell:before {
  content: "\f44b";
}

/* line 401, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dumpster:before {
  content: "\f793";
}

/* line 402, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dumpster-fire:before {
  content: "\f794";
}

/* line 403, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dungeon:before {
  content: "\f6d9";
}

/* line 404, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-dyalog:before {
  content: "\f399";
}

/* line 405, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-earlybirds:before {
  content: "\f39a";
}

/* line 406, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ebay:before {
  content: "\f4f4";
}

/* line 407, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-edge:before {
  content: "\f282";
}

/* line 408, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-edit:before {
  content: "\f044";
}

/* line 409, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-egg:before {
  content: "\f7fb";
}

/* line 410, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-eject:before {
  content: "\f052";
}

/* line 411, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-elementor:before {
  content: "\f430";
}

/* line 412, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ellipsis-h:before {
  content: "\f141";
}

/* line 413, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ellipsis-v:before {
  content: "\f142";
}

/* line 414, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ello:before {
  content: "\f5f1";
}

/* line 415, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ember:before {
  content: "\f423";
}

/* line 416, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-empire:before {
  content: "\f1d1";
}

/* line 417, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-envelope:before {
  content: "\f0e0";
}

/* line 418, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-envelope-open:before {
  content: "\f2b6";
}

/* line 419, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-envelope-open-text:before {
  content: "\f658";
}

/* line 420, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-envelope-square:before {
  content: "\f199";
}

/* line 421, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-envira:before {
  content: "\f299";
}

/* line 422, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-equals:before {
  content: "\f52c";
}

/* line 423, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-eraser:before {
  content: "\f12d";
}

/* line 424, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-erlang:before {
  content: "\f39d";
}

/* line 425, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ethereum:before {
  content: "\f42e";
}

/* line 426, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ethernet:before {
  content: "\f796";
}

/* line 427, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-etsy:before {
  content: "\f2d7";
}

/* line 428, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-euro-sign:before {
  content: "\f153";
}

/* line 429, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-evernote:before {
  content: "\f839";
}

/* line 430, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-exchange-alt:before {
  content: "\f362";
}

/* line 431, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-exclamation:before {
  content: "\f12a";
}

/* line 432, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-exclamation-circle:before {
  content: "\f06a";
}

/* line 433, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-exclamation-triangle:before {
  content: "\f071";
}

/* line 434, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-expand:before {
  content: "\f065";
}

/* line 435, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-expand-alt:before {
  content: "\f424";
}

/* line 436, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-expand-arrows-alt:before {
  content: "\f31e";
}

/* line 437, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-expeditedssl:before {
  content: "\f23e";
}

/* line 438, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-external-link-alt:before {
  content: "\f35d";
}

/* line 439, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-external-link-square-alt:before {
  content: "\f360";
}

/* line 440, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-eye:before {
  content: "\f06e";
}

/* line 441, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-eye-dropper:before {
  content: "\f1fb";
}

/* line 442, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-eye-slash:before {
  content: "\f070";
}

/* line 443, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-facebook:before {
  content: "\f09a";
}

/* line 444, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-facebook-f:before {
  content: "\f39e";
}

/* line 445, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-facebook-messenger:before {
  content: "\f39f";
}

/* line 446, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-facebook-square:before {
  content: "\f082";
}

/* line 447, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fan:before {
  content: "\f863";
}

/* line 448, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

/* line 449, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fast-backward:before {
  content: "\f049";
}

/* line 450, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fast-forward:before {
  content: "\f050";
}

/* line 451, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-faucet:before {
  content: "\f905";
}

/* line 452, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fax:before {
  content: "\f1ac";
}

/* line 453, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-feather:before {
  content: "\f52d";
}

/* line 454, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-feather-alt:before {
  content: "\f56b";
}

/* line 455, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fedex:before {
  content: "\f797";
}

/* line 456, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fedora:before {
  content: "\f798";
}

/* line 457, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-female:before {
  content: "\f182";
}

/* line 458, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fighter-jet:before {
  content: "\f0fb";
}

/* line 459, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-figma:before {
  content: "\f799";
}

/* line 460, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file:before {
  content: "\f15b";
}

/* line 461, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-alt:before {
  content: "\f15c";
}

/* line 462, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-archive:before {
  content: "\f1c6";
}

/* line 463, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-audio:before {
  content: "\f1c7";
}

/* line 464, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-code:before {
  content: "\f1c9";
}

/* line 465, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-contract:before {
  content: "\f56c";
}

/* line 466, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-csv:before {
  content: "\f6dd";
}

/* line 467, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-download:before {
  content: "\f56d";
}

/* line 468, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-excel:before {
  content: "\f1c3";
}

/* line 469, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-export:before {
  content: "\f56e";
}

/* line 470, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-image:before {
  content: "\f1c5";
}

/* line 471, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-import:before {
  content: "\f56f";
}

/* line 472, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-invoice:before {
  content: "\f570";
}

/* line 473, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-invoice-dollar:before {
  content: "\f571";
}

/* line 474, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-medical:before {
  content: "\f477";
}

/* line 475, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-medical-alt:before {
  content: "\f478";
}

/* line 476, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-pdf:before {
  content: "\f1c1";
}

/* line 477, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-powerpoint:before {
  content: "\f1c4";
}

/* line 478, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-prescription:before {
  content: "\f572";
}

/* line 479, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-signature:before {
  content: "\f573";
}

/* line 480, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-upload:before {
  content: "\f574";
}

/* line 481, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-video:before {
  content: "\f1c8";
}

/* line 482, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-file-word:before {
  content: "\f1c2";
}

/* line 483, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fill:before {
  content: "\f575";
}

/* line 484, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fill-drip:before {
  content: "\f576";
}

/* line 485, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-film:before {
  content: "\f008";
}

/* line 486, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-filter:before {
  content: "\f0b0";
}

/* line 487, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fingerprint:before {
  content: "\f577";
}

/* line 488, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fire:before {
  content: "\f06d";
}

/* line 489, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fire-alt:before {
  content: "\f7e4";
}

/* line 490, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fire-extinguisher:before {
  content: "\f134";
}

/* line 491, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-firefox:before {
  content: "\f269";
}

/* line 492, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-firefox-browser:before {
  content: "\f907";
}

/* line 493, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-first-aid:before {
  content: "\f479";
}

/* line 494, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-first-order:before {
  content: "\f2b0";
}

/* line 495, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-first-order-alt:before {
  content: "\f50a";
}

/* line 496, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-firstdraft:before {
  content: "\f3a1";
}

/* line 497, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fish:before {
  content: "\f578";
}

/* line 498, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fist-raised:before {
  content: "\f6de";
}

/* line 499, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-flag:before {
  content: "\f024";
}

/* line 500, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-flag-checkered:before {
  content: "\f11e";
}

/* line 501, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-flag-usa:before {
  content: "\f74d";
}

/* line 502, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-flask:before {
  content: "\f0c3";
}

/* line 503, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-flickr:before {
  content: "\f16e";
}

/* line 504, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-flipboard:before {
  content: "\f44d";
}

/* line 505, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-flushed:before {
  content: "\f579";
}

/* line 506, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fly:before {
  content: "\f417";
}

/* line 507, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-folder:before {
  content: "\f07b";
}

/* line 508, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-folder-minus:before {
  content: "\f65d";
}

/* line 509, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-folder-open:before {
  content: "\f07c";
}

/* line 510, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-folder-plus:before {
  content: "\f65e";
}

/* line 511, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-font:before {
  content: "\f031";
}

/* line 512, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-font-awesome:before {
  content: "\f2b4";
}

/* line 513, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-font-awesome-alt:before {
  content: "\f35c";
}

/* line 514, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-font-awesome-flag:before {
  content: "\f425";
}

/* line 515, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}

/* line 516, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fonticons:before {
  content: "\f280";
}

/* line 517, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fonticons-fi:before {
  content: "\f3a2";
}

/* line 518, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-football-ball:before {
  content: "\f44e";
}

/* line 519, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fort-awesome:before {
  content: "\f286";
}

/* line 520, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

/* line 521, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-forumbee:before {
  content: "\f211";
}

/* line 522, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-forward:before {
  content: "\f04e";
}

/* line 523, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-foursquare:before {
  content: "\f180";
}

/* line 524, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-free-code-camp:before {
  content: "\f2c5";
}

/* line 525, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-freebsd:before {
  content: "\f3a4";
}

/* line 526, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-frog:before {
  content: "\f52e";
}

/* line 527, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-frown:before {
  content: "\f119";
}

/* line 528, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-frown-open:before {
  content: "\f57a";
}

/* line 529, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-fulcrum:before {
  content: "\f50b";
}

/* line 530, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-funnel-dollar:before {
  content: "\f662";
}

/* line 531, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-futbol:before {
  content: "\f1e3";
}

/* line 532, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-galactic-republic:before {
  content: "\f50c";
}

/* line 533, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-galactic-senate:before {
  content: "\f50d";
}

/* line 534, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gamepad:before {
  content: "\f11b";
}

/* line 535, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gas-pump:before {
  content: "\f52f";
}

/* line 536, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gavel:before {
  content: "\f0e3";
}

/* line 537, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gem:before {
  content: "\f3a5";
}

/* line 538, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-genderless:before {
  content: "\f22d";
}

/* line 539, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-get-pocket:before {
  content: "\f265";
}

/* line 540, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gg:before {
  content: "\f260";
}

/* line 541, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gg-circle:before {
  content: "\f261";
}

/* line 542, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ghost:before {
  content: "\f6e2";
}

/* line 543, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gift:before {
  content: "\f06b";
}

/* line 544, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gifts:before {
  content: "\f79c";
}

/* line 545, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-git:before {
  content: "\f1d3";
}

/* line 546, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-git-alt:before {
  content: "\f841";
}

/* line 547, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-git-square:before {
  content: "\f1d2";
}

/* line 548, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-github:before {
  content: "\f09b";
}

/* line 549, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-github-alt:before {
  content: "\f113";
}

/* line 550, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-github-square:before {
  content: "\f092";
}

/* line 551, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gitkraken:before {
  content: "\f3a6";
}

/* line 552, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gitlab:before {
  content: "\f296";
}

/* line 553, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gitter:before {
  content: "\f426";
}

/* line 554, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-glass-cheers:before {
  content: "\f79f";
}

/* line 555, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-glass-martini:before {
  content: "\f000";
}

/* line 556, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-glass-martini-alt:before {
  content: "\f57b";
}

/* line 557, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-glass-whiskey:before {
  content: "\f7a0";
}

/* line 558, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-glasses:before {
  content: "\f530";
}

/* line 559, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-glide:before {
  content: "\f2a5";
}

/* line 560, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-glide-g:before {
  content: "\f2a6";
}

/* line 561, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-globe:before {
  content: "\f0ac";
}

/* line 562, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-globe-africa:before {
  content: "\f57c";
}

/* line 563, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-globe-americas:before {
  content: "\f57d";
}

/* line 564, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-globe-asia:before {
  content: "\f57e";
}

/* line 565, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-globe-europe:before {
  content: "\f7a2";
}

/* line 566, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gofore:before {
  content: "\f3a7";
}

/* line 567, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-golf-ball:before {
  content: "\f450";
}

/* line 568, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-goodreads:before {
  content: "\f3a8";
}

/* line 569, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-goodreads-g:before {
  content: "\f3a9";
}

/* line 570, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-google:before {
  content: "\f1a0";
}

/* line 571, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-google-drive:before {
  content: "\f3aa";
}

/* line 572, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-google-play:before {
  content: "\f3ab";
}

/* line 573, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-google-plus:before {
  content: "\f2b3";
}

/* line 574, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-google-plus-g:before {
  content: "\f0d5";
}

/* line 575, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-google-plus-square:before {
  content: "\f0d4";
}

/* line 576, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-google-wallet:before {
  content: "\f1ee";
}

/* line 577, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gopuram:before {
  content: "\f664";
}

/* line 578, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-graduation-cap:before {
  content: "\f19d";
}

/* line 579, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gratipay:before {
  content: "\f184";
}

/* line 580, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grav:before {
  content: "\f2d6";
}

/* line 581, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-greater-than:before {
  content: "\f531";
}

/* line 582, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-greater-than-equal:before {
  content: "\f532";
}

/* line 583, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grimace:before {
  content: "\f57f";
}

/* line 584, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grin:before {
  content: "\f580";
}

/* line 585, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grin-alt:before {
  content: "\f581";
}

/* line 586, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grin-beam:before {
  content: "\f582";
}

/* line 587, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grin-beam-sweat:before {
  content: "\f583";
}

/* line 588, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grin-hearts:before {
  content: "\f584";
}

/* line 589, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grin-squint:before {
  content: "\f585";
}

/* line 590, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grin-squint-tears:before {
  content: "\f586";
}

/* line 591, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grin-stars:before {
  content: "\f587";
}

/* line 592, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grin-tears:before {
  content: "\f588";
}

/* line 593, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grin-tongue:before {
  content: "\f589";
}

/* line 594, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grin-tongue-squint:before {
  content: "\f58a";
}

/* line 595, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grin-tongue-wink:before {
  content: "\f58b";
}

/* line 596, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grin-wink:before {
  content: "\f58c";
}

/* line 597, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grip-horizontal:before {
  content: "\f58d";
}

/* line 598, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grip-lines:before {
  content: "\f7a4";
}

/* line 599, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grip-lines-vertical:before {
  content: "\f7a5";
}

/* line 600, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grip-vertical:before {
  content: "\f58e";
}

/* line 601, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gripfire:before {
  content: "\f3ac";
}

/* line 602, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-grunt:before {
  content: "\f3ad";
}

/* line 603, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-guitar:before {
  content: "\f7a6";
}

/* line 604, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-gulp:before {
  content: "\f3ae";
}

/* line 605, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-h-square:before {
  content: "\f0fd";
}

/* line 606, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hacker-news:before {
  content: "\f1d4";
}

/* line 607, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hacker-news-square:before {
  content: "\f3af";
}

/* line 608, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hackerrank:before {
  content: "\f5f7";
}

/* line 609, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hamburger:before {
  content: "\f805";
}

/* line 610, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hammer:before {
  content: "\f6e3";
}

/* line 611, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hamsa:before {
  content: "\f665";
}

/* line 612, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-holding:before {
  content: "\f4bd";
}

/* line 613, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-holding-heart:before {
  content: "\f4be";
}

/* line 614, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-holding-medical:before {
  content: "\f95c";
}

/* line 615, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-holding-usd:before {
  content: "\f4c0";
}

/* line 616, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-holding-water:before {
  content: "\f4c1";
}

/* line 617, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-lizard:before {
  content: "\f258";
}

/* line 618, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-middle-finger:before {
  content: "\f806";
}

/* line 619, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-paper:before {
  content: "\f256";
}

/* line 620, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-peace:before {
  content: "\f25b";
}

/* line 621, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-point-down:before {
  content: "\f0a7";
}

/* line 622, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-point-left:before {
  content: "\f0a5";
}

/* line 623, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-point-right:before {
  content: "\f0a4";
}

/* line 624, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-point-up:before {
  content: "\f0a6";
}

/* line 625, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-pointer:before {
  content: "\f25a";
}

/* line 626, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-rock:before {
  content: "\f255";
}

/* line 627, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-scissors:before {
  content: "\f257";
}

/* line 628, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-sparkles:before {
  content: "\f95d";
}

/* line 629, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hand-spock:before {
  content: "\f259";
}

/* line 630, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hands:before {
  content: "\f4c2";
}

/* line 631, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hands-helping:before {
  content: "\f4c4";
}

/* line 632, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hands-wash:before {
  content: "\f95e";
}

/* line 633, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-handshake:before {
  content: "\f2b5";
}

/* line 634, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-handshake-alt-slash:before {
  content: "\f95f";
}

/* line 635, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-handshake-slash:before {
  content: "\f960";
}

/* line 636, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hanukiah:before {
  content: "\f6e6";
}

/* line 637, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hard-hat:before {
  content: "\f807";
}

/* line 638, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hashtag:before {
  content: "\f292";
}

/* line 639, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hat-cowboy:before {
  content: "\f8c0";
}

/* line 640, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hat-cowboy-side:before {
  content: "\f8c1";
}

/* line 641, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hat-wizard:before {
  content: "\f6e8";
}

/* line 642, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hdd:before {
  content: "\f0a0";
}

/* line 643, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-head-side-cough:before {
  content: "\f961";
}

/* line 644, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-head-side-cough-slash:before {
  content: "\f962";
}

/* line 645, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-head-side-mask:before {
  content: "\f963";
}

/* line 646, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-head-side-virus:before {
  content: "\f964";
}

/* line 647, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-heading:before {
  content: "\f1dc";
}

/* line 648, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-headphones:before {
  content: "\f025";
}

/* line 649, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-headphones-alt:before {
  content: "\f58f";
}

/* line 650, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-headset:before {
  content: "\f590";
}

/* line 651, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-heart:before {
  content: "\f004";
}

/* line 652, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-heart-broken:before {
  content: "\f7a9";
}

/* line 653, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-heartbeat:before {
  content: "\f21e";
}

/* line 654, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-helicopter:before {
  content: "\f533";
}

/* line 655, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-highlighter:before {
  content: "\f591";
}

/* line 656, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hiking:before {
  content: "\f6ec";
}

/* line 657, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hippo:before {
  content: "\f6ed";
}

/* line 658, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hips:before {
  content: "\f452";
}

/* line 659, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hire-a-helper:before {
  content: "\f3b0";
}

/* line 660, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-history:before {
  content: "\f1da";
}

/* line 661, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hockey-puck:before {
  content: "\f453";
}

/* line 662, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-holly-berry:before {
  content: "\f7aa";
}

/* line 663, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-home:before {
  content: "\f015";
}

/* line 664, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hooli:before {
  content: "\f427";
}

/* line 665, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hornbill:before {
  content: "\f592";
}

/* line 666, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-horse:before {
  content: "\f6f0";
}

/* line 667, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-horse-head:before {
  content: "\f7ab";
}

/* line 668, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hospital:before {
  content: "\f0f8";
}

/* line 669, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hospital-alt:before {
  content: "\f47d";
}

/* line 670, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hospital-symbol:before {
  content: "\f47e";
}

/* line 671, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hospital-user:before {
  content: "\f80d";
}

/* line 672, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hot-tub:before {
  content: "\f593";
}

/* line 673, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hotdog:before {
  content: "\f80f";
}

/* line 674, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hotel:before {
  content: "\f594";
}

/* line 675, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hotjar:before {
  content: "\f3b1";
}

/* line 676, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hourglass:before {
  content: "\f254";
}

/* line 677, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hourglass-end:before {
  content: "\f253";
}

/* line 678, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hourglass-half:before {
  content: "\f252";
}

/* line 679, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hourglass-start:before {
  content: "\f251";
}

/* line 680, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-house-damage:before {
  content: "\f6f1";
}

/* line 681, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-house-user:before {
  content: "\f965";
}

/* line 682, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-houzz:before {
  content: "\f27c";
}

/* line 683, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hryvnia:before {
  content: "\f6f2";
}

/* line 684, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-html5:before {
  content: "\f13b";
}

/* line 685, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-hubspot:before {
  content: "\f3b2";
}

/* line 686, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-i-cursor:before {
  content: "\f246";
}

/* line 687, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ice-cream:before {
  content: "\f810";
}

/* line 688, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-icicles:before {
  content: "\f7ad";
}

/* line 689, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-icons:before {
  content: "\f86d";
}

/* line 690, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-id-badge:before {
  content: "\f2c1";
}

/* line 691, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-id-card:before {
  content: "\f2c2";
}

/* line 692, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-id-card-alt:before {
  content: "\f47f";
}

/* line 693, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ideal:before {
  content: "\f913";
}

/* line 694, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-igloo:before {
  content: "\f7ae";
}

/* line 695, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-image:before {
  content: "\f03e";
}

/* line 696, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-images:before {
  content: "\f302";
}

/* line 697, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-imdb:before {
  content: "\f2d8";
}

/* line 698, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-inbox:before {
  content: "\f01c";
}

/* line 699, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-indent:before {
  content: "\f03c";
}

/* line 700, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-industry:before {
  content: "\f275";
}

/* line 701, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-infinity:before {
  content: "\f534";
}

/* line 702, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-info:before {
  content: "\f129";
}

/* line 703, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-info-circle:before {
  content: "\f05a";
}

/* line 704, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-instagram:before {
  content: "\f16d";
}

/* line 705, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-instagram-square:before {
  content: "\f955";
}

/* line 706, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-intercom:before {
  content: "\f7af";
}

/* line 707, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-internet-explorer:before {
  content: "\f26b";
}

/* line 708, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-invision:before {
  content: "\f7b0";
}

/* line 709, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ioxhost:before {
  content: "\f208";
}

/* line 710, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-italic:before {
  content: "\f033";
}

/* line 711, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-itch-io:before {
  content: "\f83a";
}

/* line 712, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-itunes:before {
  content: "\f3b4";
}

/* line 713, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-itunes-note:before {
  content: "\f3b5";
}

/* line 714, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-java:before {
  content: "\f4e4";
}

/* line 715, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-jedi:before {
  content: "\f669";
}

/* line 716, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-jedi-order:before {
  content: "\f50e";
}

/* line 717, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-jenkins:before {
  content: "\f3b6";
}

/* line 718, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-jira:before {
  content: "\f7b1";
}

/* line 719, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-joget:before {
  content: "\f3b7";
}

/* line 720, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-joint:before {
  content: "\f595";
}

/* line 721, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-joomla:before {
  content: "\f1aa";
}

/* line 722, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-journal-whills:before {
  content: "\f66a";
}

/* line 723, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-js:before {
  content: "\f3b8";
}

/* line 724, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-js-square:before {
  content: "\f3b9";
}

/* line 725, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-jsfiddle:before {
  content: "\f1cc";
}

/* line 726, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-kaaba:before {
  content: "\f66b";
}

/* line 727, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-kaggle:before {
  content: "\f5fa";
}

/* line 728, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-key:before {
  content: "\f084";
}

/* line 729, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-keybase:before {
  content: "\f4f5";
}

/* line 730, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-keyboard:before {
  content: "\f11c";
}

/* line 731, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-keycdn:before {
  content: "\f3ba";
}

/* line 732, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-khanda:before {
  content: "\f66d";
}

/* line 733, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-kickstarter:before {
  content: "\f3bb";
}

/* line 734, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-kickstarter-k:before {
  content: "\f3bc";
}

/* line 735, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-kiss:before {
  content: "\f596";
}

/* line 736, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-kiss-beam:before {
  content: "\f597";
}

/* line 737, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-kiss-wink-heart:before {
  content: "\f598";
}

/* line 738, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-kiwi-bird:before {
  content: "\f535";
}

/* line 739, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-korvue:before {
  content: "\f42f";
}

/* line 740, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-landmark:before {
  content: "\f66f";
}

/* line 741, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-language:before {
  content: "\f1ab";
}

/* line 742, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-laptop:before {
  content: "\f109";
}

/* line 743, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-laptop-code:before {
  content: "\f5fc";
}

/* line 744, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-laptop-house:before {
  content: "\f966";
}

/* line 745, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-laptop-medical:before {
  content: "\f812";
}

/* line 746, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-laravel:before {
  content: "\f3bd";
}

/* line 747, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-lastfm:before {
  content: "\f202";
}

/* line 748, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-lastfm-square:before {
  content: "\f203";
}

/* line 749, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-laugh:before {
  content: "\f599";
}

/* line 750, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-laugh-beam:before {
  content: "\f59a";
}

/* line 751, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-laugh-squint:before {
  content: "\f59b";
}

/* line 752, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-laugh-wink:before {
  content: "\f59c";
}

/* line 753, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-layer-group:before {
  content: "\f5fd";
}

/* line 754, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-leaf:before {
  content: "\f06c";
}

/* line 755, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-leanpub:before {
  content: "\f212";
}

/* line 756, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-lemon:before {
  content: "\f094";
}

/* line 757, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-less:before {
  content: "\f41d";
}

/* line 758, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-less-than:before {
  content: "\f536";
}

/* line 759, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-less-than-equal:before {
  content: "\f537";
}

/* line 760, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-level-down-alt:before {
  content: "\f3be";
}

/* line 761, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-level-up-alt:before {
  content: "\f3bf";
}

/* line 762, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-life-ring:before {
  content: "\f1cd";
}

/* line 763, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-lightbulb:before {
  content: "\f0eb";
}

/* line 764, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-line:before {
  content: "\f3c0";
}

/* line 765, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-link:before {
  content: "\f0c1";
}

/* line 766, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-linkedin:before {
  content: "\f08c";
}

/* line 767, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-linkedin-in:before {
  content: "\f0e1";
}

/* line 768, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-linode:before {
  content: "\f2b8";
}

/* line 769, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-linux:before {
  content: "\f17c";
}

/* line 770, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-lira-sign:before {
  content: "\f195";
}

/* line 771, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-list:before {
  content: "\f03a";
}

/* line 772, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-list-alt:before {
  content: "\f022";
}

/* line 773, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-list-ol:before {
  content: "\f0cb";
}

/* line 774, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-list-ul:before {
  content: "\f0ca";
}

/* line 775, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-location-arrow:before {
  content: "\f124";
}

/* line 776, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-lock:before {
  content: "\f023";
}

/* line 777, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-lock-open:before {
  content: "\f3c1";
}

/* line 778, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-long-arrow-alt-down:before {
  content: "\f309";
}

/* line 779, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

/* line 780, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

/* line 781, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

/* line 782, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-low-vision:before {
  content: "\f2a8";
}

/* line 783, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-luggage-cart:before {
  content: "\f59d";
}

/* line 784, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-lungs:before {
  content: "\f604";
}

/* line 785, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-lungs-virus:before {
  content: "\f967";
}

/* line 786, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-lyft:before {
  content: "\f3c3";
}

/* line 787, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-magento:before {
  content: "\f3c4";
}

/* line 788, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-magic:before {
  content: "\f0d0";
}

/* line 789, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-magnet:before {
  content: "\f076";
}

/* line 790, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mail-bulk:before {
  content: "\f674";
}

/* line 791, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mailchimp:before {
  content: "\f59e";
}

/* line 792, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-male:before {
  content: "\f183";
}

/* line 793, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mandalorian:before {
  content: "\f50f";
}

/* line 794, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-map:before {
  content: "\f279";
}

/* line 795, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-map-marked:before {
  content: "\f59f";
}

/* line 796, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-map-marked-alt:before {
  content: "\f5a0";
}

/* line 797, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-map-marker:before {
  content: "\f041";
}

/* line 798, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-map-marker-alt:before {
  content: "\f3c5";
}

/* line 799, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-map-pin:before {
  content: "\f276";
}

/* line 800, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-map-signs:before {
  content: "\f277";
}

/* line 801, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-markdown:before {
  content: "\f60f";
}

/* line 802, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-marker:before {
  content: "\f5a1";
}

/* line 803, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mars:before {
  content: "\f222";
}

/* line 804, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mars-double:before {
  content: "\f227";
}

/* line 805, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mars-stroke:before {
  content: "\f229";
}

/* line 806, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mars-stroke-h:before {
  content: "\f22b";
}

/* line 807, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mars-stroke-v:before {
  content: "\f22a";
}

/* line 808, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mask:before {
  content: "\f6fa";
}

/* line 809, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mastodon:before {
  content: "\f4f6";
}

/* line 810, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-maxcdn:before {
  content: "\f136";
}

/* line 811, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mdb:before {
  content: "\f8ca";
}

/* line 812, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-medal:before {
  content: "\f5a2";
}

/* line 813, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-medapps:before {
  content: "\f3c6";
}

/* line 814, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-medium:before {
  content: "\f23a";
}

/* line 815, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-medium-m:before {
  content: "\f3c7";
}

/* line 816, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-medkit:before {
  content: "\f0fa";
}

/* line 817, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-medrt:before {
  content: "\f3c8";
}

/* line 818, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-meetup:before {
  content: "\f2e0";
}

/* line 819, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-megaport:before {
  content: "\f5a3";
}

/* line 820, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-meh:before {
  content: "\f11a";
}

/* line 821, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-meh-blank:before {
  content: "\f5a4";
}

/* line 822, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}

/* line 823, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-memory:before {
  content: "\f538";
}

/* line 824, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mendeley:before {
  content: "\f7b3";
}

/* line 825, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-menorah:before {
  content: "\f676";
}

/* line 826, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mercury:before {
  content: "\f223";
}

/* line 827, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-meteor:before {
  content: "\f753";
}

/* line 828, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-microblog:before {
  content: "\f91a";
}

/* line 829, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-microchip:before {
  content: "\f2db";
}

/* line 830, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-microphone:before {
  content: "\f130";
}

/* line 831, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-microphone-alt:before {
  content: "\f3c9";
}

/* line 832, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-microphone-alt-slash:before {
  content: "\f539";
}

/* line 833, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-microphone-slash:before {
  content: "\f131";
}

/* line 834, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-microscope:before {
  content: "\f610";
}

/* line 835, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-microsoft:before {
  content: "\f3ca";
}

/* line 836, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-minus:before {
  content: "\f068";
}

/* line 837, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-minus-circle:before {
  content: "\f056";
}

/* line 838, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-minus-square:before {
  content: "\f146";
}

/* line 839, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mitten:before {
  content: "\f7b5";
}

/* line 840, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mix:before {
  content: "\f3cb";
}

/* line 841, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mixcloud:before {
  content: "\f289";
}

/* line 842, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mixer:before {
  content: "\f956";
}

/* line 843, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mizuni:before {
  content: "\f3cc";
}

/* line 844, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mobile:before {
  content: "\f10b";
}

/* line 845, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mobile-alt:before {
  content: "\f3cd";
}

/* line 846, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-modx:before {
  content: "\f285";
}

/* line 847, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-monero:before {
  content: "\f3d0";
}

/* line 848, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-money-bill:before {
  content: "\f0d6";
}

/* line 849, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-money-bill-alt:before {
  content: "\f3d1";
}

/* line 850, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-money-bill-wave:before {
  content: "\f53a";
}

/* line 851, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-money-bill-wave-alt:before {
  content: "\f53b";
}

/* line 852, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-money-check:before {
  content: "\f53c";
}

/* line 853, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-money-check-alt:before {
  content: "\f53d";
}

/* line 854, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-monument:before {
  content: "\f5a6";
}

/* line 855, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-moon:before {
  content: "\f186";
}

/* line 856, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mortar-pestle:before {
  content: "\f5a7";
}

/* line 857, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mosque:before {
  content: "\f678";
}

/* line 858, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-motorcycle:before {
  content: "\f21c";
}

/* line 859, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mountain:before {
  content: "\f6fc";
}

/* line 860, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mouse:before {
  content: "\f8cc";
}

/* line 861, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mouse-pointer:before {
  content: "\f245";
}

/* line 862, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-mug-hot:before {
  content: "\f7b6";
}

/* line 863, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-music:before {
  content: "\f001";
}

/* line 864, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-napster:before {
  content: "\f3d2";
}

/* line 865, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-neos:before {
  content: "\f612";
}

/* line 866, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-network-wired:before {
  content: "\f6ff";
}

/* line 867, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-neuter:before {
  content: "\f22c";
}

/* line 868, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-newspaper:before {
  content: "\f1ea";
}

/* line 869, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-nimblr:before {
  content: "\f5a8";
}

/* line 870, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-node:before {
  content: "\f419";
}

/* line 871, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-node-js:before {
  content: "\f3d3";
}

/* line 872, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-not-equal:before {
  content: "\f53e";
}

/* line 873, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-notes-medical:before {
  content: "\f481";
}

/* line 874, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-npm:before {
  content: "\f3d4";
}

/* line 875, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ns8:before {
  content: "\f3d5";
}

/* line 876, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-nutritionix:before {
  content: "\f3d6";
}

/* line 877, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-object-group:before {
  content: "\f247";
}

/* line 878, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-object-ungroup:before {
  content: "\f248";
}

/* line 879, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-odnoklassniki:before {
  content: "\f263";
}

/* line 880, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-odnoklassniki-square:before {
  content: "\f264";
}

/* line 881, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-oil-can:before {
  content: "\f613";
}

/* line 882, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-old-republic:before {
  content: "\f510";
}

/* line 883, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-om:before {
  content: "\f679";
}

/* line 884, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-opencart:before {
  content: "\f23d";
}

/* line 885, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-openid:before {
  content: "\f19b";
}

/* line 886, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-opera:before {
  content: "\f26a";
}

/* line 887, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-optin-monster:before {
  content: "\f23c";
}

/* line 888, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-orcid:before {
  content: "\f8d2";
}

/* line 889, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-osi:before {
  content: "\f41a";
}

/* line 890, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-otter:before {
  content: "\f700";
}

/* line 891, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-outdent:before {
  content: "\f03b";
}

/* line 892, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-page4:before {
  content: "\f3d7";
}

/* line 893, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pagelines:before {
  content: "\f18c";
}

/* line 894, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pager:before {
  content: "\f815";
}

/* line 895, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-paint-brush:before {
  content: "\f1fc";
}

/* line 896, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-paint-roller:before {
  content: "\f5aa";
}

/* line 897, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-palette:before {
  content: "\f53f";
}

/* line 898, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-palfed:before {
  content: "\f3d8";
}

/* line 899, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pallet:before {
  content: "\f482";
}

/* line 900, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-paper-plane:before {
  content: "\f1d8";
}

/* line 901, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-paperclip:before {
  content: "\f0c6";
}

/* line 902, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-parachute-box:before {
  content: "\f4cd";
}

/* line 903, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-paragraph:before {
  content: "\f1dd";
}

/* line 904, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-parking:before {
  content: "\f540";
}

/* line 905, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-passport:before {
  content: "\f5ab";
}

/* line 906, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pastafarianism:before {
  content: "\f67b";
}

/* line 907, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-paste:before {
  content: "\f0ea";
}

/* line 908, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-patreon:before {
  content: "\f3d9";
}

/* line 909, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pause:before {
  content: "\f04c";
}

/* line 910, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pause-circle:before {
  content: "\f28b";
}

/* line 911, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-paw:before {
  content: "\f1b0";
}

/* line 912, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-paypal:before {
  content: "\f1ed";
}

/* line 913, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-peace:before {
  content: "\f67c";
}

/* line 914, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pen:before {
  content: "\f304";
}

/* line 915, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pen-alt:before {
  content: "\f305";
}

/* line 916, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pen-fancy:before {
  content: "\f5ac";
}

/* line 917, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pen-nib:before {
  content: "\f5ad";
}

/* line 918, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pen-square:before {
  content: "\f14b";
}

/* line 919, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pencil-alt:before {
  content: "\f303";
}

/* line 920, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pencil-ruler:before {
  content: "\f5ae";
}

/* line 921, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-penny-arcade:before {
  content: "\f704";
}

/* line 922, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-people-arrows:before {
  content: "\f968";
}

/* line 923, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-people-carry:before {
  content: "\f4ce";
}

/* line 924, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pepper-hot:before {
  content: "\f816";
}

/* line 925, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-percent:before {
  content: "\f295";
}

/* line 926, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-percentage:before {
  content: "\f541";
}

/* line 927, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-periscope:before {
  content: "\f3da";
}

/* line 928, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-person-booth:before {
  content: "\f756";
}

/* line 929, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-phabricator:before {
  content: "\f3db";
}

/* line 930, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-phoenix-framework:before {
  content: "\f3dc";
}

/* line 931, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-phoenix-squadron:before {
  content: "\f511";
}

/* line 932, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-phone:before {
  content: "\f095";
}

/* line 933, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-phone-alt:before {
  content: "\f879";
}

/* line 934, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-phone-slash:before {
  content: "\f3dd";
}

/* line 935, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-phone-square:before {
  content: "\f098";
}

/* line 936, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-phone-square-alt:before {
  content: "\f87b";
}

/* line 937, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-phone-volume:before {
  content: "\f2a0";
}

/* line 938, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-photo-video:before {
  content: "\f87c";
}

/* line 939, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-php:before {
  content: "\f457";
}

/* line 940, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pied-piper:before {
  content: "\f2ae";
}

/* line 941, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pied-piper-alt:before {
  content: "\f1a8";
}

/* line 942, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pied-piper-hat:before {
  content: "\f4e5";
}

/* line 943, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pied-piper-pp:before {
  content: "\f1a7";
}

/* line 944, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pied-piper-square:before {
  content: "\f91e";
}

/* line 945, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-piggy-bank:before {
  content: "\f4d3";
}

/* line 946, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pills:before {
  content: "\f484";
}

/* line 947, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pinterest:before {
  content: "\f0d2";
}

/* line 948, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pinterest-p:before {
  content: "\f231";
}

/* line 949, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pinterest-square:before {
  content: "\f0d3";
}

/* line 950, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pizza-slice:before {
  content: "\f818";
}

/* line 951, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-place-of-worship:before {
  content: "\f67f";
}

/* line 952, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-plane:before {
  content: "\f072";
}

/* line 953, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-plane-arrival:before {
  content: "\f5af";
}

/* line 954, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-plane-departure:before {
  content: "\f5b0";
}

/* line 955, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-plane-slash:before {
  content: "\f969";
}

/* line 956, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-play:before {
  content: "\f04b";
}

/* line 957, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-play-circle:before {
  content: "\f144";
}

/* line 958, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-playstation:before {
  content: "\f3df";
}

/* line 959, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-plug:before {
  content: "\f1e6";
}

/* line 960, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-plus:before {
  content: "\f067";
}

/* line 961, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-plus-circle:before {
  content: "\f055";
}

/* line 962, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-plus-square:before {
  content: "\f0fe";
}

/* line 963, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-podcast:before {
  content: "\f2ce";
}

/* line 964, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-poll:before {
  content: "\f681";
}

/* line 965, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-poll-h:before {
  content: "\f682";
}

/* line 966, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-poo:before {
  content: "\f2fe";
}

/* line 967, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-poo-storm:before {
  content: "\f75a";
}

/* line 968, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-poop:before {
  content: "\f619";
}

/* line 969, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-portrait:before {
  content: "\f3e0";
}

/* line 970, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pound-sign:before {
  content: "\f154";
}

/* line 971, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-power-off:before {
  content: "\f011";
}

/* line 972, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pray:before {
  content: "\f683";
}

/* line 973, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-praying-hands:before {
  content: "\f684";
}

/* line 974, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-prescription:before {
  content: "\f5b1";
}

/* line 975, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-prescription-bottle:before {
  content: "\f485";
}

/* line 976, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-prescription-bottle-alt:before {
  content: "\f486";
}

/* line 977, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-print:before {
  content: "\f02f";
}

/* line 978, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-procedures:before {
  content: "\f487";
}

/* line 979, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-product-hunt:before {
  content: "\f288";
}

/* line 980, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-project-diagram:before {
  content: "\f542";
}

/* line 981, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pump-medical:before {
  content: "\f96a";
}

/* line 982, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pump-soap:before {
  content: "\f96b";
}

/* line 983, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-pushed:before {
  content: "\f3e1";
}

/* line 984, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-puzzle-piece:before {
  content: "\f12e";
}

/* line 985, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-python:before {
  content: "\f3e2";
}

/* line 986, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-qq:before {
  content: "\f1d6";
}

/* line 987, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-qrcode:before {
  content: "\f029";
}

/* line 988, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-question:before {
  content: "\f128";
}

/* line 989, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-question-circle:before {
  content: "\f059";
}

/* line 990, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-quidditch:before {
  content: "\f458";
}

/* line 991, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-quinscape:before {
  content: "\f459";
}

/* line 992, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-quora:before {
  content: "\f2c4";
}

/* line 993, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-quote-left:before {
  content: "\f10d";
}

/* line 994, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-quote-right:before {
  content: "\f10e";
}

/* line 995, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-quran:before {
  content: "\f687";
}

/* line 996, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-r-project:before {
  content: "\f4f7";
}

/* line 997, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-radiation:before {
  content: "\f7b9";
}

/* line 998, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-radiation-alt:before {
  content: "\f7ba";
}

/* line 999, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-rainbow:before {
  content: "\f75b";
}

/* line 1000, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-random:before {
  content: "\f074";
}

/* line 1001, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-raspberry-pi:before {
  content: "\f7bb";
}

/* line 1002, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ravelry:before {
  content: "\f2d9";
}

/* line 1003, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-react:before {
  content: "\f41b";
}

/* line 1004, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-reacteurope:before {
  content: "\f75d";
}

/* line 1005, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-readme:before {
  content: "\f4d5";
}

/* line 1006, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-rebel:before {
  content: "\f1d0";
}

/* line 1007, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-receipt:before {
  content: "\f543";
}

/* line 1008, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-record-vinyl:before {
  content: "\f8d9";
}

/* line 1009, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-recycle:before {
  content: "\f1b8";
}

/* line 1010, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-red-river:before {
  content: "\f3e3";
}

/* line 1011, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-reddit:before {
  content: "\f1a1";
}

/* line 1012, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-reddit-alien:before {
  content: "\f281";
}

/* line 1013, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-reddit-square:before {
  content: "\f1a2";
}

/* line 1014, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-redhat:before {
  content: "\f7bc";
}

/* line 1015, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-redo:before {
  content: "\f01e";
}

/* line 1016, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-redo-alt:before {
  content: "\f2f9";
}

/* line 1017, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-registered:before {
  content: "\f25d";
}

/* line 1018, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-remove-format:before {
  content: "\f87d";
}

/* line 1019, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-renren:before {
  content: "\f18b";
}

/* line 1020, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-reply:before {
  content: "\f3e5";
}

/* line 1021, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-reply-all:before {
  content: "\f122";
}

/* line 1022, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-replyd:before {
  content: "\f3e6";
}

/* line 1023, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-republican:before {
  content: "\f75e";
}

/* line 1024, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-researchgate:before {
  content: "\f4f8";
}

/* line 1025, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-resolving:before {
  content: "\f3e7";
}

/* line 1026, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-restroom:before {
  content: "\f7bd";
}

/* line 1027, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-retweet:before {
  content: "\f079";
}

/* line 1028, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-rev:before {
  content: "\f5b2";
}

/* line 1029, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ribbon:before {
  content: "\f4d6";
}

/* line 1030, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ring:before {
  content: "\f70b";
}

/* line 1031, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-road:before {
  content: "\f018";
}

/* line 1032, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-robot:before {
  content: "\f544";
}

/* line 1033, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-rocket:before {
  content: "\f135";
}

/* line 1034, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-rocketchat:before {
  content: "\f3e8";
}

/* line 1035, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-rockrms:before {
  content: "\f3e9";
}

/* line 1036, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-route:before {
  content: "\f4d7";
}

/* line 1037, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-rss:before {
  content: "\f09e";
}

/* line 1038, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-rss-square:before {
  content: "\f143";
}

/* line 1039, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ruble-sign:before {
  content: "\f158";
}

/* line 1040, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ruler:before {
  content: "\f545";
}

/* line 1041, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ruler-combined:before {
  content: "\f546";
}

/* line 1042, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ruler-horizontal:before {
  content: "\f547";
}

/* line 1043, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ruler-vertical:before {
  content: "\f548";
}

/* line 1044, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-running:before {
  content: "\f70c";
}

/* line 1045, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-rupee-sign:before {
  content: "\f156";
}

/* line 1046, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sad-cry:before {
  content: "\f5b3";
}

/* line 1047, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sad-tear:before {
  content: "\f5b4";
}

/* line 1048, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-safari:before {
  content: "\f267";
}

/* line 1049, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-salesforce:before {
  content: "\f83b";
}

/* line 1050, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sass:before {
  content: "\f41e";
}

/* line 1051, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-satellite:before {
  content: "\f7bf";
}

/* line 1052, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-satellite-dish:before {
  content: "\f7c0";
}

/* line 1053, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-save:before {
  content: "\f0c7";
}

/* line 1054, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-schlix:before {
  content: "\f3ea";
}

/* line 1055, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-school:before {
  content: "\f549";
}

/* line 1056, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-screwdriver:before {
  content: "\f54a";
}

/* line 1057, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-scribd:before {
  content: "\f28a";
}

/* line 1058, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-scroll:before {
  content: "\f70e";
}

/* line 1059, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sd-card:before {
  content: "\f7c2";
}

/* line 1060, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-search:before {
  content: "\f002";
}

/* line 1061, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-search-dollar:before {
  content: "\f688";
}

/* line 1062, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-search-location:before {
  content: "\f689";
}

/* line 1063, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-search-minus:before {
  content: "\f010";
}

/* line 1064, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-search-plus:before {
  content: "\f00e";
}

/* line 1065, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-searchengin:before {
  content: "\f3eb";
}

/* line 1066, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-seedling:before {
  content: "\f4d8";
}

/* line 1067, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sellcast:before {
  content: "\f2da";
}

/* line 1068, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sellsy:before {
  content: "\f213";
}

/* line 1069, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-server:before {
  content: "\f233";
}

/* line 1070, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-servicestack:before {
  content: "\f3ec";
}

/* line 1071, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shapes:before {
  content: "\f61f";
}

/* line 1072, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-share:before {
  content: "\f064";
}

/* line 1073, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-share-alt:before {
  content: "\f1e0";
}

/* line 1074, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-share-alt-square:before {
  content: "\f1e1";
}

/* line 1075, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-share-square:before {
  content: "\f14d";
}

/* line 1076, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shekel-sign:before {
  content: "\f20b";
}

/* line 1077, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shield-alt:before {
  content: "\f3ed";
}

/* line 1078, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shield-virus:before {
  content: "\f96c";
}

/* line 1079, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ship:before {
  content: "\f21a";
}

/* line 1080, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shipping-fast:before {
  content: "\f48b";
}

/* line 1081, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shirtsinbulk:before {
  content: "\f214";
}

/* line 1082, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shoe-prints:before {
  content: "\f54b";
}

/* line 1083, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shopify:before {
  content: "\f957";
}

/* line 1084, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shopping-bag:before {
  content: "\f290";
}

/* line 1085, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shopping-basket:before {
  content: "\f291";
}

/* line 1086, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shopping-cart:before {
  content: "\f07a";
}

/* line 1087, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shopware:before {
  content: "\f5b5";
}

/* line 1088, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shower:before {
  content: "\f2cc";
}

/* line 1089, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-shuttle-van:before {
  content: "\f5b6";
}

/* line 1090, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sign:before {
  content: "\f4d9";
}

/* line 1091, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sign-in-alt:before {
  content: "\f2f6";
}

/* line 1092, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sign-language:before {
  content: "\f2a7";
}

/* line 1093, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sign-out-alt:before {
  content: "\f2f5";
}

/* line 1094, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-signal:before {
  content: "\f012";
}

/* line 1095, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-signature:before {
  content: "\f5b7";
}

/* line 1096, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sim-card:before {
  content: "\f7c4";
}

/* line 1097, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-simplybuilt:before {
  content: "\f215";
}

/* line 1098, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sistrix:before {
  content: "\f3ee";
}

/* line 1099, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sitemap:before {
  content: "\f0e8";
}

/* line 1100, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sith:before {
  content: "\f512";
}

/* line 1101, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-skating:before {
  content: "\f7c5";
}

/* line 1102, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sketch:before {
  content: "\f7c6";
}

/* line 1103, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-skiing:before {
  content: "\f7c9";
}

/* line 1104, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-skiing-nordic:before {
  content: "\f7ca";
}

/* line 1105, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-skull:before {
  content: "\f54c";
}

/* line 1106, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-skull-crossbones:before {
  content: "\f714";
}

/* line 1107, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-skyatlas:before {
  content: "\f216";
}

/* line 1108, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-skype:before {
  content: "\f17e";
}

/* line 1109, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-slack:before {
  content: "\f198";
}

/* line 1110, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-slack-hash:before {
  content: "\f3ef";
}

/* line 1111, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-slash:before {
  content: "\f715";
}

/* line 1112, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sleigh:before {
  content: "\f7cc";
}

/* line 1113, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sliders-h:before {
  content: "\f1de";
}

/* line 1114, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-slideshare:before {
  content: "\f1e7";
}

/* line 1115, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-smile:before {
  content: "\f118";
}

/* line 1116, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-smile-beam:before {
  content: "\f5b8";
}

/* line 1117, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-smile-wink:before {
  content: "\f4da";
}

/* line 1118, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-smog:before {
  content: "\f75f";
}

/* line 1119, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-smoking:before {
  content: "\f48d";
}

/* line 1120, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-smoking-ban:before {
  content: "\f54d";
}

/* line 1121, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sms:before {
  content: "\f7cd";
}

/* line 1122, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-snapchat:before {
  content: "\f2ab";
}

/* line 1123, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-snapchat-ghost:before {
  content: "\f2ac";
}

/* line 1124, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-snapchat-square:before {
  content: "\f2ad";
}

/* line 1125, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-snowboarding:before {
  content: "\f7ce";
}

/* line 1126, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-snowflake:before {
  content: "\f2dc";
}

/* line 1127, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-snowman:before {
  content: "\f7d0";
}

/* line 1128, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-snowplow:before {
  content: "\f7d2";
}

/* line 1129, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-soap:before {
  content: "\f96e";
}

/* line 1130, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-socks:before {
  content: "\f696";
}

/* line 1131, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-solar-panel:before {
  content: "\f5ba";
}

/* line 1132, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort:before {
  content: "\f0dc";
}

/* line 1133, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-alpha-down:before {
  content: "\f15d";
}

/* line 1134, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-alpha-down-alt:before {
  content: "\f881";
}

/* line 1135, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-alpha-up:before {
  content: "\f15e";
}

/* line 1136, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-alpha-up-alt:before {
  content: "\f882";
}

/* line 1137, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-amount-down:before {
  content: "\f160";
}

/* line 1138, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-amount-down-alt:before {
  content: "\f884";
}

/* line 1139, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-amount-up:before {
  content: "\f161";
}

/* line 1140, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-amount-up-alt:before {
  content: "\f885";
}

/* line 1141, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-down:before {
  content: "\f0dd";
}

/* line 1142, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-numeric-down:before {
  content: "\f162";
}

/* line 1143, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-numeric-down-alt:before {
  content: "\f886";
}

/* line 1144, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-numeric-up:before {
  content: "\f163";
}

/* line 1145, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-numeric-up-alt:before {
  content: "\f887";
}

/* line 1146, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sort-up:before {
  content: "\f0de";
}

/* line 1147, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-soundcloud:before {
  content: "\f1be";
}

/* line 1148, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sourcetree:before {
  content: "\f7d3";
}

/* line 1149, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-spa:before {
  content: "\f5bb";
}

/* line 1150, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-space-shuttle:before {
  content: "\f197";
}

/* line 1151, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-speakap:before {
  content: "\f3f3";
}

/* line 1152, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-speaker-deck:before {
  content: "\f83c";
}

/* line 1153, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-spell-check:before {
  content: "\f891";
}

/* line 1154, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-spider:before {
  content: "\f717";
}

/* line 1155, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-spinner:before {
  content: "\f110";
}

/* line 1156, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-splotch:before {
  content: "\f5bc";
}

/* line 1157, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-spotify:before {
  content: "\f1bc";
}

/* line 1158, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-spray-can:before {
  content: "\f5bd";
}

/* line 1159, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-square:before {
  content: "\f0c8";
}

/* line 1160, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-square-full:before {
  content: "\f45c";
}

/* line 1161, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-square-root-alt:before {
  content: "\f698";
}

/* line 1162, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-squarespace:before {
  content: "\f5be";
}

/* line 1163, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stack-exchange:before {
  content: "\f18d";
}

/* line 1164, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stack-overflow:before {
  content: "\f16c";
}

/* line 1165, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stackpath:before {
  content: "\f842";
}

/* line 1166, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stamp:before {
  content: "\f5bf";
}

/* line 1167, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-star:before {
  content: "\f005";
}

/* line 1168, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-star-and-crescent:before {
  content: "\f699";
}

/* line 1169, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-star-half:before {
  content: "\f089";
}

/* line 1170, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-star-half-alt:before {
  content: "\f5c0";
}

/* line 1171, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-star-of-david:before {
  content: "\f69a";
}

/* line 1172, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-star-of-life:before {
  content: "\f621";
}

/* line 1173, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-staylinked:before {
  content: "\f3f5";
}

/* line 1174, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-steam:before {
  content: "\f1b6";
}

/* line 1175, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-steam-square:before {
  content: "\f1b7";
}

/* line 1176, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-steam-symbol:before {
  content: "\f3f6";
}

/* line 1177, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-step-backward:before {
  content: "\f048";
}

/* line 1178, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-step-forward:before {
  content: "\f051";
}

/* line 1179, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stethoscope:before {
  content: "\f0f1";
}

/* line 1180, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sticker-mule:before {
  content: "\f3f7";
}

/* line 1181, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sticky-note:before {
  content: "\f249";
}

/* line 1182, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stop:before {
  content: "\f04d";
}

/* line 1183, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stop-circle:before {
  content: "\f28d";
}

/* line 1184, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stopwatch:before {
  content: "\f2f2";
}

/* line 1185, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stopwatch-20:before {
  content: "\f96f";
}

/* line 1186, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-store:before {
  content: "\f54e";
}

/* line 1187, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-store-alt:before {
  content: "\f54f";
}

/* line 1188, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-store-alt-slash:before {
  content: "\f970";
}

/* line 1189, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-store-slash:before {
  content: "\f971";
}

/* line 1190, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-strava:before {
  content: "\f428";
}

/* line 1191, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stream:before {
  content: "\f550";
}

/* line 1192, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-street-view:before {
  content: "\f21d";
}

/* line 1193, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-strikethrough:before {
  content: "\f0cc";
}

/* line 1194, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stripe:before {
  content: "\f429";
}

/* line 1195, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stripe-s:before {
  content: "\f42a";
}

/* line 1196, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stroopwafel:before {
  content: "\f551";
}

/* line 1197, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-studiovinari:before {
  content: "\f3f8";
}

/* line 1198, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stumbleupon:before {
  content: "\f1a4";
}

/* line 1199, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

/* line 1200, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-subscript:before {
  content: "\f12c";
}

/* line 1201, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-subway:before {
  content: "\f239";
}

/* line 1202, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-suitcase:before {
  content: "\f0f2";
}

/* line 1203, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-suitcase-rolling:before {
  content: "\f5c1";
}

/* line 1204, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sun:before {
  content: "\f185";
}

/* line 1205, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-superpowers:before {
  content: "\f2dd";
}

/* line 1206, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-superscript:before {
  content: "\f12b";
}

/* line 1207, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-supple:before {
  content: "\f3f9";
}

/* line 1208, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-surprise:before {
  content: "\f5c2";
}

/* line 1209, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-suse:before {
  content: "\f7d6";
}

/* line 1210, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-swatchbook:before {
  content: "\f5c3";
}

/* line 1211, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-swift:before {
  content: "\f8e1";
}

/* line 1212, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-swimmer:before {
  content: "\f5c4";
}

/* line 1213, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-swimming-pool:before {
  content: "\f5c5";
}

/* line 1214, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-symfony:before {
  content: "\f83d";
}

/* line 1215, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-synagogue:before {
  content: "\f69b";
}

/* line 1216, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sync:before {
  content: "\f021";
}

/* line 1217, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-sync-alt:before {
  content: "\f2f1";
}

/* line 1218, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-syringe:before {
  content: "\f48e";
}

/* line 1219, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-table:before {
  content: "\f0ce";
}

/* line 1220, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-table-tennis:before {
  content: "\f45d";
}

/* line 1221, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tablet:before {
  content: "\f10a";
}

/* line 1222, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tablet-alt:before {
  content: "\f3fa";
}

/* line 1223, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tablets:before {
  content: "\f490";
}

/* line 1224, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tachometer-alt:before {
  content: "\f3fd";
}

/* line 1225, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tag:before {
  content: "\f02b";
}

/* line 1226, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tags:before {
  content: "\f02c";
}

/* line 1227, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tape:before {
  content: "\f4db";
}

/* line 1228, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tasks:before {
  content: "\f0ae";
}

/* line 1229, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-taxi:before {
  content: "\f1ba";
}

/* line 1230, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-teamspeak:before {
  content: "\f4f9";
}

/* line 1231, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-teeth:before {
  content: "\f62e";
}

/* line 1232, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-teeth-open:before {
  content: "\f62f";
}

/* line 1233, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-telegram:before {
  content: "\f2c6";
}

/* line 1234, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-telegram-plane:before {
  content: "\f3fe";
}

/* line 1235, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-temperature-high:before {
  content: "\f769";
}

/* line 1236, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-temperature-low:before {
  content: "\f76b";
}

/* line 1237, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tencent-weibo:before {
  content: "\f1d5";
}

/* line 1238, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tenge:before {
  content: "\f7d7";
}

/* line 1239, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-terminal:before {
  content: "\f120";
}

/* line 1240, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-text-height:before {
  content: "\f034";
}

/* line 1241, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-text-width:before {
  content: "\f035";
}

/* line 1242, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-th:before {
  content: "\f00a";
}

/* line 1243, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-th-large:before {
  content: "\f009";
}

/* line 1244, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-th-list:before {
  content: "\f00b";
}

/* line 1245, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-the-red-yeti:before {
  content: "\f69d";
}

/* line 1246, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-theater-masks:before {
  content: "\f630";
}

/* line 1247, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-themeco:before {
  content: "\f5c6";
}

/* line 1248, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-themeisle:before {
  content: "\f2b2";
}

/* line 1249, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-thermometer:before {
  content: "\f491";
}

/* line 1250, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-thermometer-empty:before {
  content: "\f2cb";
}

/* line 1251, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-thermometer-full:before {
  content: "\f2c7";
}

/* line 1252, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-thermometer-half:before {
  content: "\f2c9";
}

/* line 1253, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-thermometer-quarter:before {
  content: "\f2ca";
}

/* line 1254, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

/* line 1255, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-think-peaks:before {
  content: "\f731";
}

/* line 1256, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-thumbs-down:before {
  content: "\f165";
}

/* line 1257, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-thumbs-up:before {
  content: "\f164";
}

/* line 1258, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-thumbtack:before {
  content: "\f08d";
}

/* line 1259, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ticket-alt:before {
  content: "\f3ff";
}

/* line 1260, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-times:before {
  content: "\f00d";
}

/* line 1261, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-times-circle:before {
  content: "\f057";
}

/* line 1262, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tint:before {
  content: "\f043";
}

/* line 1263, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tint-slash:before {
  content: "\f5c7";
}

/* line 1264, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tired:before {
  content: "\f5c8";
}

/* line 1265, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-toggle-off:before {
  content: "\f204";
}

/* line 1266, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-toggle-on:before {
  content: "\f205";
}

/* line 1267, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-toilet:before {
  content: "\f7d8";
}

/* line 1268, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-toilet-paper:before {
  content: "\f71e";
}

/* line 1269, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-toilet-paper-slash:before {
  content: "\f972";
}

/* line 1270, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-toolbox:before {
  content: "\f552";
}

/* line 1271, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tools:before {
  content: "\f7d9";
}

/* line 1272, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tooth:before {
  content: "\f5c9";
}

/* line 1273, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-torah:before {
  content: "\f6a0";
}

/* line 1274, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-torii-gate:before {
  content: "\f6a1";
}

/* line 1275, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tractor:before {
  content: "\f722";
}

/* line 1276, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-trade-federation:before {
  content: "\f513";
}

/* line 1277, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-trademark:before {
  content: "\f25c";
}

/* line 1278, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-traffic-light:before {
  content: "\f637";
}

/* line 1279, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-trailer:before {
  content: "\f941";
}

/* line 1280, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-train:before {
  content: "\f238";
}

/* line 1281, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tram:before {
  content: "\f7da";
}

/* line 1282, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-transgender:before {
  content: "\f224";
}

/* line 1283, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-transgender-alt:before {
  content: "\f225";
}

/* line 1284, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-trash:before {
  content: "\f1f8";
}

/* line 1285, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-trash-alt:before {
  content: "\f2ed";
}

/* line 1286, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-trash-restore:before {
  content: "\f829";
}

/* line 1287, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-trash-restore-alt:before {
  content: "\f82a";
}

/* line 1288, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tree:before {
  content: "\f1bb";
}

/* line 1289, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-trello:before {
  content: "\f181";
}

/* line 1290, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tripadvisor:before {
  content: "\f262";
}

/* line 1291, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-trophy:before {
  content: "\f091";
}

/* line 1292, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-truck:before {
  content: "\f0d1";
}

/* line 1293, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-truck-loading:before {
  content: "\f4de";
}

/* line 1294, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-truck-monster:before {
  content: "\f63b";
}

/* line 1295, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-truck-moving:before {
  content: "\f4df";
}

/* line 1296, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-truck-pickup:before {
  content: "\f63c";
}

/* line 1297, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tshirt:before {
  content: "\f553";
}

/* line 1298, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tty:before {
  content: "\f1e4";
}

/* line 1299, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tumblr:before {
  content: "\f173";
}

/* line 1300, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tumblr-square:before {
  content: "\f174";
}

/* line 1301, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-tv:before {
  content: "\f26c";
}

/* line 1302, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-twitch:before {
  content: "\f1e8";
}

/* line 1303, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-twitter:before {
  content: "\f099";
}

/* line 1304, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-twitter-square:before {
  content: "\f081";
}

/* line 1305, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-typo3:before {
  content: "\f42b";
}

/* line 1306, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-uber:before {
  content: "\f402";
}

/* line 1307, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ubuntu:before {
  content: "\f7df";
}

/* line 1308, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-uikit:before {
  content: "\f403";
}

/* line 1309, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-umbraco:before {
  content: "\f8e8";
}

/* line 1310, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-umbrella:before {
  content: "\f0e9";
}

/* line 1311, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-umbrella-beach:before {
  content: "\f5ca";
}

/* line 1312, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-underline:before {
  content: "\f0cd";
}

/* line 1313, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-undo:before {
  content: "\f0e2";
}

/* line 1314, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-undo-alt:before {
  content: "\f2ea";
}

/* line 1315, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-uniregistry:before {
  content: "\f404";
}

/* line 1316, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-unity:before {
  content: "\f949";
}

/* line 1317, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-universal-access:before {
  content: "\f29a";
}

/* line 1318, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-university:before {
  content: "\f19c";
}

/* line 1319, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-unlink:before {
  content: "\f127";
}

/* line 1320, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-unlock:before {
  content: "\f09c";
}

/* line 1321, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-unlock-alt:before {
  content: "\f13e";
}

/* line 1322, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-untappd:before {
  content: "\f405";
}

/* line 1323, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-upload:before {
  content: "\f093";
}

/* line 1324, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ups:before {
  content: "\f7e0";
}

/* line 1325, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-usb:before {
  content: "\f287";
}

/* line 1326, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user:before {
  content: "\f007";
}

/* line 1327, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-alt:before {
  content: "\f406";
}

/* line 1328, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-alt-slash:before {
  content: "\f4fa";
}

/* line 1329, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-astronaut:before {
  content: "\f4fb";
}

/* line 1330, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-check:before {
  content: "\f4fc";
}

/* line 1331, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-circle:before {
  content: "\f2bd";
}

/* line 1332, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-clock:before {
  content: "\f4fd";
}

/* line 1333, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-cog:before {
  content: "\f4fe";
}

/* line 1334, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-edit:before {
  content: "\f4ff";
}

/* line 1335, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-friends:before {
  content: "\f500";
}

/* line 1336, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-graduate:before {
  content: "\f501";
}

/* line 1337, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-injured:before {
  content: "\f728";
}

/* line 1338, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-lock:before {
  content: "\f502";
}

/* line 1339, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-md:before {
  content: "\f0f0";
}

/* line 1340, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-minus:before {
  content: "\f503";
}

/* line 1341, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-ninja:before {
  content: "\f504";
}

/* line 1342, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-nurse:before {
  content: "\f82f";
}

/* line 1343, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-plus:before {
  content: "\f234";
}

/* line 1344, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-secret:before {
  content: "\f21b";
}

/* line 1345, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-shield:before {
  content: "\f505";
}

/* line 1346, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-slash:before {
  content: "\f506";
}

/* line 1347, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-tag:before {
  content: "\f507";
}

/* line 1348, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-tie:before {
  content: "\f508";
}

/* line 1349, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-user-times:before {
  content: "\f235";
}

/* line 1350, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-users:before {
  content: "\f0c0";
}

/* line 1351, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-users-cog:before {
  content: "\f509";
}

/* line 1352, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-usps:before {
  content: "\f7e1";
}

/* line 1353, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-ussunnah:before {
  content: "\f407";
}

/* line 1354, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-utensil-spoon:before {
  content: "\f2e5";
}

/* line 1355, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-utensils:before {
  content: "\f2e7";
}

/* line 1356, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vaadin:before {
  content: "\f408";
}

/* line 1357, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vector-square:before {
  content: "\f5cb";
}

/* line 1358, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-venus:before {
  content: "\f221";
}

/* line 1359, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-venus-double:before {
  content: "\f226";
}

/* line 1360, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-venus-mars:before {
  content: "\f228";
}

/* line 1361, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-viacoin:before {
  content: "\f237";
}

/* line 1362, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-viadeo:before {
  content: "\f2a9";
}

/* line 1363, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-viadeo-square:before {
  content: "\f2aa";
}

/* line 1364, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vial:before {
  content: "\f492";
}

/* line 1365, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vials:before {
  content: "\f493";
}

/* line 1366, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-viber:before {
  content: "\f409";
}

/* line 1367, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-video:before {
  content: "\f03d";
}

/* line 1368, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-video-slash:before {
  content: "\f4e2";
}

/* line 1369, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vihara:before {
  content: "\f6a7";
}

/* line 1370, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vimeo:before {
  content: "\f40a";
}

/* line 1371, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vimeo-square:before {
  content: "\f194";
}

/* line 1372, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vimeo-v:before {
  content: "\f27d";
}

/* line 1373, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vine:before {
  content: "\f1ca";
}

/* line 1374, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-virus:before {
  content: "\f974";
}

/* line 1375, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-virus-slash:before {
  content: "\f975";
}

/* line 1376, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-viruses:before {
  content: "\f976";
}

/* line 1377, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vk:before {
  content: "\f189";
}

/* line 1378, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vnv:before {
  content: "\f40b";
}

/* line 1379, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-voicemail:before {
  content: "\f897";
}

/* line 1380, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-volleyball-ball:before {
  content: "\f45f";
}

/* line 1381, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-volume-down:before {
  content: "\f027";
}

/* line 1382, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-volume-mute:before {
  content: "\f6a9";
}

/* line 1383, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-volume-off:before {
  content: "\f026";
}

/* line 1384, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-volume-up:before {
  content: "\f028";
}

/* line 1385, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vote-yea:before {
  content: "\f772";
}

/* line 1386, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vr-cardboard:before {
  content: "\f729";
}

/* line 1387, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-vuejs:before {
  content: "\f41f";
}

/* line 1388, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-walking:before {
  content: "\f554";
}

/* line 1389, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wallet:before {
  content: "\f555";
}

/* line 1390, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-warehouse:before {
  content: "\f494";
}

/* line 1391, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-water:before {
  content: "\f773";
}

/* line 1392, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wave-square:before {
  content: "\f83e";
}

/* line 1393, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-waze:before {
  content: "\f83f";
}

/* line 1394, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-weebly:before {
  content: "\f5cc";
}

/* line 1395, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-weibo:before {
  content: "\f18a";
}

/* line 1396, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-weight:before {
  content: "\f496";
}

/* line 1397, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-weight-hanging:before {
  content: "\f5cd";
}

/* line 1398, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-weixin:before {
  content: "\f1d7";
}

/* line 1399, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-whatsapp:before {
  content: "\f232";
}

/* line 1400, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-whatsapp-square:before {
  content: "\f40c";
}

/* line 1401, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wheelchair:before {
  content: "\f193";
}

/* line 1402, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-whmcs:before {
  content: "\f40d";
}

/* line 1403, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wifi:before {
  content: "\f1eb";
}

/* line 1404, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wikipedia-w:before {
  content: "\f266";
}

/* line 1405, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wind:before {
  content: "\f72e";
}

/* line 1406, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-window-close:before {
  content: "\f410";
}

/* line 1407, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-window-maximize:before {
  content: "\f2d0";
}

/* line 1408, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-window-minimize:before {
  content: "\f2d1";
}

/* line 1409, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-window-restore:before {
  content: "\f2d2";
}

/* line 1410, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-windows:before {
  content: "\f17a";
}

/* line 1411, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wine-bottle:before {
  content: "\f72f";
}

/* line 1412, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wine-glass:before {
  content: "\f4e3";
}

/* line 1413, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wine-glass-alt:before {
  content: "\f5ce";
}

/* line 1414, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wix:before {
  content: "\f5cf";
}

/* line 1415, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wizards-of-the-coast:before {
  content: "\f730";
}

/* line 1416, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wolf-pack-battalion:before {
  content: "\f514";
}

/* line 1417, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-won-sign:before {
  content: "\f159";
}

/* line 1418, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wordpress:before {
  content: "\f19a";
}

/* line 1419, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wordpress-simple:before {
  content: "\f411";
}

/* line 1420, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wpbeginner:before {
  content: "\f297";
}

/* line 1421, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wpexplorer:before {
  content: "\f2de";
}

/* line 1422, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wpforms:before {
  content: "\f298";
}

/* line 1423, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wpressr:before {
  content: "\f3e4";
}

/* line 1424, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-wrench:before {
  content: "\f0ad";
}

/* line 1425, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-x-ray:before {
  content: "\f497";
}

/* line 1426, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-xbox:before {
  content: "\f412";
}

/* line 1427, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-xing:before {
  content: "\f168";
}

/* line 1428, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-xing-square:before {
  content: "\f169";
}

/* line 1429, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-y-combinator:before {
  content: "\f23b";
}

/* line 1430, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-yahoo:before {
  content: "\f19e";
}

/* line 1431, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-yammer:before {
  content: "\f840";
}

/* line 1432, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-yandex:before {
  content: "\f413";
}

/* line 1433, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-yandex-international:before {
  content: "\f414";
}

/* line 1434, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-yarn:before {
  content: "\f7e3";
}

/* line 1435, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-yelp:before {
  content: "\f1e9";
}

/* line 1436, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-yen-sign:before {
  content: "\f157";
}

/* line 1437, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-yin-yang:before {
  content: "\f6ad";
}

/* line 1438, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-yoast:before {
  content: "\f2b1";
}

/* line 1439, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-youtube:before {
  content: "\f167";
}

/* line 1440, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-youtube-square:before {
  content: "\f431";
}

/* line 1441, node_modules/@fortawesome/fontawesome-free/scss/_icons.scss */
.fa-zhihu:before {
  content: "\f63f";
}

/* line 4, node_modules/@fortawesome/fontawesome-free/scss/_screen-reader.scss */
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* line 47, node_modules/@fortawesome/fontawesome-free/scss/_mixins.scss */
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

[class^="ti-"],
[class*=" ti-"] {
  font-family: "themify";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 26, src/assets/scss/vendors/themify-icons.css */
.ti-wand:before {
  content: "\e600";
}

/* line 29, src/assets/scss/vendors/themify-icons.css */
.ti-volume:before {
  content: "\e601";
}

/* line 32, src/assets/scss/vendors/themify-icons.css */
.ti-user:before {
  content: "\e602";
}

/* line 35, src/assets/scss/vendors/themify-icons.css */
.ti-unlock:before {
  content: "\e603";
}

/* line 38, src/assets/scss/vendors/themify-icons.css */
.ti-unlink:before {
  content: "\e604";
}

/* line 41, src/assets/scss/vendors/themify-icons.css */
.ti-trash:before {
  content: "\e605";
}

/* line 44, src/assets/scss/vendors/themify-icons.css */
.ti-thought:before {
  content: "\e606";
}

/* line 47, src/assets/scss/vendors/themify-icons.css */
.ti-target:before {
  content: "\e607";
}

/* line 50, src/assets/scss/vendors/themify-icons.css */
.ti-tag:before {
  content: "\e608";
}

/* line 53, src/assets/scss/vendors/themify-icons.css */
.ti-tablet:before {
  content: "\e609";
}

/* line 56, src/assets/scss/vendors/themify-icons.css */
.ti-star:before {
  content: "\e60a";
}

/* line 59, src/assets/scss/vendors/themify-icons.css */
.ti-spray:before {
  content: "\e60b";
}

/* line 62, src/assets/scss/vendors/themify-icons.css */
.ti-signal:before {
  content: "\e60c";
}

/* line 65, src/assets/scss/vendors/themify-icons.css */
.ti-shopping-cart:before {
  content: "\e60d";
}

/* line 68, src/assets/scss/vendors/themify-icons.css */
.ti-shopping-cart-full:before {
  content: "\e60e";
}

/* line 71, src/assets/scss/vendors/themify-icons.css */
.ti-settings:before {
  content: "\e60f";
}

/* line 74, src/assets/scss/vendors/themify-icons.css */
.ti-search:before {
  content: "\e610";
}

/* line 77, src/assets/scss/vendors/themify-icons.css */
.ti-zoom-in:before {
  content: "\e611";
}

/* line 80, src/assets/scss/vendors/themify-icons.css */
.ti-zoom-out:before {
  content: "\e612";
}

/* line 83, src/assets/scss/vendors/themify-icons.css */
.ti-cut:before {
  content: "\e613";
}

/* line 86, src/assets/scss/vendors/themify-icons.css */
.ti-ruler:before {
  content: "\e614";
}

/* line 89, src/assets/scss/vendors/themify-icons.css */
.ti-ruler-pencil:before {
  content: "\e615";
}

/* line 92, src/assets/scss/vendors/themify-icons.css */
.ti-ruler-alt:before {
  content: "\e616";
}

/* line 95, src/assets/scss/vendors/themify-icons.css */
.ti-bookmark:before {
  content: "\e617";
}

/* line 98, src/assets/scss/vendors/themify-icons.css */
.ti-bookmark-alt:before {
  content: "\e618";
}

/* line 101, src/assets/scss/vendors/themify-icons.css */
.ti-reload:before {
  content: "\e619";
}

/* line 104, src/assets/scss/vendors/themify-icons.css */
.ti-plus:before {
  content: "\e61a";
}

/* line 107, src/assets/scss/vendors/themify-icons.css */
.ti-pin:before {
  content: "\e61b";
}

/* line 110, src/assets/scss/vendors/themify-icons.css */
.ti-pencil:before {
  content: "\e61c";
}

/* line 113, src/assets/scss/vendors/themify-icons.css */
.ti-pencil-alt:before {
  content: "\e61d";
}

/* line 116, src/assets/scss/vendors/themify-icons.css */
.ti-paint-roller:before {
  content: "\e61e";
}

/* line 119, src/assets/scss/vendors/themify-icons.css */
.ti-paint-bucket:before {
  content: "\e61f";
}

/* line 122, src/assets/scss/vendors/themify-icons.css */
.ti-na:before {
  content: "\e620";
}

/* line 125, src/assets/scss/vendors/themify-icons.css */
.ti-mobile:before {
  content: "\e621";
}

/* line 128, src/assets/scss/vendors/themify-icons.css */
.ti-minus:before {
  content: "\e622";
}

/* line 131, src/assets/scss/vendors/themify-icons.css */
.ti-medall:before {
  content: "\e623";
}

/* line 134, src/assets/scss/vendors/themify-icons.css */
.ti-medall-alt:before {
  content: "\e624";
}

/* line 137, src/assets/scss/vendors/themify-icons.css */
.ti-marker:before {
  content: "\e625";
}

/* line 140, src/assets/scss/vendors/themify-icons.css */
.ti-marker-alt:before {
  content: "\e626";
}

/* line 143, src/assets/scss/vendors/themify-icons.css */
.ti-arrow-up:before {
  content: "\e627";
}

/* line 146, src/assets/scss/vendors/themify-icons.css */
.ti-arrow-right:before {
  content: "\e628";
}

/* line 149, src/assets/scss/vendors/themify-icons.css */
.ti-arrow-left:before {
  content: "\e629";
}

/* line 152, src/assets/scss/vendors/themify-icons.css */
.ti-arrow-down:before {
  content: "\e62a";
}

/* line 155, src/assets/scss/vendors/themify-icons.css */
.ti-lock:before {
  content: "\e62b";
}

/* line 158, src/assets/scss/vendors/themify-icons.css */
.ti-location-arrow:before {
  content: "\e62c";
}

/* line 161, src/assets/scss/vendors/themify-icons.css */
.ti-link:before {
  content: "\e62d";
}

/* line 164, src/assets/scss/vendors/themify-icons.css */
.ti-layout:before {
  content: "\e62e";
}

/* line 167, src/assets/scss/vendors/themify-icons.css */
.ti-layers:before {
  content: "\e62f";
}

/* line 170, src/assets/scss/vendors/themify-icons.css */
.ti-layers-alt:before {
  content: "\e630";
}

/* line 173, src/assets/scss/vendors/themify-icons.css */
.ti-key:before {
  content: "\e631";
}

/* line 176, src/assets/scss/vendors/themify-icons.css */
.ti-import:before {
  content: "\e632";
}

/* line 179, src/assets/scss/vendors/themify-icons.css */
.ti-image:before {
  content: "\e633";
}

/* line 182, src/assets/scss/vendors/themify-icons.css */
.ti-heart:before {
  content: "\e634";
}

/* line 185, src/assets/scss/vendors/themify-icons.css */
.ti-heart-broken:before {
  content: "\e635";
}

/* line 188, src/assets/scss/vendors/themify-icons.css */
.ti-hand-stop:before {
  content: "\e636";
}

/* line 191, src/assets/scss/vendors/themify-icons.css */
.ti-hand-open:before {
  content: "\e637";
}

/* line 194, src/assets/scss/vendors/themify-icons.css */
.ti-hand-drag:before {
  content: "\e638";
}

/* line 197, src/assets/scss/vendors/themify-icons.css */
.ti-folder:before {
  content: "\e639";
}

/* line 200, src/assets/scss/vendors/themify-icons.css */
.ti-flag:before {
  content: "\e63a";
}

/* line 203, src/assets/scss/vendors/themify-icons.css */
.ti-flag-alt:before {
  content: "\e63b";
}

/* line 206, src/assets/scss/vendors/themify-icons.css */
.ti-flag-alt-2:before {
  content: "\e63c";
}

/* line 209, src/assets/scss/vendors/themify-icons.css */
.ti-eye:before {
  content: "\e63d";
}

/* line 212, src/assets/scss/vendors/themify-icons.css */
.ti-export:before {
  content: "\e63e";
}

/* line 215, src/assets/scss/vendors/themify-icons.css */
.ti-exchange-vertical:before {
  content: "\e63f";
}

/* line 218, src/assets/scss/vendors/themify-icons.css */
.ti-desktop:before {
  content: "\e640";
}

/* line 221, src/assets/scss/vendors/themify-icons.css */
.ti-cup:before {
  content: "\e641";
}

/* line 224, src/assets/scss/vendors/themify-icons.css */
.ti-crown:before {
  content: "\e642";
}

/* line 227, src/assets/scss/vendors/themify-icons.css */
.ti-comments:before {
  content: "\e643";
}

/* line 230, src/assets/scss/vendors/themify-icons.css */
.ti-comment:before {
  content: "\e644";
}

/* line 233, src/assets/scss/vendors/themify-icons.css */
.ti-comment-alt:before {
  content: "\e645";
}

/* line 236, src/assets/scss/vendors/themify-icons.css */
.ti-close:before {
  content: "\e646";
}

/* line 239, src/assets/scss/vendors/themify-icons.css */
.ti-clip:before {
  content: "\e647";
}

/* line 242, src/assets/scss/vendors/themify-icons.css */
.ti-angle-up:before {
  content: "\e648";
}

/* line 245, src/assets/scss/vendors/themify-icons.css */
.ti-angle-right:before {
  content: "\e649";
}

/* line 248, src/assets/scss/vendors/themify-icons.css */
.ti-angle-left:before {
  content: "\e64a";
}

/* line 251, src/assets/scss/vendors/themify-icons.css */
.ti-angle-down:before {
  content: "\e64b";
}

/* line 254, src/assets/scss/vendors/themify-icons.css */
.ti-check:before {
  content: "\e64c";
}

/* line 257, src/assets/scss/vendors/themify-icons.css */
.ti-check-box:before {
  content: "\e64d";
}

/* line 260, src/assets/scss/vendors/themify-icons.css */
.ti-camera:before {
  content: "\e64e";
}

/* line 263, src/assets/scss/vendors/themify-icons.css */
.ti-announcement:before {
  content: "\e64f";
}

/* line 266, src/assets/scss/vendors/themify-icons.css */
.ti-brush:before {
  content: "\e650";
}

/* line 269, src/assets/scss/vendors/themify-icons.css */
.ti-briefcase:before {
  content: "\e651";
}

/* line 272, src/assets/scss/vendors/themify-icons.css */
.ti-bolt:before {
  content: "\e652";
}

/* line 275, src/assets/scss/vendors/themify-icons.css */
.ti-bolt-alt:before {
  content: "\e653";
}

/* line 278, src/assets/scss/vendors/themify-icons.css */
.ti-blackboard:before {
  content: "\e654";
}

/* line 281, src/assets/scss/vendors/themify-icons.css */
.ti-bag:before {
  content: "\e655";
}

/* line 284, src/assets/scss/vendors/themify-icons.css */
.ti-move:before {
  content: "\e656";
}

/* line 287, src/assets/scss/vendors/themify-icons.css */
.ti-arrows-vertical:before {
  content: "\e657";
}

/* line 290, src/assets/scss/vendors/themify-icons.css */
.ti-arrows-horizontal:before {
  content: "\e658";
}

/* line 293, src/assets/scss/vendors/themify-icons.css */
.ti-fullscreen:before {
  content: "\e659";
}

/* line 296, src/assets/scss/vendors/themify-icons.css */
.ti-arrow-top-right:before {
  content: "\e65a";
}

/* line 299, src/assets/scss/vendors/themify-icons.css */
.ti-arrow-top-left:before {
  content: "\e65b";
}

/* line 302, src/assets/scss/vendors/themify-icons.css */
.ti-arrow-circle-up:before {
  content: "\e65c";
}

/* line 305, src/assets/scss/vendors/themify-icons.css */
.ti-arrow-circle-right:before {
  content: "\e65d";
}

/* line 308, src/assets/scss/vendors/themify-icons.css */
.ti-arrow-circle-left:before {
  content: "\e65e";
}

/* line 311, src/assets/scss/vendors/themify-icons.css */
.ti-arrow-circle-down:before {
  content: "\e65f";
}

/* line 314, src/assets/scss/vendors/themify-icons.css */
.ti-angle-double-up:before {
  content: "\e660";
}

/* line 317, src/assets/scss/vendors/themify-icons.css */
.ti-angle-double-right:before {
  content: "\e661";
}

/* line 320, src/assets/scss/vendors/themify-icons.css */
.ti-angle-double-left:before {
  content: "\e662";
}

/* line 323, src/assets/scss/vendors/themify-icons.css */
.ti-angle-double-down:before {
  content: "\e663";
}

/* line 326, src/assets/scss/vendors/themify-icons.css */
.ti-zip:before {
  content: "\e664";
}

/* line 329, src/assets/scss/vendors/themify-icons.css */
.ti-world:before {
  content: "\e665";
}

/* line 332, src/assets/scss/vendors/themify-icons.css */
.ti-wheelchair:before {
  content: "\e666";
}

/* line 335, src/assets/scss/vendors/themify-icons.css */
.ti-view-list:before {
  content: "\e667";
}

/* line 338, src/assets/scss/vendors/themify-icons.css */
.ti-view-list-alt:before {
  content: "\e668";
}

/* line 341, src/assets/scss/vendors/themify-icons.css */
.ti-view-grid:before {
  content: "\e669";
}

/* line 344, src/assets/scss/vendors/themify-icons.css */
.ti-uppercase:before {
  content: "\e66a";
}

/* line 347, src/assets/scss/vendors/themify-icons.css */
.ti-upload:before {
  content: "\e66b";
}

/* line 350, src/assets/scss/vendors/themify-icons.css */
.ti-underline:before {
  content: "\e66c";
}

/* line 353, src/assets/scss/vendors/themify-icons.css */
.ti-truck:before {
  content: "\e66d";
}

/* line 356, src/assets/scss/vendors/themify-icons.css */
.ti-timer:before {
  content: "\e66e";
}

/* line 359, src/assets/scss/vendors/themify-icons.css */
.ti-ticket:before {
  content: "\e66f";
}

/* line 362, src/assets/scss/vendors/themify-icons.css */
.ti-thumb-up:before {
  content: "\e670";
}

/* line 365, src/assets/scss/vendors/themify-icons.css */
.ti-thumb-down:before {
  content: "\e671";
}

/* line 368, src/assets/scss/vendors/themify-icons.css */
.ti-text:before {
  content: "\e672";
}

/* line 371, src/assets/scss/vendors/themify-icons.css */
.ti-stats-up:before {
  content: "\e673";
}

/* line 374, src/assets/scss/vendors/themify-icons.css */
.ti-stats-down:before {
  content: "\e674";
}

/* line 377, src/assets/scss/vendors/themify-icons.css */
.ti-split-v:before {
  content: "\e675";
}

/* line 380, src/assets/scss/vendors/themify-icons.css */
.ti-split-h:before {
  content: "\e676";
}

/* line 383, src/assets/scss/vendors/themify-icons.css */
.ti-smallcap:before {
  content: "\e677";
}

/* line 386, src/assets/scss/vendors/themify-icons.css */
.ti-shine:before {
  content: "\e678";
}

/* line 389, src/assets/scss/vendors/themify-icons.css */
.ti-shift-right:before {
  content: "\e679";
}

/* line 392, src/assets/scss/vendors/themify-icons.css */
.ti-shift-left:before {
  content: "\e67a";
}

/* line 395, src/assets/scss/vendors/themify-icons.css */
.ti-shield:before {
  content: "\e67b";
}

/* line 398, src/assets/scss/vendors/themify-icons.css */
.ti-notepad:before {
  content: "\e67c";
}

/* line 401, src/assets/scss/vendors/themify-icons.css */
.ti-server:before {
  content: "\e67d";
}

/* line 404, src/assets/scss/vendors/themify-icons.css */
.ti-quote-right:before {
  content: "\e67e";
}

/* line 407, src/assets/scss/vendors/themify-icons.css */
.ti-quote-left:before {
  content: "\e67f";
}

/* line 410, src/assets/scss/vendors/themify-icons.css */
.ti-pulse:before {
  content: "\e680";
}

/* line 413, src/assets/scss/vendors/themify-icons.css */
.ti-printer:before {
  content: "\e681";
}

/* line 416, src/assets/scss/vendors/themify-icons.css */
.ti-power-off:before {
  content: "\e682";
}

/* line 419, src/assets/scss/vendors/themify-icons.css */
.ti-plug:before {
  content: "\e683";
}

/* line 422, src/assets/scss/vendors/themify-icons.css */
.ti-pie-chart:before {
  content: "\e684";
}

/* line 425, src/assets/scss/vendors/themify-icons.css */
.ti-paragraph:before {
  content: "\e685";
}

/* line 428, src/assets/scss/vendors/themify-icons.css */
.ti-panel:before {
  content: "\e686";
}

/* line 431, src/assets/scss/vendors/themify-icons.css */
.ti-package:before {
  content: "\e687";
}

/* line 434, src/assets/scss/vendors/themify-icons.css */
.ti-music:before {
  content: "\e688";
}

/* line 437, src/assets/scss/vendors/themify-icons.css */
.ti-music-alt:before {
  content: "\e689";
}

/* line 440, src/assets/scss/vendors/themify-icons.css */
.ti-mouse:before {
  content: "\e68a";
}

/* line 443, src/assets/scss/vendors/themify-icons.css */
.ti-mouse-alt:before {
  content: "\e68b";
}

/* line 446, src/assets/scss/vendors/themify-icons.css */
.ti-money:before {
  content: "\e68c";
}

/* line 449, src/assets/scss/vendors/themify-icons.css */
.ti-microphone:before {
  content: "\e68d";
}

/* line 452, src/assets/scss/vendors/themify-icons.css */
.ti-menu:before {
  content: "\e68e";
}

/* line 455, src/assets/scss/vendors/themify-icons.css */
.ti-menu-alt:before {
  content: "\e68f";
}

/* line 458, src/assets/scss/vendors/themify-icons.css */
.ti-map:before {
  content: "\e690";
}

/* line 461, src/assets/scss/vendors/themify-icons.css */
.ti-map-alt:before {
  content: "\e691";
}

/* line 464, src/assets/scss/vendors/themify-icons.css */
.ti-loop:before {
  content: "\e692";
}

/* line 467, src/assets/scss/vendors/themify-icons.css */
.ti-location-pin:before {
  content: "\e693";
}

/* line 470, src/assets/scss/vendors/themify-icons.css */
.ti-list:before {
  content: "\e694";
}

/* line 473, src/assets/scss/vendors/themify-icons.css */
.ti-light-bulb:before {
  content: "\e695";
}

/* line 476, src/assets/scss/vendors/themify-icons.css */
.ti-Italic:before {
  content: "\e696";
}

/* line 479, src/assets/scss/vendors/themify-icons.css */
.ti-info:before {
  content: "\e697";
}

/* line 482, src/assets/scss/vendors/themify-icons.css */
.ti-infinite:before {
  content: "\e698";
}

/* line 485, src/assets/scss/vendors/themify-icons.css */
.ti-id-badge:before {
  content: "\e699";
}

/* line 488, src/assets/scss/vendors/themify-icons.css */
.ti-hummer:before {
  content: "\e69a";
}

/* line 491, src/assets/scss/vendors/themify-icons.css */
.ti-home:before {
  content: "\e69b";
}

/* line 494, src/assets/scss/vendors/themify-icons.css */
.ti-help:before {
  content: "\e69c";
}

/* line 497, src/assets/scss/vendors/themify-icons.css */
.ti-headphone:before {
  content: "\e69d";
}

/* line 500, src/assets/scss/vendors/themify-icons.css */
.ti-harddrives:before {
  content: "\e69e";
}

/* line 503, src/assets/scss/vendors/themify-icons.css */
.ti-harddrive:before {
  content: "\e69f";
}

/* line 506, src/assets/scss/vendors/themify-icons.css */
.ti-gift:before {
  content: "\e6a0";
}

/* line 509, src/assets/scss/vendors/themify-icons.css */
.ti-game:before {
  content: "\e6a1";
}

/* line 512, src/assets/scss/vendors/themify-icons.css */
.ti-filter:before {
  content: "\e6a2";
}

/* line 515, src/assets/scss/vendors/themify-icons.css */
.ti-files:before {
  content: "\e6a3";
}

/* line 518, src/assets/scss/vendors/themify-icons.css */
.ti-file:before {
  content: "\e6a4";
}

/* line 521, src/assets/scss/vendors/themify-icons.css */
.ti-eraser:before {
  content: "\e6a5";
}

/* line 524, src/assets/scss/vendors/themify-icons.css */
.ti-envelope:before {
  content: "\e6a6";
}

/* line 527, src/assets/scss/vendors/themify-icons.css */
.ti-download:before {
  content: "\e6a7";
}

/* line 530, src/assets/scss/vendors/themify-icons.css */
.ti-direction:before {
  content: "\e6a8";
}

/* line 533, src/assets/scss/vendors/themify-icons.css */
.ti-direction-alt:before {
  content: "\e6a9";
}

/* line 536, src/assets/scss/vendors/themify-icons.css */
.ti-dashboard:before {
  content: "\e6aa";
}

/* line 539, src/assets/scss/vendors/themify-icons.css */
.ti-control-stop:before {
  content: "\e6ab";
}

/* line 542, src/assets/scss/vendors/themify-icons.css */
.ti-control-shuffle:before {
  content: "\e6ac";
}

/* line 545, src/assets/scss/vendors/themify-icons.css */
.ti-control-play:before {
  content: "\e6ad";
}

/* line 548, src/assets/scss/vendors/themify-icons.css */
.ti-control-pause:before {
  content: "\e6ae";
}

/* line 551, src/assets/scss/vendors/themify-icons.css */
.ti-control-forward:before {
  content: "\e6af";
}

/* line 554, src/assets/scss/vendors/themify-icons.css */
.ti-control-backward:before {
  content: "\e6b0";
}

/* line 557, src/assets/scss/vendors/themify-icons.css */
.ti-cloud:before {
  content: "\e6b1";
}

/* line 560, src/assets/scss/vendors/themify-icons.css */
.ti-cloud-up:before {
  content: "\e6b2";
}

/* line 563, src/assets/scss/vendors/themify-icons.css */
.ti-cloud-down:before {
  content: "\e6b3";
}

/* line 566, src/assets/scss/vendors/themify-icons.css */
.ti-clipboard:before {
  content: "\e6b4";
}

/* line 569, src/assets/scss/vendors/themify-icons.css */
.ti-car:before {
  content: "\e6b5";
}

/* line 572, src/assets/scss/vendors/themify-icons.css */
.ti-calendar:before {
  content: "\e6b6";
}

/* line 575, src/assets/scss/vendors/themify-icons.css */
.ti-book:before {
  content: "\e6b7";
}

/* line 578, src/assets/scss/vendors/themify-icons.css */
.ti-bell:before {
  content: "\e6b8";
}

/* line 581, src/assets/scss/vendors/themify-icons.css */
.ti-basketball:before {
  content: "\e6b9";
}

/* line 584, src/assets/scss/vendors/themify-icons.css */
.ti-bar-chart:before {
  content: "\e6ba";
}

/* line 587, src/assets/scss/vendors/themify-icons.css */
.ti-bar-chart-alt:before {
  content: "\e6bb";
}

/* line 590, src/assets/scss/vendors/themify-icons.css */
.ti-back-right:before {
  content: "\e6bc";
}

/* line 593, src/assets/scss/vendors/themify-icons.css */
.ti-back-left:before {
  content: "\e6bd";
}

/* line 596, src/assets/scss/vendors/themify-icons.css */
.ti-arrows-corner:before {
  content: "\e6be";
}

/* line 599, src/assets/scss/vendors/themify-icons.css */
.ti-archive:before {
  content: "\e6bf";
}

/* line 602, src/assets/scss/vendors/themify-icons.css */
.ti-anchor:before {
  content: "\e6c0";
}

/* line 605, src/assets/scss/vendors/themify-icons.css */
.ti-align-right:before {
  content: "\e6c1";
}

/* line 608, src/assets/scss/vendors/themify-icons.css */
.ti-align-left:before {
  content: "\e6c2";
}

/* line 611, src/assets/scss/vendors/themify-icons.css */
.ti-align-justify:before {
  content: "\e6c3";
}

/* line 614, src/assets/scss/vendors/themify-icons.css */
.ti-align-center:before {
  content: "\e6c4";
}

/* line 617, src/assets/scss/vendors/themify-icons.css */
.ti-alert:before {
  content: "\e6c5";
}

/* line 620, src/assets/scss/vendors/themify-icons.css */
.ti-alarm-clock:before {
  content: "\e6c6";
}

/* line 623, src/assets/scss/vendors/themify-icons.css */
.ti-agenda:before {
  content: "\e6c7";
}

/* line 626, src/assets/scss/vendors/themify-icons.css */
.ti-write:before {
  content: "\e6c8";
}

/* line 629, src/assets/scss/vendors/themify-icons.css */
.ti-window:before {
  content: "\e6c9";
}

/* line 632, src/assets/scss/vendors/themify-icons.css */
.ti-widgetized:before {
  content: "\e6ca";
}

/* line 635, src/assets/scss/vendors/themify-icons.css */
.ti-widget:before {
  content: "\e6cb";
}

/* line 638, src/assets/scss/vendors/themify-icons.css */
.ti-widget-alt:before {
  content: "\e6cc";
}

/* line 641, src/assets/scss/vendors/themify-icons.css */
.ti-wallet:before {
  content: "\e6cd";
}

/* line 644, src/assets/scss/vendors/themify-icons.css */
.ti-video-clapper:before {
  content: "\e6ce";
}

/* line 647, src/assets/scss/vendors/themify-icons.css */
.ti-video-camera:before {
  content: "\e6cf";
}

/* line 650, src/assets/scss/vendors/themify-icons.css */
.ti-vector:before {
  content: "\e6d0";
}

/* line 653, src/assets/scss/vendors/themify-icons.css */
.ti-themify-logo:before {
  content: "\e6d1";
}

/* line 656, src/assets/scss/vendors/themify-icons.css */
.ti-themify-favicon:before {
  content: "\e6d2";
}

/* line 659, src/assets/scss/vendors/themify-icons.css */
.ti-themify-favicon-alt:before {
  content: "\e6d3";
}

/* line 662, src/assets/scss/vendors/themify-icons.css */
.ti-support:before {
  content: "\e6d4";
}

/* line 665, src/assets/scss/vendors/themify-icons.css */
.ti-stamp:before {
  content: "\e6d5";
}

/* line 668, src/assets/scss/vendors/themify-icons.css */
.ti-split-v-alt:before {
  content: "\e6d6";
}

/* line 671, src/assets/scss/vendors/themify-icons.css */
.ti-slice:before {
  content: "\e6d7";
}

/* line 674, src/assets/scss/vendors/themify-icons.css */
.ti-shortcode:before {
  content: "\e6d8";
}

/* line 677, src/assets/scss/vendors/themify-icons.css */
.ti-shift-right-alt:before {
  content: "\e6d9";
}

/* line 680, src/assets/scss/vendors/themify-icons.css */
.ti-shift-left-alt:before {
  content: "\e6da";
}

/* line 683, src/assets/scss/vendors/themify-icons.css */
.ti-ruler-alt-2:before {
  content: "\e6db";
}

/* line 686, src/assets/scss/vendors/themify-icons.css */
.ti-receipt:before {
  content: "\e6dc";
}

/* line 689, src/assets/scss/vendors/themify-icons.css */
.ti-pin2:before {
  content: "\e6dd";
}

/* line 692, src/assets/scss/vendors/themify-icons.css */
.ti-pin-alt:before {
  content: "\e6de";
}

/* line 695, src/assets/scss/vendors/themify-icons.css */
.ti-pencil-alt2:before {
  content: "\e6df";
}

/* line 698, src/assets/scss/vendors/themify-icons.css */
.ti-palette:before {
  content: "\e6e0";
}

/* line 701, src/assets/scss/vendors/themify-icons.css */
.ti-more:before {
  content: "\e6e1";
}

/* line 704, src/assets/scss/vendors/themify-icons.css */
.ti-more-alt:before {
  content: "\e6e2";
}

/* line 707, src/assets/scss/vendors/themify-icons.css */
.ti-microphone-alt:before {
  content: "\e6e3";
}

/* line 710, src/assets/scss/vendors/themify-icons.css */
.ti-magnet:before {
  content: "\e6e4";
}

/* line 713, src/assets/scss/vendors/themify-icons.css */
.ti-line-double:before {
  content: "\e6e5";
}

/* line 716, src/assets/scss/vendors/themify-icons.css */
.ti-line-dotted:before {
  content: "\e6e6";
}

/* line 719, src/assets/scss/vendors/themify-icons.css */
.ti-line-dashed:before {
  content: "\e6e7";
}

/* line 722, src/assets/scss/vendors/themify-icons.css */
.ti-layout-width-full:before {
  content: "\e6e8";
}

/* line 725, src/assets/scss/vendors/themify-icons.css */
.ti-layout-width-default:before {
  content: "\e6e9";
}

/* line 728, src/assets/scss/vendors/themify-icons.css */
.ti-layout-width-default-alt:before {
  content: "\e6ea";
}

/* line 731, src/assets/scss/vendors/themify-icons.css */
.ti-layout-tab:before {
  content: "\e6eb";
}

/* line 734, src/assets/scss/vendors/themify-icons.css */
.ti-layout-tab-window:before {
  content: "\e6ec";
}

/* line 737, src/assets/scss/vendors/themify-icons.css */
.ti-layout-tab-v:before {
  content: "\e6ed";
}

/* line 740, src/assets/scss/vendors/themify-icons.css */
.ti-layout-tab-min:before {
  content: "\e6ee";
}

/* line 743, src/assets/scss/vendors/themify-icons.css */
.ti-layout-slider:before {
  content: "\e6ef";
}

/* line 746, src/assets/scss/vendors/themify-icons.css */
.ti-layout-slider-alt:before {
  content: "\e6f0";
}

/* line 749, src/assets/scss/vendors/themify-icons.css */
.ti-layout-sidebar-right:before {
  content: "\e6f1";
}

/* line 752, src/assets/scss/vendors/themify-icons.css */
.ti-layout-sidebar-none:before {
  content: "\e6f2";
}

/* line 755, src/assets/scss/vendors/themify-icons.css */
.ti-layout-sidebar-left:before {
  content: "\e6f3";
}

/* line 758, src/assets/scss/vendors/themify-icons.css */
.ti-layout-placeholder:before {
  content: "\e6f4";
}

/* line 761, src/assets/scss/vendors/themify-icons.css */
.ti-layout-menu:before {
  content: "\e6f5";
}

/* line 764, src/assets/scss/vendors/themify-icons.css */
.ti-layout-menu-v:before {
  content: "\e6f6";
}

/* line 767, src/assets/scss/vendors/themify-icons.css */
.ti-layout-menu-separated:before {
  content: "\e6f7";
}

/* line 770, src/assets/scss/vendors/themify-icons.css */
.ti-layout-menu-full:before {
  content: "\e6f8";
}

/* line 773, src/assets/scss/vendors/themify-icons.css */
.ti-layout-media-right-alt:before {
  content: "\e6f9";
}

/* line 776, src/assets/scss/vendors/themify-icons.css */
.ti-layout-media-right:before {
  content: "\e6fa";
}

/* line 779, src/assets/scss/vendors/themify-icons.css */
.ti-layout-media-overlay:before {
  content: "\e6fb";
}

/* line 782, src/assets/scss/vendors/themify-icons.css */
.ti-layout-media-overlay-alt:before {
  content: "\e6fc";
}

/* line 785, src/assets/scss/vendors/themify-icons.css */
.ti-layout-media-overlay-alt-2:before {
  content: "\e6fd";
}

/* line 788, src/assets/scss/vendors/themify-icons.css */
.ti-layout-media-left-alt:before {
  content: "\e6fe";
}

/* line 791, src/assets/scss/vendors/themify-icons.css */
.ti-layout-media-left:before {
  content: "\e6ff";
}

/* line 794, src/assets/scss/vendors/themify-icons.css */
.ti-layout-media-center-alt:before {
  content: "\e700";
}

/* line 797, src/assets/scss/vendors/themify-icons.css */
.ti-layout-media-center:before {
  content: "\e701";
}

/* line 800, src/assets/scss/vendors/themify-icons.css */
.ti-layout-list-thumb:before {
  content: "\e702";
}

/* line 803, src/assets/scss/vendors/themify-icons.css */
.ti-layout-list-thumb-alt:before {
  content: "\e703";
}

/* line 806, src/assets/scss/vendors/themify-icons.css */
.ti-layout-list-post:before {
  content: "\e704";
}

/* line 809, src/assets/scss/vendors/themify-icons.css */
.ti-layout-list-large-image:before {
  content: "\e705";
}

/* line 812, src/assets/scss/vendors/themify-icons.css */
.ti-layout-line-solid:before {
  content: "\e706";
}

/* line 815, src/assets/scss/vendors/themify-icons.css */
.ti-layout-grid4:before {
  content: "\e707";
}

/* line 818, src/assets/scss/vendors/themify-icons.css */
.ti-layout-grid3:before {
  content: "\e708";
}

/* line 821, src/assets/scss/vendors/themify-icons.css */
.ti-layout-grid2:before {
  content: "\e709";
}

/* line 824, src/assets/scss/vendors/themify-icons.css */
.ti-layout-grid2-thumb:before {
  content: "\e70a";
}

/* line 827, src/assets/scss/vendors/themify-icons.css */
.ti-layout-cta-right:before {
  content: "\e70b";
}

/* line 830, src/assets/scss/vendors/themify-icons.css */
.ti-layout-cta-left:before {
  content: "\e70c";
}

/* line 833, src/assets/scss/vendors/themify-icons.css */
.ti-layout-cta-center:before {
  content: "\e70d";
}

/* line 836, src/assets/scss/vendors/themify-icons.css */
.ti-layout-cta-btn-right:before {
  content: "\e70e";
}

/* line 839, src/assets/scss/vendors/themify-icons.css */
.ti-layout-cta-btn-left:before {
  content: "\e70f";
}

/* line 842, src/assets/scss/vendors/themify-icons.css */
.ti-layout-column4:before {
  content: "\e710";
}

/* line 845, src/assets/scss/vendors/themify-icons.css */
.ti-layout-column3:before {
  content: "\e711";
}

/* line 848, src/assets/scss/vendors/themify-icons.css */
.ti-layout-column2:before {
  content: "\e712";
}

/* line 851, src/assets/scss/vendors/themify-icons.css */
.ti-layout-accordion-separated:before {
  content: "\e713";
}

/* line 854, src/assets/scss/vendors/themify-icons.css */
.ti-layout-accordion-merged:before {
  content: "\e714";
}

/* line 857, src/assets/scss/vendors/themify-icons.css */
.ti-layout-accordion-list:before {
  content: "\e715";
}

/* line 860, src/assets/scss/vendors/themify-icons.css */
.ti-ink-pen:before {
  content: "\e716";
}

/* line 863, src/assets/scss/vendors/themify-icons.css */
.ti-info-alt:before {
  content: "\e717";
}

/* line 866, src/assets/scss/vendors/themify-icons.css */
.ti-help-alt:before {
  content: "\e718";
}

/* line 869, src/assets/scss/vendors/themify-icons.css */
.ti-headphone-alt:before {
  content: "\e719";
}

/* line 872, src/assets/scss/vendors/themify-icons.css */
.ti-hand-point-up:before {
  content: "\e71a";
}

/* line 875, src/assets/scss/vendors/themify-icons.css */
.ti-hand-point-right:before {
  content: "\e71b";
}

/* line 878, src/assets/scss/vendors/themify-icons.css */
.ti-hand-point-left:before {
  content: "\e71c";
}

/* line 881, src/assets/scss/vendors/themify-icons.css */
.ti-hand-point-down:before {
  content: "\e71d";
}

/* line 884, src/assets/scss/vendors/themify-icons.css */
.ti-gallery:before {
  content: "\e71e";
}

/* line 887, src/assets/scss/vendors/themify-icons.css */
.ti-face-smile:before {
  content: "\e71f";
}

/* line 890, src/assets/scss/vendors/themify-icons.css */
.ti-face-sad:before {
  content: "\e720";
}

/* line 893, src/assets/scss/vendors/themify-icons.css */
.ti-credit-card:before {
  content: "\e721";
}

/* line 896, src/assets/scss/vendors/themify-icons.css */
.ti-control-skip-forward:before {
  content: "\e722";
}

/* line 899, src/assets/scss/vendors/themify-icons.css */
.ti-control-skip-backward:before {
  content: "\e723";
}

/* line 902, src/assets/scss/vendors/themify-icons.css */
.ti-control-record:before {
  content: "\e724";
}

/* line 905, src/assets/scss/vendors/themify-icons.css */
.ti-control-eject:before {
  content: "\e725";
}

/* line 908, src/assets/scss/vendors/themify-icons.css */
.ti-comments-smiley:before {
  content: "\e726";
}

/* line 911, src/assets/scss/vendors/themify-icons.css */
.ti-brush-alt:before {
  content: "\e727";
}

/* line 914, src/assets/scss/vendors/themify-icons.css */
.ti-youtube:before {
  content: "\e728";
}

/* line 917, src/assets/scss/vendors/themify-icons.css */
.ti-vimeo:before {
  content: "\e729";
}

/* line 920, src/assets/scss/vendors/themify-icons.css */
.ti-twitter:before {
  content: "\e72a";
}

/* line 923, src/assets/scss/vendors/themify-icons.css */
.ti-time:before {
  content: "\e72b";
}

/* line 926, src/assets/scss/vendors/themify-icons.css */
.ti-tumblr:before {
  content: "\e72c";
}

/* line 929, src/assets/scss/vendors/themify-icons.css */
.ti-skype:before {
  content: "\e72d";
}

/* line 932, src/assets/scss/vendors/themify-icons.css */
.ti-share:before {
  content: "\e72e";
}

/* line 935, src/assets/scss/vendors/themify-icons.css */
.ti-share-alt:before {
  content: "\e72f";
}

/* line 938, src/assets/scss/vendors/themify-icons.css */
.ti-rocket:before {
  content: "\e730";
}

/* line 941, src/assets/scss/vendors/themify-icons.css */
.ti-pinterest:before {
  content: "\e731";
}

/* line 944, src/assets/scss/vendors/themify-icons.css */
.ti-new-window:before {
  content: "\e732";
}

/* line 947, src/assets/scss/vendors/themify-icons.css */
.ti-microsoft:before {
  content: "\e733";
}

/* line 950, src/assets/scss/vendors/themify-icons.css */
.ti-list-ol:before {
  content: "\e734";
}

/* line 953, src/assets/scss/vendors/themify-icons.css */
.ti-linkedin:before {
  content: "\e735";
}

/* line 956, src/assets/scss/vendors/themify-icons.css */
.ti-layout-sidebar-2:before {
  content: "\e736";
}

/* line 959, src/assets/scss/vendors/themify-icons.css */
.ti-layout-grid4-alt:before {
  content: "\e737";
}

/* line 962, src/assets/scss/vendors/themify-icons.css */
.ti-layout-grid3-alt:before {
  content: "\e738";
}

/* line 965, src/assets/scss/vendors/themify-icons.css */
.ti-layout-grid2-alt:before {
  content: "\e739";
}

/* line 968, src/assets/scss/vendors/themify-icons.css */
.ti-layout-column4-alt:before {
  content: "\e73a";
}

/* line 971, src/assets/scss/vendors/themify-icons.css */
.ti-layout-column3-alt:before {
  content: "\e73b";
}

/* line 974, src/assets/scss/vendors/themify-icons.css */
.ti-layout-column2-alt:before {
  content: "\e73c";
}

/* line 977, src/assets/scss/vendors/themify-icons.css */
.ti-instagram:before {
  content: "\e73d";
}

/* line 980, src/assets/scss/vendors/themify-icons.css */
.ti-google:before {
  content: "\e73e";
}

/* line 983, src/assets/scss/vendors/themify-icons.css */
.ti-github:before {
  content: "\e73f";
}

/* line 986, src/assets/scss/vendors/themify-icons.css */
.ti-flickr:before {
  content: "\e740";
}

/* line 989, src/assets/scss/vendors/themify-icons.css */
.ti-facebook:before {
  content: "\e741";
}

/* line 992, src/assets/scss/vendors/themify-icons.css */
.ti-dropbox:before {
  content: "\e742";
}

/* line 995, src/assets/scss/vendors/themify-icons.css */
.ti-dribbble:before {
  content: "\e743";
}

/* line 998, src/assets/scss/vendors/themify-icons.css */
.ti-apple:before {
  content: "\e744";
}

/* line 1001, src/assets/scss/vendors/themify-icons.css */
.ti-android:before {
  content: "\e745";
}

/* line 1004, src/assets/scss/vendors/themify-icons.css */
.ti-save:before {
  content: "\e746";
}

/* line 1007, src/assets/scss/vendors/themify-icons.css */
.ti-save-alt:before {
  content: "\e747";
}

/* line 1010, src/assets/scss/vendors/themify-icons.css */
.ti-yahoo:before {
  content: "\e748";
}

/* line 1013, src/assets/scss/vendors/themify-icons.css */
.ti-wordpress:before {
  content: "\e749";
}

/* line 1016, src/assets/scss/vendors/themify-icons.css */
.ti-vimeo-alt:before {
  content: "\e74a";
}

/* line 1019, src/assets/scss/vendors/themify-icons.css */
.ti-twitter-alt:before {
  content: "\e74b";
}

/* line 1022, src/assets/scss/vendors/themify-icons.css */
.ti-tumblr-alt:before {
  content: "\e74c";
}

/* line 1025, src/assets/scss/vendors/themify-icons.css */
.ti-trello:before {
  content: "\e74d";
}

/* line 1028, src/assets/scss/vendors/themify-icons.css */
.ti-stack-overflow:before {
  content: "\e74e";
}

/* line 1031, src/assets/scss/vendors/themify-icons.css */
.ti-soundcloud:before {
  content: "\e74f";
}

/* line 1034, src/assets/scss/vendors/themify-icons.css */
.ti-sharethis:before {
  content: "\e750";
}

/* line 1037, src/assets/scss/vendors/themify-icons.css */
.ti-sharethis-alt:before {
  content: "\e751";
}

/* line 1040, src/assets/scss/vendors/themify-icons.css */
.ti-reddit:before {
  content: "\e752";
}

/* line 1043, src/assets/scss/vendors/themify-icons.css */
.ti-pinterest-alt:before {
  content: "\e753";
}

/* line 1046, src/assets/scss/vendors/themify-icons.css */
.ti-microsoft-alt:before {
  content: "\e754";
}

/* line 1049, src/assets/scss/vendors/themify-icons.css */
.ti-linux:before {
  content: "\e755";
}

/* line 1052, src/assets/scss/vendors/themify-icons.css */
.ti-jsfiddle:before {
  content: "\e756";
}

/* line 1055, src/assets/scss/vendors/themify-icons.css */
.ti-joomla:before {
  content: "\e757";
}

/* line 1058, src/assets/scss/vendors/themify-icons.css */
.ti-html5:before {
  content: "\e758";
}

/* line 1061, src/assets/scss/vendors/themify-icons.css */
.ti-flickr-alt:before {
  content: "\e759";
}

/* line 1064, src/assets/scss/vendors/themify-icons.css */
.ti-email:before {
  content: "\e75a";
}

/* line 1067, src/assets/scss/vendors/themify-icons.css */
.ti-drupal:before {
  content: "\e75b";
}

/* line 1070, src/assets/scss/vendors/themify-icons.css */
.ti-dropbox-alt:before {
  content: "\e75c";
}

/* line 1073, src/assets/scss/vendors/themify-icons.css */
.ti-css3:before {
  content: "\e75d";
}

/* line 1076, src/assets/scss/vendors/themify-icons.css */
.ti-rss:before {
  content: "\e75e";
}

/* line 1079, src/assets/scss/vendors/themify-icons.css */
.ti-rss-alt:before {
  content: "\e75f";
}

/* Magnific Popup CSS */
/* line 2, src/assets/scss/vendors/magnific-popup.css */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

/* line 13, src/assets/scss/vendors/magnific-popup.css */
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

/* line 23, src/assets/scss/vendors/magnific-popup.css */
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 33, src/assets/scss/vendors/magnific-popup.css */
.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

/* line 39, src/assets/scss/vendors/magnific-popup.css */
.mfp-align-top .mfp-container:before {
  display: none;
}

/* line 42, src/assets/scss/vendors/magnific-popup.css */
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

/* line 50, src/assets/scss/vendors/magnific-popup.css */
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

/* line 55, src/assets/scss/vendors/magnific-popup.css */
.mfp-ajax-cur {
  cursor: progress;
}

/* line 58, src/assets/scss/vendors/magnific-popup.css */
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

/* line 63, src/assets/scss/vendors/magnific-popup.css */
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

/* line 69, src/assets/scss/vendors/magnific-popup.css */
.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

/* line 72, src/assets/scss/vendors/magnific-popup.css */
.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 80, src/assets/scss/vendors/magnific-popup.css */
.mfp-loading.mfp-figure {
  display: none;
}

/* line 83, src/assets/scss/vendors/magnific-popup.css */
.mfp-hide {
  display: none !important;
}

/* line 86, src/assets/scss/vendors/magnific-popup.css */
.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

/* line 96, src/assets/scss/vendors/magnific-popup.css */
.mfp-preloader a {
  color: #ccc;
}

/* line 98, src/assets/scss/vendors/magnific-popup.css */
.mfp-preloader a:hover {
  color: #fff;
}

/* line 101, src/assets/scss/vendors/magnific-popup.css */
.mfp-s-ready .mfp-preloader {
  display: none;
}

/* line 104, src/assets/scss/vendors/magnific-popup.css */
.mfp-s-error .mfp-content {
  display: none;
}

/* line 107, src/assets/scss/vendors/magnific-popup.css */
button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/* line 121, src/assets/scss/vendors/magnific-popup.css */
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/* line 125, src/assets/scss/vendors/magnific-popup.css */
.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

/* line 140, src/assets/scss/vendors/magnific-popup.css */
.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

/* line 143, src/assets/scss/vendors/magnific-popup.css */
.mfp-close:active {
  top: 1px;
}

/* line 146, src/assets/scss/vendors/magnific-popup.css */
.mfp-close-btn-in .mfp-close {
  color: #333;
}

/* line 149, src/assets/scss/vendors/magnific-popup.css */
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

/* line 157, src/assets/scss/vendors/magnific-popup.css */
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

/* line 166, src/assets/scss/vendors/magnific-popup.css */
.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

/* line 176, src/assets/scss/vendors/magnific-popup.css */
.mfp-arrow:active {
  margin-top: -54px;
}

/* line 178, src/assets/scss/vendors/magnific-popup.css */
.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

/* line 181, src/assets/scss/vendors/magnific-popup.css */
.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

/* line 193, src/assets/scss/vendors/magnific-popup.css */
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

/* line 197, src/assets/scss/vendors/magnific-popup.css */
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

/* line 202, src/assets/scss/vendors/magnific-popup.css */
.mfp-arrow-left {
  left: 0;
}

/* line 204, src/assets/scss/vendors/magnific-popup.css */
.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

/* line 207, src/assets/scss/vendors/magnific-popup.css */
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

/* line 211, src/assets/scss/vendors/magnific-popup.css */
.mfp-arrow-right {
  right: 0;
}

/* line 213, src/assets/scss/vendors/magnific-popup.css */
.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

/* line 216, src/assets/scss/vendors/magnific-popup.css */
.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

/* line 219, src/assets/scss/vendors/magnific-popup.css */
.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

/* line 222, src/assets/scss/vendors/magnific-popup.css */
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

/* line 226, src/assets/scss/vendors/magnific-popup.css */
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

/* line 229, src/assets/scss/vendors/magnific-popup.css */
.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

/* line 234, src/assets/scss/vendors/magnific-popup.css */
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
/* line 245, src/assets/scss/vendors/magnific-popup.css */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
/* line 256, src/assets/scss/vendors/magnific-popup.css */
.mfp-figure {
  line-height: 0;
}

/* line 258, src/assets/scss/vendors/magnific-popup.css */
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

/* line 271, src/assets/scss/vendors/magnific-popup.css */
.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

/* line 276, src/assets/scss/vendors/magnific-popup.css */
.mfp-figure figure {
  margin: 0;
}

/* line 279, src/assets/scss/vendors/magnific-popup.css */
.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

/* line 287, src/assets/scss/vendors/magnific-popup.css */
.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

/* line 294, src/assets/scss/vendors/magnific-popup.css */
.mfp-image-holder .mfp-content {
  max-width: 100%;
}

/* line 297, src/assets/scss/vendors/magnific-popup.css */
.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
  screen and (max-height: 300px) {
  /**
         * Remove all paddings around the image on small screen
         */
  /* line 304, src/assets/scss/vendors/magnific-popup.css */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  /* line 307, src/assets/scss/vendors/magnific-popup.css */
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  /* line 309, src/assets/scss/vendors/magnific-popup.css */
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  /* line 312, src/assets/scss/vendors/magnific-popup.css */
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  /* line 315, src/assets/scss/vendors/magnific-popup.css */
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  /* line 323, src/assets/scss/vendors/magnific-popup.css */
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  /* line 325, src/assets/scss/vendors/magnific-popup.css */
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  /* line 328, src/assets/scss/vendors/magnific-popup.css */
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  /* line 340, src/assets/scss/vendors/magnific-popup.css */
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  /* line 343, src/assets/scss/vendors/magnific-popup.css */
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  /* line 346, src/assets/scss/vendors/magnific-popup.css */
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  /* line 349, src/assets/scss/vendors/magnific-popup.css */
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel.owl-hidden {
  opacity: 0;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel.owl-rtl {
  direction: rtl;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-animated-in {
  z-index: 0;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-animated-out {
  z-index: 1;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-height {
  -webkit-transition: height 0.5s ease-in-out;
  transition: height 0.5s ease-in-out;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background: url(owl.video.play.html) no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.1s ease;
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}

/* line 6, src/assets/scss/vendors/owl.carousel.min.css */
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
/* line 9, src/assets/scss/vendors/owl.theme.default.css */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

/* line 13, src/assets/scss/vendors/owl.theme.default.css */
.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

/* line 22, src/assets/scss/vendors/owl.theme.default.css */
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none;
}

/* line 26, src/assets/scss/vendors/owl.theme.default.css */
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

/* line 30, src/assets/scss/vendors/owl.theme.default.css */
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

/* line 33, src/assets/scss/vendors/owl.theme.default.css */
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

/* line 36, src/assets/scss/vendors/owl.theme.default.css */
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  display: inline;
}

/* line 40, src/assets/scss/vendors/owl.theme.default.css */
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

/* line 49, src/assets/scss/vendors/owl.theme.default.css */
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

/* line 8, node_modules/bootstrap/scss/_images.scss */
.navbar-brand .img-fluid {
  max-width: 100%;
  max-height: 50px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/* line 14, node_modules/bootstrap/scss/_images.scss */
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

/* line 29, node_modules/bootstrap/scss/_images.scss */
.figure {
  display: inline-block;
}

/* line 34, node_modules/bootstrap/scss/_images.scss */
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

/* line 39, node_modules/bootstrap/scss/_images.scss */
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

/* line 2, node_modules/bootstrap/scss/_code.scss */
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

/* line 8, node_modules/bootstrap/scss/_code.scss */
a > code {
  color: inherit;
}

/* line 14, node_modules/bootstrap/scss/_code.scss */
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

/* line 22, node_modules/bootstrap/scss/_code.scss */
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

/* line 31, node_modules/bootstrap/scss/_code.scss */
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

/* line 37, node_modules/bootstrap/scss/_code.scss */
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

/* line 45, node_modules/bootstrap/scss/_code.scss */
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

/* line 5, node_modules/bootstrap/scss/_tables.scss */
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

/* line 11, node_modules/bootstrap/scss/_tables.scss */
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

/* line 18, node_modules/bootstrap/scss/_tables.scss */
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

/* line 23, node_modules/bootstrap/scss/_tables.scss */
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

/* line 34, node_modules/bootstrap/scss/_tables.scss */
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

/* line 45, node_modules/bootstrap/scss/_tables.scss */
.table-bordered {
  border: 1px solid #dee2e6;
}

/* line 48, node_modules/bootstrap/scss/_tables.scss */
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

/* line 54, node_modules/bootstrap/scss/_tables.scss */
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

/* line 62, node_modules/bootstrap/scss/_tables.scss */
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

/* line 75, node_modules/bootstrap/scss/_tables.scss */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-info:hover {
  background-color: #abdde5;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-light:hover {
  background-color: #ececf6;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

/* line 114, node_modules/bootstrap/scss/_tables.scss */
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

/* line 122, node_modules/bootstrap/scss/_tables.scss */
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

/* line 130, node_modules/bootstrap/scss/_tables.scss */
.table-dark {
  color: #fff;
  background-color: #343a40;
}

/* line 134, node_modules/bootstrap/scss/_tables.scss */
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

/* line 140, node_modules/bootstrap/scss/_tables.scss */
.table-dark.table-bordered {
  border: 0;
}

/* line 145, node_modules/bootstrap/scss/_tables.scss */
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* line 179, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* line 179, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* line 179, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* line 179, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

/* line 171, node_modules/bootstrap/scss/_tables.scss */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* line 179, node_modules/bootstrap/scss/_tables.scss */
.table-responsive > .table-bordered {
  border: 0;
}

/* line 7, node_modules/bootstrap/scss/_buttons.scss */
.btn {
  display: inline-block;
  font-weight: 400;
  color: #696969;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  /* line 7, node_modules/bootstrap/scss/_buttons.scss */
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn:hover {
  color: #696969;
  text-decoration: none;
}

/* line 27, node_modules/bootstrap/scss/_buttons.scss */
.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 34, node_modules/bootstrap/scss/_buttons.scss */
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

/* line 40, node_modules/bootstrap/scss/_buttons.scss */
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

/* line 55, node_modules/bootstrap/scss/_buttons.scss */
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

/* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

/* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

/* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

/* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

/* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

/* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

/* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

/* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

/* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

/* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

/* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

/* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

/* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

/* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

/* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

/* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

/* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

/* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

/* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

/* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

/* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

/* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

/* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

/* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

/* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

/* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

/* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

/* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-success:focus,
.btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

/* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

/* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

/* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

/* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-info:focus,
.btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

/* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

/* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

/* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

/* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

/* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

/* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

/* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

/* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-light:focus,
.btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

/* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

/* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

/* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

/* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

/* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

/* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

/* line 83, node_modules/bootstrap/scss/_buttons.scss */
.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-link:hover {
  color: #0056b3;
  text-decoration: none;
}

/* line 93, node_modules/bootstrap/scss/_buttons.scss */
.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
}

/* line 98, node_modules/bootstrap/scss/_buttons.scss */
.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

/* line 112, node_modules/bootstrap/scss/_buttons.scss */
.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

/* line 116, node_modules/bootstrap/scss/_buttons.scss */
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

/* line 125, node_modules/bootstrap/scss/_buttons.scss */
.btn-block {
  display: block;
  width: 100%;
}

/* line 130, node_modules/bootstrap/scss/_buttons.scss */
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

/* line 139, node_modules/bootstrap/scss/_buttons.scss */
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

/* line 1, node_modules/bootstrap/scss/_transitions.scss */
.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  /* line 1, node_modules/bootstrap/scss/_transitions.scss */
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 4, node_modules/bootstrap/scss/_transitions.scss */
.fade:not(.show) {
  opacity: 0;
}

/* line 10, node_modules/bootstrap/scss/_transitions.scss */
.collapse:not(.show) {
  display: none;
}

/* line 15, node_modules/bootstrap/scss/_transitions.scss */
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  /* line 15, node_modules/bootstrap/scss/_transitions.scss */
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 2, node_modules/bootstrap/scss/_dropdown.scss */
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

/* line 9, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-toggle {
  white-space: nowrap;
}

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropdown-toggle:empty::after {
  margin-left: 0;
}

/* line 17, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

/* line 42, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu-left {
  right: auto;
  left: 0;
}

/* line 47, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

/* line 57, node_modules/bootstrap/scss/_dropdown.scss */
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

/* line 70, node_modules/bootstrap/scss/_dropdown.scss */
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

/* line 80, node_modules/bootstrap/scss/_dropdown.scss */
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

/* line 87, node_modules/bootstrap/scss/_dropdown.scss */
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

/* line 45, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle::after {
  display: none;
}

/* line 49, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

/* line 97, node_modules/bootstrap/scss/_dropdown.scss */
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

/* line 106, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

/* line 116, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

/* line 123, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

/* line 154, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

/* line 161, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

/* line 173, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu.show {
  display: block;
}

/* line 178, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

/* line 188, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

/* line 4, node_modules/bootstrap/scss/_button-group.scss */
.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

/* line 10, node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

/* line 19, node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

/* line 28, node_modules/bootstrap/scss/_button-group.scss */
.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

/* line 33, node_modules/bootstrap/scss/_button-group.scss */
.btn-toolbar .input-group {
  width: auto;
}

/* line 40, node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

/* line 46, node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* line 51, node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* line 69, node_modules/bootstrap/scss/_button-group.scss */
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

/* line 73, node_modules/bootstrap/scss/_button-group.scss */
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

/* line 79, node_modules/bootstrap/scss/_button-group.scss */
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

/* line 84, node_modules/bootstrap/scss/_button-group.scss */
.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

/* line 89, node_modules/bootstrap/scss/_button-group.scss */
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

/* line 111, node_modules/bootstrap/scss/_button-group.scss */
.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* line 116, node_modules/bootstrap/scss/_button-group.scss */
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

/* line 121, node_modules/bootstrap/scss/_button-group.scss */
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

/* line 127, node_modules/bootstrap/scss/_button-group.scss */
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* line 132, node_modules/bootstrap/scss/_button-group.scss */
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* line 152, node_modules/bootstrap/scss/_button-group.scss */
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

/* line 156, node_modules/bootstrap/scss/_button-group.scss */
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

/* line 7, node_modules/bootstrap/scss/_input-group.scss */
.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

/* line 14, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

/* line 24, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

/* line 32, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

/* line 39, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

/* line 45, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* line 46, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* line 51, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 55, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* line 57, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* line 68, node_modules/bootstrap/scss/_input-group.scss */
.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 75, node_modules/bootstrap/scss/_input-group.scss */
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

/* line 79, node_modules/bootstrap/scss/_input-group.scss */
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

/* line 84, node_modules/bootstrap/scss/_input-group.scss */
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

/* line 92, node_modules/bootstrap/scss/_input-group.scss */
.input-group-prepend {
  margin-right: -1px;
}

/* line 93, node_modules/bootstrap/scss/_input-group.scss */
.input-group-append {
  margin-left: -1px;
}

/* line 101, node_modules/bootstrap/scss/_input-group.scss */
.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #f5f5f5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

/* line 117, node_modules/bootstrap/scss/_input-group.scss */
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

/* line 129, node_modules/bootstrap/scss/_input-group.scss */
.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

/* line 134, node_modules/bootstrap/scss/_input-group.scss */
.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

/* line 146, node_modules/bootstrap/scss/_input-group.scss */
.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

/* line 151, node_modules/bootstrap/scss/_input-group.scss */
.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

/* line 163, node_modules/bootstrap/scss/_input-group.scss */
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

/* line 176, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* line 185, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* line 10, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control {
  position: relative;
  display: block;
  min-height: 1.6415rem;
  padding-left: 1.5rem;
}

/* line 17, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

/* line 22, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.32075rem;
  opacity: 0;
}

/* line 30, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

/* line 37, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* line 46, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

/* line 50, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

/* line 60, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

/* line 63, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

/* line 74, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

/* line 82, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-label::before {
  position: absolute;
  top: 0.32075rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

/* line 97, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-label::after {
  position: absolute;
  top: 0.32075rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

/* line 115, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

/* line 120, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

/* line 126, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

/* line 131, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

/* line 137, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

/* line 140, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

/* line 151, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

/* line 157, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

/* line 163, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

/* line 174, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-switch {
  padding-left: 2.25rem;
}

/* line 178, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

/* line 186, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-switch .custom-control-label::after {
  top: calc(0.32075rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  /* line 186, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-switch .custom-control-label::after {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 199, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

/* line 206, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

/* line 219, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* line 236, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* line 246, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

/* line 257, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

/* line 264, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

/* line 270, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select::-ms-expand {
  display: none;
}

/* line 275, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

/* line 281, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

/* line 289, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

/* line 302, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

/* line 310, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

/* line 318, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* line 324, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

/* line 330, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

/* line 335, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

/* line 340, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

/* line 357, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

/* line 381, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* line 388, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range:focus {
  outline: none;
}

/* line 393, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* line 394, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* line 395, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* line 398, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range::-moz-focus-outer {
  border: 0;
}

/* line 402, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  /* line 402, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 413, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

/* line 418, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

/* line 429, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  /* line 429, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

/* line 439, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

/* line 444, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

/* line 455, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  /* line 455, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

/* line 468, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

/* line 473, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

/* line 484, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

/* line 489, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

/* line 496, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

/* line 500, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

/* line 504, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

/* line 508, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range:disabled::-moz-range-track {
  cursor: default;
}

/* line 512, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

/* line 518, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  /* line 518, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 6, node_modules/bootstrap/scss/_nav.scss */
.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

/* line 14, node_modules/bootstrap/scss/_nav.scss */
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

/* line 24, node_modules/bootstrap/scss/_nav.scss */
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

/* line 35, node_modules/bootstrap/scss/_nav.scss */
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

/* line 38, node_modules/bootstrap/scss/_nav.scss */
.nav-tabs .nav-item {
  margin-bottom: -1px;
}

/* line 42, node_modules/bootstrap/scss/_nav.scss */
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

/* line 50, node_modules/bootstrap/scss/_nav.scss */
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

/* line 57, node_modules/bootstrap/scss/_nav.scss */
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

/* line 64, node_modules/bootstrap/scss/_nav.scss */
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* line 78, node_modules/bootstrap/scss/_nav.scss */
.nav-pills .nav-link {
  border-radius: 0.25rem;
}

/* line 82, node_modules/bootstrap/scss/_nav.scss */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

/* line 95, node_modules/bootstrap/scss/_nav.scss */
.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

/* line 102, node_modules/bootstrap/scss/_nav.scss */
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

/* line 115, node_modules/bootstrap/scss/_nav.scss */
.tab-content > .tab-pane {
  display: none;
}

/* line 118, node_modules/bootstrap/scss/_nav.scss */
.tab-content > .active {
  display: block;
}

/* line 18, node_modules/bootstrap/scss/_navbar.scss */
.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

/* line 28, node_modules/bootstrap/scss/_navbar.scss */
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 52, node_modules/bootstrap/scss/_navbar.scss */
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

/* line 71, node_modules/bootstrap/scss/_navbar.scss */
.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

/* line 78, node_modules/bootstrap/scss/_navbar.scss */
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

/* line 83, node_modules/bootstrap/scss/_navbar.scss */
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

/* line 94, node_modules/bootstrap/scss/_navbar.scss */
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* line 109, node_modules/bootstrap/scss/_navbar.scss */
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 118, node_modules/bootstrap/scss/_navbar.scss */
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

/* line 133, node_modules/bootstrap/scss/_navbar.scss */
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  /* line 150, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  /* line 173, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  /* line 176, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  /* line 180, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  /* line 187, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  /* line 202, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  /* line 209, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  /* line 150, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  /* line 173, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  /* line 176, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  /* line 180, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  /* line 187, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  /* line 202, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  /* line 209, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  /* line 150, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  /* line 173, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  /* line 176, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  /* line 180, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  /* line 187, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  /* line 202, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .dashboard-tabs .navbar-expand-lg .navbar-collapse {
    justify-content: center;
  }

  /* line 209, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  /* line 150, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  /* line 173, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  /* line 176, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  /* line 180, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  /* line 187, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  /* line 202, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  /* line 209, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

/* line 150, node_modules/bootstrap/scss/_navbar.scss */
.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

/* line 152, node_modules/bootstrap/scss/_navbar.scss */
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

/* line 173, node_modules/bootstrap/scss/_navbar.scss */
.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

/* line 176, node_modules/bootstrap/scss/_navbar.scss */
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

/* line 180, node_modules/bootstrap/scss/_navbar.scss */
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

/* line 187, node_modules/bootstrap/scss/_navbar.scss */
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

/* line 202, node_modules/bootstrap/scss/_navbar.scss */
.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

/* line 209, node_modules/bootstrap/scss/_navbar.scss */
.navbar-expand .navbar-toggler {
  display: none;
}

/* line 224, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

/* line 233, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

/* line 240, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

/* line 245, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

/* line 253, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

/* line 258, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* line 262, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

/* line 264, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

/* line 276, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-brand {
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

/* line 285, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

/* line 292, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

/* line 297, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

/* line 305, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

/* line 310, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* line 314, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

/* line 316, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-text a {
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

/* line 5, node_modules/bootstrap/scss/_card.scss */
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.25rem;
}

/* line 17, node_modules/bootstrap/scss/_card.scss */
.card > hr {
  margin-right: 0;
  margin-left: 0;
}

/* line 22, node_modules/bootstrap/scss/_card.scss */
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

/* line 26, node_modules/bootstrap/scss/_card.scss */
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

/* line 31, node_modules/bootstrap/scss/_card.scss */
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

/* line 38, node_modules/bootstrap/scss/_card.scss */
.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

/* line 49, node_modules/bootstrap/scss/_card.scss */
.card-title {
  margin-bottom: 0.75rem;
}

/* line 53, node_modules/bootstrap/scss/_card.scss */
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

/* line 58, node_modules/bootstrap/scss/_card.scss */
.card-text:last-child {
  margin-bottom: 0;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.card-link:hover {
  text-decoration: none;
}

/* line 67, node_modules/bootstrap/scss/_card.scss */
.card-link + .card-link {
  margin-left: 1.25rem;
}

/* line 76, node_modules/bootstrap/scss/_card.scss */
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
}

/* line 83, node_modules/bootstrap/scss/_card.scss */
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

/* line 88, node_modules/bootstrap/scss/_card.scss */
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

/* line 94, node_modules/bootstrap/scss/_card.scss */
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(72, 94, 144, 0.16);
}

/* line 100, node_modules/bootstrap/scss/_card.scss */
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

/* line 110, node_modules/bootstrap/scss/_card.scss */
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

/* line 117, node_modules/bootstrap/scss/_card.scss */
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

/* line 123, node_modules/bootstrap/scss/_card.scss */
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

/* line 132, node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

/* line 139, node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

/* line 144, node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

/* line 153, node_modules/bootstrap/scss/_card.scss */
.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  /* line 152, node_modules/bootstrap/scss/_card.scss */
  .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  /* line 163, node_modules/bootstrap/scss/_card.scss */
  .card-deck .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

/* line 181, node_modules/bootstrap/scss/_card.scss */
.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  /* line 178, node_modules/bootstrap/scss/_card.scss */
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  /* line 190, node_modules/bootstrap/scss/_card.scss */
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  /* line 195, node_modules/bootstrap/scss/_card.scss */
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  /* line 202, node_modules/bootstrap/scss/_card.scss */
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  /* line 205, node_modules/bootstrap/scss/_card.scss */
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  /* line 210, node_modules/bootstrap/scss/_card.scss */
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  /* line 217, node_modules/bootstrap/scss/_card.scss */
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  /* line 220, node_modules/bootstrap/scss/_card.scss */
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  /* line 225, node_modules/bootstrap/scss/_card.scss */
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

/* line 242, node_modules/bootstrap/scss/_card.scss */
.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  /* line 241, node_modules/bootstrap/scss/_card.scss */
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  /* line 252, node_modules/bootstrap/scss/_card.scss */
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

/* line 265, node_modules/bootstrap/scss/_card.scss */
.accordion > .card {
  overflow: hidden;
}

/* line 268, node_modules/bootstrap/scss/_card.scss */
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* line 273, node_modules/bootstrap/scss/_card.scss */
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* line 277, node_modules/bootstrap/scss/_card.scss */
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

/* line 1, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

/* line 12, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* line 16, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

/* line 19, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

/* line 33, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item {
  font-size: 13px;
}

/* line 37, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

/* line 41, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item.active {
  color: #6c757d;
}

/* line 1, node_modules/bootstrap/scss/_pagination.scss */
.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

/* line 7, node_modules/bootstrap/scss/_pagination.scss */
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

/* line 18, node_modules/bootstrap/scss/_pagination.scss */
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

/* line 26, node_modules/bootstrap/scss/_pagination.scss */
.page-link:focus {
  z-index: 3;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* line 35, node_modules/bootstrap/scss/_pagination.scss */
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/* line 41, node_modules/bootstrap/scss/_pagination.scss */
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/* line 46, node_modules/bootstrap/scss/_pagination.scss */
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

/* line 53, node_modules/bootstrap/scss/_pagination.scss */
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

/* line 4, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

/* line 12, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

/* line 17, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

/* line 4, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* line 12, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

/* line 17, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/* line 6, node_modules/bootstrap/scss/_badge.scss */
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  /* line 6, node_modules/bootstrap/scss/_badge.scss */
  .badge {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

/* line 25, node_modules/bootstrap/scss/_badge.scss */
.badge:empty {
  display: none;
}

/* line 31, node_modules/bootstrap/scss/_badge.scss */
.btn .badge {
  position: relative;
  top: -1px;
}

/* line 40, node_modules/bootstrap/scss/_badge.scss */
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-primary {
  color: #fff;
  background-color: #007bff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-success {
  color: #fff;
  background-color: #28a745;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-dark {
  color: #fff;
  background-color: #343a40;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

/* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

/* line 1, node_modules/bootstrap/scss/_jumbotron.scss */
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  /* line 1, node_modules/bootstrap/scss/_jumbotron.scss */
  .jumbotron {
    padding: 4rem 2rem;
  }
}

/* line 13, node_modules/bootstrap/scss/_jumbotron.scss */
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

/* line 5, node_modules/bootstrap/scss/_alert.scss */
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

/* line 14, node_modules/bootstrap/scss/_alert.scss */
.alert-heading {
  color: inherit;
}

/* line 20, node_modules/bootstrap/scss/_alert.scss */
.alert-link {
  font-weight: 700;
}

/* line 29, node_modules/bootstrap/scss/_alert.scss */
.alert-dismissible {
  padding-right: 4rem;
}

/* line 33, node_modules/bootstrap/scss/_alert.scss */
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-primary hr {
  border-top-color: #9fcdff;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-primary .alert-link {
  color: #002752;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-secondary hr {
  border-top-color: #c8cbcf;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-secondary .alert-link {
  color: #202326;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-success hr {
  border-top-color: #b1dfbb;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-success .alert-link {
  color: #0b2e13;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-info hr {
  border-top-color: #abdde5;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-info .alert-link {
  color: #062c33;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-warning hr {
  border-top-color: #ffe8a1;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-warning .alert-link {
  color: #533f03;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-danger hr {
  border-top-color: #f1b0b7;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-danger .alert-link {
  color: #491217;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-light hr {
  border-top-color: #ececf6;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-light .alert-link {
  color: #686868;
}

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

/* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-dark hr {
  border-top-color: #b9bbbe;
}

/* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
.alert-dark .alert-link {
  color: #040505;
}

/* line 1, node_modules/bootstrap/scss/_close.scss */
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #1b2e4b;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.close:hover {
  color: #1b2e4b;
  text-decoration: none;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

/* line 29, node_modules/bootstrap/scss/_close.scss */
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

/* line 38, node_modules/bootstrap/scss/_close.scss */
a.close.disabled {
  pointer-events: none;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

/* line 9, node_modules/bootstrap/scss/_progress.scss */
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

/* line 20, node_modules/bootstrap/scss/_progress.scss */
.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  /* line 20, node_modules/bootstrap/scss/_progress.scss */
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 32, node_modules/bootstrap/scss/_progress.scss */
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

/* line 38, node_modules/bootstrap/scss/_progress.scss */
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  /* line 38, node_modules/bootstrap/scss/_progress.scss */
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

/* line 1, node_modules/bootstrap/scss/_media.scss */
.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

/* line 6, node_modules/bootstrap/scss/_media.scss */
.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

/* line 5, node_modules/bootstrap/scss/_list-group.scss */
.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

/* line 21, node_modules/bootstrap/scss/_list-group.scss */
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

/* line 34, node_modules/bootstrap/scss/_list-group.scss */
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

/* line 45, node_modules/bootstrap/scss/_list-group.scss */
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

/* line 54, node_modules/bootstrap/scss/_list-group.scss */
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

/* line 58, node_modules/bootstrap/scss/_list-group.scss */
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

/* line 62, node_modules/bootstrap/scss/_list-group.scss */
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

/* line 70, node_modules/bootstrap/scss/_list-group.scss */
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

/* line 77, node_modules/bootstrap/scss/_list-group.scss */
.list-group-item + .list-group-item {
  border-top-width: 0;
}

/* line 80, node_modules/bootstrap/scss/_list-group.scss */
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

/* line 96, node_modules/bootstrap/scss/_list-group.scss */
.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

/* line 100, node_modules/bootstrap/scss/_list-group.scss */
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

/* line 105, node_modules/bootstrap/scss/_list-group.scss */
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

/* line 110, node_modules/bootstrap/scss/_list-group.scss */
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

/* line 114, node_modules/bootstrap/scss/_list-group.scss */
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

/* line 118, node_modules/bootstrap/scss/_list-group.scss */
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  /* line 96, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  /* line 100, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  /* line 105, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  /* line 110, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  /* line 114, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  /* line 118, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  /* line 96, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  /* line 100, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  /* line 105, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  /* line 110, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  /* line 114, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  /* line 118, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  /* line 96, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  /* line 100, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  /* line 105, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  /* line 110, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  /* line 114, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  /* line 118, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  /* line 96, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  /* line 100, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  /* line 105, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  /* line 110, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  /* line 114, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  /* line 118, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

/* line 134, node_modules/bootstrap/scss/_list-group.scss */
.list-group-flush {
  border-radius: 0;
}

/* line 137, node_modules/bootstrap/scss/_list-group.scss */
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

/* line 140, node_modules/bootstrap/scss/_list-group.scss */
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

/* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

/* line 1, node_modules/bootstrap/scss/_toasts.scss */
.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

/* line 14, node_modules/bootstrap/scss/_toasts.scss */
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

/* line 18, node_modules/bootstrap/scss/_toasts.scss */
.toast.showing {
  opacity: 1;
}

/* line 22, node_modules/bootstrap/scss/_toasts.scss */
.toast.show {
  display: block;
  opacity: 1;
}

/* line 27, node_modules/bootstrap/scss/_toasts.scss */
.toast.hide {
  display: none;
}

/* line 32, node_modules/bootstrap/scss/_toasts.scss */
.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

/* line 42, node_modules/bootstrap/scss/_toasts.scss */
.toast-body {
  padding: 0.75rem;
}

/* line 7, node_modules/bootstrap/scss/_modal.scss */
.modal-open {
  overflow: hidden;
}

/* line 11, node_modules/bootstrap/scss/_modal.scss */
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

/* line 18, node_modules/bootstrap/scss/_modal.scss */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

/* line 36, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

/* line 44, node_modules/bootstrap/scss/_modal.scss */
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  /* line 44, node_modules/bootstrap/scss/_modal.scss */
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 48, node_modules/bootstrap/scss/_modal.scss */
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

/* line 53, node_modules/bootstrap/scss/_modal.scss */
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

/* line 58, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

/* line 62, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

/* line 67, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

/* line 72, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

/* line 77, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

/* line 83, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

/* line 91, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

/* line 96, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

/* line 100, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

/* line 107, node_modules/bootstrap/scss/_modal.scss */
.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

/* line 125, node_modules/bootstrap/scss/_modal.scss */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

/* line 135, node_modules/bootstrap/scss/_modal.scss */
.modal-backdrop.fade {
  opacity: 0;
}

/* line 136, node_modules/bootstrap/scss/_modal.scss */
.modal-backdrop.show {
  opacity: 0.5;
}

/* line 141, node_modules/bootstrap/scss/_modal.scss */
.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

/* line 149, node_modules/bootstrap/scss/_modal.scss */
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

/* line 157, node_modules/bootstrap/scss/_modal.scss */
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

/* line 164, node_modules/bootstrap/scss/_modal.scss */
.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

/* line 173, node_modules/bootstrap/scss/_modal.scss */
.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

/* line 186, node_modules/bootstrap/scss/_modal.scss */
.modal-footer > * {
  margin: 0.25rem;
}

/* line 192, node_modules/bootstrap/scss/_modal.scss */
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  /* line 203, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  /* line 208, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  /* line 211, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  /* line 216, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  /* line 219, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  /* line 229, node_modules/bootstrap/scss/_modal.scss */
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  /* line 233, node_modules/bootstrap/scss/_modal.scss */
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  /* line 240, node_modules/bootstrap/scss/_modal.scss */
  .modal-xl {
    max-width: 1140px;
  }
}

/* line 2, node_modules/bootstrap/scss/_tooltip.scss */
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

/* line 15, node_modules/bootstrap/scss/_tooltip.scss */
.tooltip.show {
  opacity: 0.9;
}

/* line 17, node_modules/bootstrap/scss/_tooltip.scss */
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

/* line 23, node_modules/bootstrap/scss/_tooltip.scss */
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

/* line 32, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

/* line 35, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

/* line 38, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

/* line 46, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

/* line 49, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

/* line 54, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

/* line 62, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

/* line 65, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

/* line 68, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

/* line 76, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

/* line 79, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

/* line 84, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

/* line 108, node_modules/bootstrap/scss/_tooltip.scss */
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

/* line 1, node_modules/bootstrap/scss/_popover.scss */
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

/* line 20, node_modules/bootstrap/scss/_popover.scss */
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

/* line 27, node_modules/bootstrap/scss/_popover.scss */
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

/* line 38, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

/* line 41, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

/* line 44, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

/* line 50, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

/* line 58, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

/* line 61, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

/* line 67, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

/* line 73, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

/* line 81, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

/* line 84, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

/* line 87, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

/* line 93, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

/* line 101, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

/* line 113, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

/* line 116, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

/* line 122, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

/* line 128, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

/* line 153, node_modules/bootstrap/scss/_popover.scss */
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.938rem;
  color: #1b2e4b;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

/* line 162, node_modules/bootstrap/scss/_popover.scss */
.popover-header:empty {
  display: none;
}

/* line 167, node_modules/bootstrap/scss/_popover.scss */
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

/* line 14, node_modules/bootstrap/scss/_carousel.scss */
.carousel {
  position: relative;
}

/* line 18, node_modules/bootstrap/scss/_carousel.scss */
.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

/* line 22, node_modules/bootstrap/scss/_carousel.scss */
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* line 2, node_modules/bootstrap/scss/mixins/_clearfix.scss */
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

/* line 29, node_modules/bootstrap/scss/_carousel.scss */
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  /* line 29, node_modules/bootstrap/scss/_carousel.scss */
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 39, node_modules/bootstrap/scss/_carousel.scss */
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* line 45, node_modules/bootstrap/scss/_carousel.scss */
.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

/* line 50, node_modules/bootstrap/scss/_carousel.scss */
.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* line 61, node_modules/bootstrap/scss/_carousel.scss */
.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

/* line 67, node_modules/bootstrap/scss/_carousel.scss */
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

/* line 74, node_modules/bootstrap/scss/_carousel.scss */
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  /* line 74, node_modules/bootstrap/scss/_carousel.scss */
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 87, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  /* line 87, node_modules/bootstrap/scss/_carousel.scss */
  .carousel-control-prev,
  .carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

/* line 111, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev {
  left: 0;
}

/* line 117, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-next {
  right: 0;
}

/* line 125, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

/* line 132, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

/* line 135, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

/* line 145, node_modules/bootstrap/scss/_carousel.scss */
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

/* line 159, node_modules/bootstrap/scss/_carousel.scss */
.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  /* line 159, node_modules/bootstrap/scss/_carousel.scss */
  .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}

/* line 177, node_modules/bootstrap/scss/_carousel.scss */
.carousel-indicators .active {
  opacity: 1;
}

/* line 187, node_modules/bootstrap/scss/_carousel.scss */
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* line 9, node_modules/bootstrap/scss/_spinners.scss */
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

/* line 21, node_modules/bootstrap/scss/_spinners.scss */
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* line 41, node_modules/bootstrap/scss/_spinners.scss */
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

/* line 53, node_modules/bootstrap/scss/_spinners.scss */
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media print {
  /* line 13, node_modules/bootstrap/scss/_print.scss */
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  /* line 24, node_modules/bootstrap/scss/_print.scss */
  a:not(.btn) {
    text-decoration: underline;
  }

  /* line 34, node_modules/bootstrap/scss/_print.scss */
  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  /* line 49, node_modules/bootstrap/scss/_print.scss */
  pre {
    white-space: pre-wrap !important;
  }

  /* line 52, node_modules/bootstrap/scss/_print.scss */
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  /* line 63, node_modules/bootstrap/scss/_print.scss */
  thead {
    display: table-header-group;
  }

  /* line 67, node_modules/bootstrap/scss/_print.scss */
  tr,
  img {
    page-break-inside: avoid;
  }

  /* line 72, node_modules/bootstrap/scss/_print.scss */
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  /* line 79, node_modules/bootstrap/scss/_print.scss */
  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  /* line 92, node_modules/bootstrap/scss/_print.scss */
  body {
    min-width: 992px !important;
  }

  /* line 95, node_modules/bootstrap/scss/_print.scss */
  .container {
    min-width: 992px !important;
  }

  /* line 100, node_modules/bootstrap/scss/_print.scss */
  .navbar {
    display: none;
  }

  /* line 103, node_modules/bootstrap/scss/_print.scss */
  .badge {
    border: 1px solid #000;
  }

  /* line 107, node_modules/bootstrap/scss/_print.scss */
  .table {
    border-collapse: collapse !important;
  }

  /* line 110, node_modules/bootstrap/scss/_print.scss */
  .table td,
  .table th {
    background-color: #fff !important;
  }

  /* line 117, node_modules/bootstrap/scss/_print.scss */
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  /* line 123, node_modules/bootstrap/scss/_print.scss */
  .table-dark {
    color: inherit;
  }

  /* line 126, node_modules/bootstrap/scss/_print.scss */
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  /* line 134, node_modules/bootstrap/scss/_print.scss */
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/* line 2, src/assets/scss/components/_components.preloader.scss */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
}

/* line 10, src/assets/scss/components/_components.preloader.scss */
#preloader .preloader-wrap {
  display: block;
  position: relative;
  text-align: center;
  top: 40%;
}

/* line 15, src/assets/scss/components/_components.preloader.scss */
#preloader .preloader-wrap .thecube {
  width: 50px;
  height: 50px;
  margin: 34px auto;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

/* line 22, src/assets/scss/components/_components.preloader.scss */
#preloader .preloader-wrap .thecube .cube {
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

/* line 28, src/assets/scss/components/_components.preloader.scss */
#preloader .preloader-wrap .thecube .cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  background: #0c2888;
}

/* line 39, src/assets/scss/components/_components.preloader.scss */
#preloader .preloader-wrap .thecube .cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0c2888;
  -webkit-animation: foldthecube 2.4s infinite linear both;
  animation: foldthecube 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

/* line 54, src/assets/scss/components/_components.preloader.scss */
#preloader .preloader-wrap .thecube .c2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
  background: #0c2888;
}

/* line 60, src/assets/scss/components/_components.preloader.scss */
#preloader .preloader-wrap .thecube .c3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
  background: #0c2888;
}

/* line 66, src/assets/scss/components/_components.preloader.scss */
#preloader .preloader-wrap .thecube .c4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
  background: #5382dc;
}

/* line 72, src/assets/scss/components/_components.preloader.scss */
#preloader .preloader-wrap .thecube .c2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

/* line 77, src/assets/scss/components/_components.preloader.scss */
#preloader .preloader-wrap .thecube .c3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

/* line 82, src/assets/scss/components/_components.preloader.scss */
#preloader .preloader-wrap .thecube .c4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes foldthecube {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes foldthecube {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

/* line 1, src/assets/scss/components/_components.scroll-up.scss */
.scroll-top {
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: fixed;
  top: 100%;
  right: 50px;
  font-size: 16px;
  border-radius: 4px;
  z-index: 99;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  border: none;
}

/* line 15, src/assets/scss/components/_components.scroll-up.scss */
.scroll-top:after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}

/* line 26, src/assets/scss/components/_components.scroll-up.scss */
.scroll-top.open {
  top: 90%;
}

/* line 6, src/assets/scss/components/_components.header.scss */
.header nav.navbar {
  padding: 0.3rem 1rem;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

/* line 9, src/assets/scss/components/_components.header.scss */
.header nav.navbar.affix {
  padding: 0 1rem;
  -webkit-box-shadow: 0 0.1rem 0.08rem rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.1rem 0.08rem rgba(0, 0, 0, 0.08);
}

/* line 18, src/assets/scss/components/_components.header.scss */
.header .navbar-brand {
  font-size: inherit;
  height: auto;
}

/* line 24, src/assets/scss/components/_components.header.scss */
.header .navbar-toggler:focus {
  outline: none;
}

/* line 30, src/assets/scss/components/_components.header.scss */
.header .menu li {
  display: inline-block;
  position: relative;
}

/* line 33, src/assets/scss/components/_components.header.scss */
.header .menu li:first-child {
  margin-left: 0;
}

/* line 36, src/assets/scss/components/_components.header.scss */
.header .menu li a {
  font-size: 14px;
  text-decoration: none;
  padding: 22px 12px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  color: #fff;
  opacity: 0.8;
}

/* line 45, src/assets/scss/components/_components.header.scss */
.header .menu li a.dropdown-toggle:after {
  position: relative;
  left: 2px;
  vertical-align: middle;
}

/* line 51, src/assets/scss/components/_components.header.scss */
.header .menu li a:hover {
  opacity: 1;
}

/* line 56, src/assets/scss/components/_components.header.scss */
.header .menu li:hover > ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

/* line 61, src/assets/scss/components/_components.header.scss */
.header .menu li:hover > a {
  opacity: 1;
}

/* line 67, src/assets/scss/components/_components.header.scss */
.header .menu ul {
  visibility: hidden;
  opacity: 0;
  margin: 0;
  padding: 0;
  width: 200px;
  position: absolute;
  left: 0;
  background: #f5f5f5;
  z-index: 9;
  border-radius: 4px;
  -webkit-transform: translate(0, 20px);
  transform: translate(0, 20px);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* line 82, src/assets/scss/components/_components.header.scss */
.header .menu ul:after {
  bottom: 100%;
  left: 35px;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border: solid transparent;
  border-bottom-color: #f5f5f5;
  border-width: 8px;
  margin-left: -8px;
}

/* line 96, src/assets/scss/components/_components.header.scss */
.header .menu ul li {
  display: block;
  float: none;
  background: none;
  margin: 0;
  padding: 0;
}

/* line 102, src/assets/scss/components/_components.header.scss */
.header .menu ul li a {
  text-transform: inherit;
  font-weight: normal;
  display: block;
  color: #696969;
  padding: 8px 15px;
}

/* line 109, src/assets/scss/components/_components.header.scss */
.header .menu ul li a.dropdown-toggle-inner:after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  margin-left: auto;
  position: absolute;
  right: 15px;
  font-size: 12px;
}

/* line 120, src/assets/scss/components/_components.header.scss */
.header .menu ul li a:hover {
  color: #fff !important;
  background: #0c2888;
}

/* line 125, src/assets/scss/components/_components.header.scss */
.header .menu ul li:hover > a {
  color: #fff !important;
  background: #0c2888;
}

/* line 130, src/assets/scss/components/_components.header.scss */
.header .menu ul ul {
  left: 200px;
  top: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(20px, 20px);
  transform: translate(20px, 20px);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

/* line 137, src/assets/scss/components/_components.header.scss */
.header .menu ul ul:after {
  left: -8px;
  top: 10px;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border: solid transparent;
  border-right-color: #f5f5f5;
  border-width: 8px;
}

/* line 157, src/assets/scss/components/_components.header.scss */
.header .bg-transparent .navbar-toggler {
  color: #fff;
}

/* line 163, src/assets/scss/components/_components.header.scss */
.header .custom-nav.affix,
.header .custom-nav-3.affix {
  background: #fff;
}

/* line 170, src/assets/scss/components/_components.header.scss */
.menu li > ul ul:hover {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

/* line 176, src/assets/scss/components/_components.header.scss */
.custom-nav.affix,
.custom-nav-3.affix {
  background: #fff !important;
}

/* line 181, src/assets/scss/components/_components.header.scss */
.custom-nav ul li a,
.custom-nav-2.affix ul li a {
  color: #fff;
}

/* line 184, src/assets/scss/components/_components.header.scss */
.custom-nav.affix ul li a {
  color: #696969;
}

/* line 188, src/assets/scss/components/_components.header.scss */
.menu ul.sub-menu li:first-child a:hover,
.menu ul.sub-menu li:last-child ul li:first-child a:hover {
  border-radius: 4px 4px 0 0;
}

/* line 192, src/assets/scss/components/_components.header.scss */
.menu ul.sub-menu li:last-child a:hover,
.menu ul.sub-menu li:last-child ul li:last-child a:hover {
  border-radius: 0 0 4px 4px;
}

/* line 196, src/assets/scss/components/_components.header.scss */
.menu ul.sub-menu li:last-child ul li a:hover {
  border-radius: 0;
}

/* line 199, src/assets/scss/components/_components.header.scss */
.affix {
  background: linear-gradient(75deg, #5382dc, #0c2888) !important;
  -webkit-transition: padding 0.4s ease-out;
  transition: padding 0.4s ease-out;
}

/* line 207, src/assets/scss/components/_components.header.scss */
.fixed-top {
  top: auto;
}

/* line 210, src/assets/scss/components/_components.header.scss */
.fixed-top.affix {
  top: 0;
}

@media (min-width: 320px) and (max-width: 992px) {
  /* line 216, src/assets/scss/components/_components.header.scss */
  .navbar.custom-nav-3,
  .navbar.custom-nav {
    background: #fff !important;
  }

  /* line 219, src/assets/scss/components/_components.header.scss */
  .navbar {
    background: linear-gradient(75deg, #0c2888, #5382dc) !important;
    padding: 0.45rem 1rem !important;
  }

  /* line 223, src/assets/scss/components/_components.header.scss */
  .menu li {
    display: block;
  }

  /* line 227, src/assets/scss/components/_components.header.scss */
  .custom-nav.affix {
    background: #fff !important;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  }

  /* line 232, src/assets/scss/components/_components.header.scss */
  .custom-nav ul li a {
    color: #696969 !important;
  }

  /* line 236, src/assets/scss/components/_components.header.scss */
  .white-bg.navbar .navbar-toggler span {
    color: #0c2888;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  /* line 246, src/assets/scss/components/_components.header.scss */
  .menu ul {
    top: 40px;
  }

  /* line 250, src/assets/scss/components/_components.header.scss */
  .menu li a {
    padding: 8px 15px !important;
  }

  /* line 254, src/assets/scss/components/_components.header.scss */
  a.homer {
    background: #374147;
  }
}

@media (max-width: 767px) {
  /* line 260, src/assets/scss/components/_components.header.scss */
  .header nav.navbar {
    padding: 0.5rem 1rem;
  }

  /* line 263, src/assets/scss/components/_components.header.scss */
  nav {
    margin: 0;
    background: none;
  }

  /* line 268, src/assets/scss/components/_components.header.scss */
  .menu li {
    display: block;
    margin: 0;
  }

  /* line 273, src/assets/scss/components/_components.header.scss */
  .menu li a {
    padding: 8px 15px !important;
  }

  /* line 277, src/assets/scss/components/_components.header.scss */
  .menu li a:hover,
  .menu li:hover > a {
    background: #0c2888;
    color: #fff;
  }

  /* line 282, src/assets/scss/components/_components.header.scss */
  .menu ul {
    visibility: hidden;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transform: initial;
    transform: initial;
  }

  /* line 291, src/assets/scss/components/_components.header.scss */
  .menu li:hover > ul {
    visibility: visible;
    opacity: 1;
    position: relative;
    -webkit-transform: initial;
    transform: initial;
  }

  /* line 298, src/assets/scss/components/_components.header.scss */
  .menu ul ul {
    left: 0;
    -webkit-transform: initial;
    transform: initial;
  }

  /* line 303, src/assets/scss/components/_components.header.scss */
  .menu li > ul ul:hover {
    -webkit-transform: initial;
    transform: initial;
  }
}

@media (max-width: 767px) {
  /* line 310, src/assets/scss/components/_components.header.scss */
  .header .menu ul ul {
    left: 0;
    -webkit-transform: initial;
    transform: initial;
  }

  /* line 314, src/assets/scss/components/_components.header.scss */
  .header .menu ul ul:after {
    display: none;
  }
}

/* line 323, src/assets/scss/components/_components.header.scss */
.header.white-bg .menu li a {
  color: #696969;
}

/* line 329, src/assets/scss/components/_components.header.scss */
.white-bg.affix {
  background: #fff !important;
}

@media (max-width: 992px) and (min-width: 320px) {
  /* line 333, src/assets/scss/components/_components.header.scss */
  .white-bg .navbar {
    background: #fff !important;
  }

  /* line 335, src/assets/scss/components/_components.header.scss */
  .white-bg .navbar .menu li a:hover,
  .white-bg .navbar .menu li:hover > a {
    background: #e5e9f2;
  }
}

/* line 3, src/assets/scss/components/_components.footer.scss */
footer p {
  font-size: 14px;
}

/* line 8, src/assets/scss/components/_components.footer.scss */
footer ul li:not(:hover) {
  opacity: 0.8;
}

/* line 14, src/assets/scss/components/_components.footer.scss */
footer ul:not(.list-inline):not(.slides) > li {
  line-height: 30px !important;
}

/* line 17, src/assets/scss/components/_components.footer.scss */
footer ul:not([class*="menu"]) li > a:hover {
  text-decoration: none;
}

/* line 21, src/assets/scss/components/_components.footer.scss */
footer ul:not([class*="menu"]) li > a {
  font-weight: 400;
  font-size: 13px;
  display: block;
}

/* line 25, src/assets/scss/components/_components.footer.scss */
footer ul:not([class*="menu"]) li > a:hover {
  opacity: 1;
  color: inherit;
}

/* line 31, src/assets/scss/components/_components.footer.scss */
footer a {
  font-weight: normal;
  color: #696969;
}

/* line 40, src/assets/scss/components/_components.footer.scss */
.footer-1.gradient-bg p,
.footer-1.gradient-bg h6 {
  color: #fff;
  opacity: 0.8;
}

/* line 46, src/assets/scss/components/_components.footer.scss */
.footer-1.gradient-bg ul li a {
  color: #fff;
  opacity: 0.8;
}

/* line 49, src/assets/scss/components/_components.footer.scss */
.footer-1.gradient-bg ul li a:hover {
  color: #fff;
  opacity: 1;
}

/* line 57, src/assets/scss/components/_components.footer.scss */
.border-gray-light {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* line 62, src/assets/scss/components/_components.footer.scss */
.small-text {
  font-size: 13px;
}

/* line 64, src/assets/scss/components/_components.footer.scss */
.small-text:not(p) {
  opacity: 1;
}

/* line 70, src/assets/scss/components/_components.footer.scss */
.footer-with-newsletter {
  margin-top: 80px;
}

/* line 72, src/assets/scss/components/_components.footer.scss */
.footer-with-newsletter .newsletter-wrap {
  margin-top: -136px;
  margin-bottom: 60px;
}

/* line 78, src/assets/scss/components/_components.footer.scss */
.newsletter-form .input-newsletter {
  height: 50px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 30px;
  font-size: 14px;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 25px;
}

/* line 90, src/assets/scss/components/_components.footer.scss */
.newsletter-form button {
  position: absolute;
  right: 4px;
  top: 4px;
  bottom: 4px;
  height: auto;
  border: none;
  border-radius: 30px;
  background-color: #0c2888;
  display: inline-block;
  color: #fff;
  padding-left: 30px;
  padding-right: 30px;
}

/* line 103, src/assets/scss/components/_components.footer.scss */
.newsletter-form button:focus {
  outline: none;
}

/* line 112, src/assets/scss/components/_components.footer.scss */
.terms-policy-wrap ul li a {
  color: #696969;
}

/* line 114, src/assets/scss/components/_components.footer.scss */
.terms-policy-wrap ul li a:hover {
  color: initial;
}

/* line 1, src/assets/scss/components/_components.buttons.scss */
.btn {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 0.838rem;
  font-weight: 500;
  padding: 8px 35px;
  line-height: 1.75em;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

/* line 9, src/assets/scss/components/_components.buttons.scss */
.btn:focus,
.btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 14, src/assets/scss/components/_components.buttons.scss */
.btn svg {
  width: 14px;
  height: 14px;
  stroke-width: 2.5px;
  margin-top: -2px;
}

/* line 22, src/assets/scss/components/_components.buttons.scss */
.btn.active-primary,
.btn.active-primary:hover,
.btn.active-primary:focus {
  background-color: #fff;
  border-color: #007bff;
  color: #007bff;
}

/* line 29, src/assets/scss/components/_components.buttons.scss */
.btn-rounded {
  border-radius: 10rem;
}

/* line 32, src/assets/scss/components/_components.buttons.scss */
.btn-circle {
  width: 45px;
  height: 45px;
  padding: 0;
  border-radius: 10rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* line 40, src/assets/scss/components/_components.buttons.scss */
.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
  width: 40px;
  height: 40px;
  font-size: 13px;
}

/* line 46, src/assets/scss/components/_components.buttons.scss */
.btn-circle.btn-xs {
  width: 35px;
  height: 35px;
  font-size: 13px;
}

/* line 53, src/assets/scss/components/_components.buttons.scss */
.btn-animated:hover {
  transform: translate(0, -3px);
  -webkit-transform: translate(0, -3px);
}

/* line 57, src/assets/scss/components/_components.buttons.scss */
.btn-xs {
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* line 62, src/assets/scss/components/_components.buttons.scss */
.btn-sm,
.btn-group-sm > .btn {
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
}

/* line 67, src/assets/scss/components/_components.buttons.scss */
.btn-lg,
.btn-group-lg > .btn {
  font-size: 0.938rem;
  padding-top: 11px;
  padding-bottom: 11px;
}

/* line 73, src/assets/scss/components/_components.buttons.scss */
.btn-light {
  background-color: #e5e9f2;
  border-color: #cdd5e6;
  color: #8392a5;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-light:hover,
.btn-light:focus {
  background-color: #e5e9f2;
  border-color: #cdd5e6;
}

/* line 84, src/assets/scss/components/_components.buttons.scss */
.btn-outline-light {
  border-color: #ced4da;
  color: #6c757d;
}

/* line 88, src/assets/scss/components/_components.buttons.scss */
.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active {
  background-color: #ced4da;
  border-color: #ced4da;
}

/* line 96, src/assets/scss/components/_components.buttons.scss */
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  background-color: #dee2e6;
  border-color: #ced4da;
}

/* line 104, src/assets/scss/components/_components.buttons.scss */
.btn-white {
  background-color: #fff;
  border-color: #c0ccda;
  color: rgba(27, 46, 75, 0.7);
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-white:hover,
.btn-white:focus {
  border-color: #8392a5;
  color: #1b2e4b;
}

/* line 114, src/assets/scss/components/_components.buttons.scss */
.btn-white:active,
.btn-white.active {
  background-color: #f5f5f5;
}

/* line 121, src/assets/scss/components/_components.buttons.scss */
.btn-icon {
  padding-left: 15px;
  padding-right: 15px;
}

/* line 125, src/assets/scss/components/_components.buttons.scss */
.btn-icon.btn-sm,
.btn-group-sm > .btn-icon.btn {
  padding-left: 12px;
  padding-right: 12px;
}

/* line 130, src/assets/scss/components/_components.buttons.scss */
.btn-icon.btn-xs {
  padding-left: 10px;
  padding-right: 10px;
}

/* line 135, src/assets/scss/components/_components.buttons.scss */
.btn-icon svg {
  margin: 0;
}

/* line 141, src/assets/scss/components/_components.buttons.scss */
.btn-brand-01 {
  background-color: #0c2888;
  border-color: #0c2888;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-brand-01:hover,
.btn-brand-01:focus {
  background-color: #0c2888;
  border-color: #0c2888;
  color: #fff;
}

/* line 151, src/assets/scss/components/_components.buttons.scss */
.btn-brand-01.btn-transparent {
  background-color: #0c2888;
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-brand-01.btn-transparent:hover,
.btn-brand-01.btn-transparent:focus {
  background-color: #0c2888;
}

/* line 162, src/assets/scss/components/_components.buttons.scss */
.btn-brand-02 {
  background-color: #0c2888;
  border-color: #0c2888;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-brand-02:hover,
.btn-brand-02:focus {
  background-color: #0c2888;
  border-color: #0c2888;
  color: #fff;
}

/* line 172, src/assets/scss/components/_components.buttons.scss */
.btn-brand-02.btn-transparent {
  background-color: #0c2888;
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-brand-02.btn-transparent:hover,
.btn-brand-02.btn-transparent:focus {
  background-color: #0c2888;
}

/* line 182, src/assets/scss/components/_components.buttons.scss */
.btn-brand-03 {
  background-color: #0c2888;
  border-color: #0c2888;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-brand-03:hover,
.btn-brand-03:focus {
  background-color: #0c2888;
  border-color: #0c2888;
  color: #fff;
}

/* line 192, src/assets/scss/components/_components.buttons.scss */
.btn-brand-03.btn-transparent {
  background-color: #0c2888;
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-brand-03.btn-transparent:hover,
.btn-brand-03.btn-transparent:focus {
  background-color: #5382dc;
}

/* line 204, src/assets/scss/components/_components.buttons.scss */
.btn-outline-brand-01 {
  border-color: #0c2888;
  color: #0c2888;
}

/* line 208, src/assets/scss/components/_components.buttons.scss */
.btn-outline-brand-01:hover,
.btn-outline-brand-01:focus,
.btn-outline-brand-01:active {
  background-color: #0c2888;
  border-color: #0c2888;
  color: #fff;
}

/* line 216, src/assets/scss/components/_components.buttons.scss */
.btn-outline-brand-02 {
  border-color: #0c2888;
  color: #0c2888;
}

/* line 220, src/assets/scss/components/_components.buttons.scss */
.btn-outline-brand-02:hover,
.btn-outline-brand-02:focus,
.btn-outline-brand-02:active {
  background-color: #0c2888;
  border-color: #0c2888;
  color: #fff;
}

/* line 229, src/assets/scss/components/_components.buttons.scss */
.btn-outline-brand-03 {
  border-color: #0c2888;
  color: #0c2888;
}

/* line 233, src/assets/scss/components/_components.buttons.scss */
.btn-outline-brand-03:hover,
.btn-outline-brand-03:focus,
.btn-outline-brand-03:active {
  background-color: #0c2888;
  border-color: #0c2888;
  color: #fff;
}

/* line 243, src/assets/scss/components/_components.buttons.scss */
.btn-uppercase {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.75;
}

/* line 250, src/assets/scss/components/_components.buttons.scss */
.btn-uppercase.btn-sm,
.btn-group-sm > .btn-uppercase.btn {
  font-size: 11px;
  line-height: 1.773;
}

/* line 255, src/assets/scss/components/_components.buttons.scss */
.btn-uppercase.btn-xs {
  font-size: 10px;
  line-height: 1.8;
}

/* line 262, src/assets/scss/components/_components.buttons.scss */
.btn-brand-3d-01,
.btn-brand-3d-02,
.btn-brand-3d-03 {
  color: #fff;
  border: inherit;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

/* line 269, src/assets/scss/components/_components.buttons.scss */
.btn-brand-3d-01 {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#5382dc),
    to(#0c2888)
  );
  background-image: linear-gradient(0deg, #5382dc 0%, #0c2888 100%);
}

/* line 272, src/assets/scss/components/_components.buttons.scss */
.btn-brand-3d-02 {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#0c2888),
    to(#5382dc)
  );
  background-image: linear-gradient(0deg, #0c2888 0%, #5382dc 100%);
}

/* line 275, src/assets/scss/components/_components.buttons.scss */
.btn-brand-3d-03 {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#0c2888),
    to(#5382dc)
  );
  background-image: linear-gradient(0deg, #0c2888 0%, #5382dc 100%);
}

/* line 278, src/assets/scss/components/_components.buttons.scss */
.btn-brand-3d-01:before,
.btn-brand-3d-02:before,
.btn-brand-3d-03:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* line 291, src/assets/scss/components/_components.buttons.scss */
.btn-brand-3d-01:before {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#0c2888),
    to(#3d1598)
  );
  background-image: linear-gradient(0deg, #0c2888 0%, #5382dc 100%);
}

/* line 294, src/assets/scss/components/_components.buttons.scss */
.btn-brand-3d-02:before {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#5382dc),
    to(#0c2888)
  );
  background-image: linear-gradient(0deg, #5382dc 0%, #0c2888 100%);
}

/* line 297, src/assets/scss/components/_components.buttons.scss */
.btn-brand-3d-03:before {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#5382dc),
    to(#0c2888)
  );
  background-image: linear-gradient(0deg, #5382dc 0%, #0c2888 100%);
}

/* line 300, src/assets/scss/components/_components.buttons.scss */
.btn-brand-3d-01:hover,
.btn-brand-3d-02:hover,
.btn-brand-3d-03:hover {
  color: #fff;
}

/* line 303, src/assets/scss/components/_components.buttons.scss */
.btn-brand-3d-01:hover:before,
.btn-brand-3d-02:hover:before,
.btn-brand-3d-03:hover:before {
  opacity: 1;
}

/* line 308, src/assets/scss/components/_components.buttons.scss */
.app-download-btn {
  line-height: inherit;
}

/* line 310, src/assets/scss/components/_components.buttons.scss */
.app-download-btn .download-text {
  line-height: initial;
}

/* line 312, src/assets/scss/components/_components.buttons.scss */
.app-download-btn .download-text h5 {
  font-size: 14px;
  color: inherit;
}

@media (min-width: 320px) and (max-width: 575px) {
  /* line 321, src/assets/scss/components/_components.buttons.scss */
  .action-btns a.btn {
    margin-bottom: 15px;
  }
}

/* line 328, src/assets/scss/components/_components.buttons.scss */
.btn-facebook {
  background-color: #4064ac;
  border-color: #4064ac;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-facebook:hover,
.btn-facebook:focus {
  background-color: #324e87;
  border-color: #324e87;
  color: #fff;
}

/* line 338, src/assets/scss/components/_components.buttons.scss */
.btn-facebook.btn-transparent {
  background-color: rgba(64, 100, 172, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-facebook.btn-transparent:hover,
.btn-facebook.btn-transparent:focus {
  background-color: rgba(64, 100, 172, 0.9);
}

/* line 347, src/assets/scss/components/_components.buttons.scss */
.btn-twitter {
  background-color: #00a7e6;
  border-color: #00a7e6;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-twitter:hover,
.btn-twitter:focus {
  background-color: #1ac0ff;
  border-color: #1ac0ff;
  color: #fff;
}

/* line 357, src/assets/scss/components/_components.buttons.scss */
.btn-twitter.btn-transparent {
  background-color: rgba(0, 167, 230, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-twitter.btn-transparent:hover,
.btn-twitter.btn-transparent:focus {
  background-color: rgba(0, 167, 230, 0.9);
}

/* line 366, src/assets/scss/components/_components.buttons.scss */
.btn-vk {
  background-color: #45668e;
  border-color: #45668e;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-vk:hover,
.btn-vk:focus {
  background-color: #344d6c;
  border-color: #344d6c;
  color: #fff;
}

/* line 376, src/assets/scss/components/_components.buttons.scss */
.btn-vk.btn-transparent {
  background-color: rgba(69, 102, 142, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-vk.btn-transparent:hover,
.btn-vk.btn-transparent:focus {
  background-color: rgba(69, 102, 142, 0.9);
}

/* line 385, src/assets/scss/components/_components.buttons.scss */
.btn-google {
  background-color: #dd4b39;
  border-color: #dd4b39;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-google:hover,
.btn-google:focus {
  background-color: #c23321;
  border-color: #c23321;
  color: #fff;
}

/* line 395, src/assets/scss/components/_components.buttons.scss */
.btn-google.btn-transparent {
  background-color: rgba(221, 75, 57, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-google.btn-transparent:hover,
.btn-google.btn-transparent:focus {
  background-color: rgba(69, 102, 142, 0.9);
}

/* line 404, src/assets/scss/components/_components.buttons.scss */
.btn-instagram {
  background-color: #c13584;
  border-color: #c13584;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-instagram:hover,
.btn-instagram:focus {
  background-color: #992a69;
  border-color: #992a69;
  color: #fff;
}

/* line 414, src/assets/scss/components/_components.buttons.scss */
.btn-instagram.btn-transparent {
  background-color: rgba(193, 53, 132, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-instagram.btn-transparent:hover,
.btn-instagram.btn-transparent:focus {
  background-color: rgba(193, 53, 132, 0.9);
}

/* line 424, src/assets/scss/components/_components.buttons.scss */
.btn-pinterest {
  background-color: #bd081c;
  border-color: #bd081c;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-pinterest:hover,
.btn-pinterest:focus {
  background-color: #8c0615;
  border-color: #8c0615;
  color: #fff;
}

/* line 434, src/assets/scss/components/_components.buttons.scss */
.btn-pinterest.btn-transparent {
  background-color: rgba(189, 8, 28, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-pinterest.btn-transparent:hover,
.btn-pinterest.btn-transparent:focus {
  background-color: rgba(189, 8, 28, 0.9);
}

/* line 444, src/assets/scss/components/_components.buttons.scss */
.btn-dribbble {
  background-color: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-dribbble:hover,
.btn-dribbble:focus {
  background-color: #e51e6b;
  border-color: #e51e6b;
  color: #fff;
}

/* line 454, src/assets/scss/components/_components.buttons.scss */
.btn-dribbble.btn-transparent {
  background-color: rgba(234, 76, 137, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-dribbble.btn-transparent:hover,
.btn-dribbble.btn-transparent:focus {
  background-color: rgba(234, 76, 137, 0.9);
}

/* line 464, src/assets/scss/components/_components.buttons.scss */
.btn-behance {
  background-color: #1769ff;
  border-color: #1769ff;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-behance:hover,
.btn-behance:focus {
  background-color: #0050e3;
  border-color: #0050e3;
  color: #fff;
}

/* line 474, src/assets/scss/components/_components.buttons.scss */
.btn-behance.btn-transparent {
  background-color: rgba(23, 105, 255, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-behance.btn-transparent:hover,
.btn-behance.btn-transparent:focus {
  background-color: rgba(23, 105, 255, 0.9);
}

/* line 483, src/assets/scss/components/_components.buttons.scss */
.btn-flickr {
  background-color: #0063dc;
  border-color: #0063dc;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-flickr:hover,
.btn-flickr:focus {
  background-color: #004ca9;
  border-color: #004ca9;
  color: #fff;
}

/* line 493, src/assets/scss/components/_components.buttons.scss */
.btn-flickr.btn-transparent {
  background-color: rgba(0, 99, 220, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-flickr.btn-transparent:hover,
.btn-flickr.btn-transparent:focus {
  background-color: rgba(0, 99, 220, 0.9);
}

/* line 503, src/assets/scss/components/_components.buttons.scss */
.btn-tumblr {
  background-color: #35465c;
  border-color: #35465c;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-tumblr:hover,
.btn-tumblr:focus {
  background-color: #222d3c;
  border-color: #222d3c;
  color: #fff;
}

/* line 513, src/assets/scss/components/_components.buttons.scss */
.btn-tumblr.btn-transparent {
  background-color: rgba(53, 70, 92, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-tumblr.btn-transparent:hover,
.btn-tumblr.btn-transparent:focus {
  background-color: rgba(53, 70, 92, 0.9);
}

/* line 523, src/assets/scss/components/_components.buttons.scss */
.btn-linkedin {
  background-color: #0077b5;
  border-color: #0077b5;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-linkedin:hover,
.btn-linkedin:focus {
  background-color: #005582;
  border-color: #005582;
  color: #fff;
}

/* line 533, src/assets/scss/components/_components.buttons.scss */
.btn-linkedin.btn-transparent {
  background-color: rgba(0, 119, 181, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-linkedin.btn-transparent:hover,
.btn-linkedin.btn-transparent:focus {
  background-color: rgba(0, 119, 181, 0.9);
}

/* line 543, src/assets/scss/components/_components.buttons.scss */
.btn-youtube {
  background-color: #ff0000;
  border-color: #ff0000;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-youtube:hover,
.btn-youtube:focus {
  background-color: #cc0000;
  border-color: #cc0000;
  color: #fff;
}

/* line 553, src/assets/scss/components/_components.buttons.scss */
.btn-youtube.btn-transparent {
  background-color: rgba(255, 0, 0, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-youtube.btn-transparent:hover,
.btn-youtube.btn-transparent:focus {
  background-color: rgba(255, 0, 0, 0.9);
}

/* line 562, src/assets/scss/components/_components.buttons.scss */
.btn-vimeo {
  background-color: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-vimeo:hover,
.btn-vimeo:focus {
  background-color: #1295bf;
  border-color: #1295bf;
  color: #fff;
}

/* line 572, src/assets/scss/components/_components.buttons.scss */
.btn-vimeo.btn-transparent {
  background-color: rgba(26, 183, 234, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-vimeo.btn-transparent:hover,
.btn-vimeo.btn-transparent:focus {
  background-color: rgba(26, 183, 234, 0.9);
}

/* line 581, src/assets/scss/components/_components.buttons.scss */
.btn-stumbleupon {
  background-color: #5382dc;
  border-color: #5382dc;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-stumbleupon:hover,
.btn-stumbleupon:focus {
  background-color: #e64152;
  border-color: #e64152;
  color: #fff;
}

/* line 591, src/assets/scss/components/_components.buttons.scss */
.btn-stumbleupon.btn-transparent {
  background-color: rgba(236, 110, 123, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-stumbleupon.btn-transparent:hover,
.btn-stumbleupon.btn-transparent:focus {
  background-color: rgba(236, 110, 123, 0.9);
}

/* line 601, src/assets/scss/components/_components.buttons.scss */
.btn-digg {
  background-color: #005be2;
  border-color: #005be2;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-digg:hover,
.btn-digg:focus {
  background-color: #0046af;
  border-color: #0046af;
  color: #fff;
}

/* line 611, src/assets/scss/components/_components.buttons.scss */
.btn-digg.btn-transparent {
  background-color: rgba(0, 91, 226, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-digg.btn-transparent:hover,
.btn-digg.btn-transparent:focus {
  background-color: rgba(0, 91, 226, 0.9);
}

/* line 621, src/assets/scss/components/_components.buttons.scss */
.btn-rss {
  background-color: #f26522;
  border-color: #f26522;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-rss:hover,
.btn-rss:focus {
  background-color: #d54d0d;
  border-color: #d54d0d;
  color: #fff;
}

/* line 631, src/assets/scss/components/_components.buttons.scss */
.btn-rss.btn-transparent {
  background-color: rgba(242, 101, 34, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-rss.btn-transparent:hover,
.btn-rss.btn-transparent:focus {
  background-color: rgba(242, 101, 34, 0.9);
}

/* line 641, src/assets/scss/components/_components.buttons.scss */
.btn-skype {
  background-color: #00aff0;
  border-color: #00aff0;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-skype:hover,
.btn-skype:focus {
  background-color: #008abd;
  border-color: #008abd;
  color: #fff;
}

/* line 651, src/assets/scss/components/_components.buttons.scss */
.btn-skype.btn-transparent {
  background-color: rgba(0, 175, 240, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-skype.btn-transparent:hover,
.btn-skype.btn-transparent:focus {
  background-color: rgba(0, 175, 240, 0.9);
}

/* line 660, src/assets/scss/components/_components.buttons.scss */
.btn-dropbox {
  background-color: #007ee5;
  border-color: #007ee5;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-dropbox:hover,
.btn-dropbox:focus {
  background-color: #0062b2;
  border-color: #0062b2;
  color: #fff;
}

/* line 670, src/assets/scss/components/_components.buttons.scss */
.btn-dropbox.btn-transparent {
  background-color: rgba(0, 126, 229, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-dropbox.btn-transparent:hover,
.btn-dropbox.btn-transparent:focus {
  background-color: rgba(0, 126, 229, 0.9);
}

/* line 679, src/assets/scss/components/_components.buttons.scss */
.btn-github {
  background-color: #4078c0;
  border-color: #4078c0;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-github:hover,
.btn-github:focus {
  background-color: #33609a;
  border-color: #33609a;
  color: #fff;
}

/* line 689, src/assets/scss/components/_components.buttons.scss */
.btn-github.btn-transparent {
  background-color: rgba(64, 120, 192, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-github.btn-transparent:hover,
.btn-github.btn-transparent:focus {
  background-color: rgba(64, 120, 192, 0.9);
}

/* line 698, src/assets/scss/components/_components.buttons.scss */
.btn-gitlab {
  background-color: #fca326;
  border-color: #fca326;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-gitlab:hover,
.btn-gitlab:focus {
  background-color: #ec8b03;
  border-color: #ec8b03;
  color: #fff;
}

/* line 708, src/assets/scss/components/_components.buttons.scss */
.btn-gitlab.btn-transparent {
  background-color: rgba(252, 163, 38, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-gitlab.btn-transparent:hover,
.btn-gitlab.btn-transparent:focus {
  background-color: rgba(252, 163, 38, 0.9);
}

/* line 717, src/assets/scss/components/_components.buttons.scss */
.btn-reddit {
  background-color: #ff4500;
  border-color: #ff4500;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-reddit:hover,
.btn-reddit:focus {
  background-color: #cc3700;
  border-color: #cc3700;
  color: #fff;
}

/* line 727, src/assets/scss/components/_components.buttons.scss */
.btn-reddit.btn-transparent {
  background-color: rgba(255, 69, 0, 0.7);
  border-color: transparent;
  color: #fff;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-reddit.btn-transparent:hover,
.btn-reddit.btn-transparent:focus {
  background-color: rgba(255, 69, 0, 0.9);
}

/* line 738, src/assets/scss/components/_components.buttons.scss */
.btn-outline-facebook {
  background-color: transparent;
  border-color: #4064ac;
  color: #4064ac;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-outline-facebook:hover,
.btn-outline-facebook:focus {
  background-color: #4064ac;
  border-color: #4064ac;
  color: #fff;
}

/* line 750, src/assets/scss/components/_components.buttons.scss */
.btn-outline-twitter {
  background-color: transparent;
  border-color: #00a7e6;
  color: #00a7e6;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
.btn-outline-twitter:hover,
.btn-outline-twitter:focus {
  background-color: #00a7e6;
  border-color: #00a7e6;
  color: #fff;
}

/* line 763, src/assets/scss/components/_components.buttons.scss */
.btn.facebook-bg,
.btn.linkedin-bg,
.btn.twitter-bg,
.btn.instagram-bg,
.btn.googleplus-bg,
.btn.pinterest-bg,
.btn.dribbble-bg,
.btn.behance-bg,
.btn.dark-bg {
  border-color: rgba(0, 0, 0, 0) !important;
}

/* line 775, src/assets/scss/components/_components.buttons.scss */
.btn.facebook-bg,
.btn.linkedin-bg,
.btn.twitter-bg,
.btn.instagram-bg,
.btn.googleplus-bg,
.btn.pinterest-bg,
.btn.dribbble-bg,
.btn.behance-bg,
.btn.dark-bg {
  color: #fff;
}

/* line 787, src/assets/scss/components/_components.buttons.scss */
.btn.facebook-bg i,
.btn.linkedin-bg i,
.btn.twitter-bg i,
.btn.instagram-bg i,
.btn.googleplus-bg i,
.btn.pinterest-bg i,
.btn.dribbble-bg i,
.btn.behance-bg i,
.btn.dark-bg i {
  color: #fff;
}

/* line 799, src/assets/scss/components/_components.buttons.scss */
.btn.facebook-bg:hover,
.btn.linkedin-bg:hover,
.btn.twitter-bg:hover,
.btn.instagram-bg:hover,
.btn.googleplus-bg:hover,
.btn.pinterest-bg:hover,
.btn.dribbble-bg:hover,
.btn.behance-bg:hover,
.btn.dark-bg:hover {
  opacity: 0.9;
}

/* line 810, src/assets/scss/components/_components.buttons.scss */
.facebook-color {
  color: #4064ac;
}

/* line 814, src/assets/scss/components/_components.buttons.scss */
.twitter-color {
  color: #00a7e6;
}

/* line 818, src/assets/scss/components/_components.buttons.scss */
.googleplus-color {
  color: #dd4b39;
}

/* line 822, src/assets/scss/components/_components.buttons.scss */
.instagram-color {
  color: #c13584;
}

/* line 826, src/assets/scss/components/_components.buttons.scss */
.pinterest-color {
  color: #bd081c;
}

/* line 830, src/assets/scss/components/_components.buttons.scss */
.dribbble-color {
  color: #ea4c89;
}

/* line 834, src/assets/scss/components/_components.buttons.scss */
.behance-color {
  color: #1769ff;
}

/* line 838, src/assets/scss/components/_components.buttons.scss */
.linkedin-color {
  color: #0077b5;
}

/* line 841, src/assets/scss/components/_components.buttons.scss */
.facebook-bg {
  background: #4064ac;
  color: #fff;
}

/* line 846, src/assets/scss/components/_components.buttons.scss */
.twitter-bg {
  background: #00a7e6;
  color: #fff;
}

/* line 851, src/assets/scss/components/_components.buttons.scss */
.googleplus-bg {
  background: #dd4b39;
  color: #fff;
}

/* line 856, src/assets/scss/components/_components.buttons.scss */
.instagram-bg {
  background: #c13584;
  color: #fff;
}

/* line 861, src/assets/scss/components/_components.buttons.scss */
.pinterest-bg {
  background: #bd081c;
  color: #fff;
}

/* line 866, src/assets/scss/components/_components.buttons.scss */
.dribbble-bg {
  background: #ea4c89;
  color: #fff;
}

/* line 871, src/assets/scss/components/_components.buttons.scss */
.behance-bg {
  background: #1769ff;
  color: #fff;
}

/* line 876, src/assets/scss/components/_components.buttons.scss */
.linkedin-bg {
  background: #0077b5;
  color: #fff;
}

/* line 882, src/assets/scss/components/_components.buttons.scss */
.icon-btn {
  padding-left: 4.57142857em;
}

/* line 884, src/assets/scss/components/_components.buttons.scss */
.icon-btn i {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  padding: 0 13px;
  border-radius: 6px 0 0 6px;
  line-height: 38px;
}

/* line 898, src/assets/scss/components/_components.buttons.scss */
.icon-btn-hover {
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  border: none;
  overflow: hidden;
  text-decoration: none !important;
  line-height: 3.11714286em;
  padding: 0 0 0 3.58571429em;
  width: 14.375rem;
  text-align: center;
  position: relative;
  display: inline-block;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
}

/* line 915, src/assets/scss/components/_components.buttons.scss */
.icon-btn-hover i {
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  width: 3.125rem;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  padding: 0 13px;
  border-radius: 4px 0 0 4px;
  line-height: inherit;
}

/* line 931, src/assets/scss/components/_components.buttons.scss */
.icon-btn-hover:hover {
  padding: 0;
}

/* line 933, src/assets/scss/components/_components.buttons.scss */
.icon-btn-hover:hover i {
  left: -3.125rem;
}

/* line 937, src/assets/scss/components/_components.buttons.scss */
.icon-btn-hover.hover-style-1 {
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  border: none;
  overflow: hidden;
  width: 14.375rem;
  line-height: 3.11714286em;
  padding: 0 3.125rem 0 0;
  text-align: center;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
}

/* line 951, src/assets/scss/components/_components.buttons.scss */
.icon-btn-hover.hover-style-1 i {
  left: auto;
  right: 0 !important;
  top: 0;
}

/* line 956, src/assets/scss/components/_components.buttons.scss */
.icon-btn-hover.hover-style-1:hover {
  padding: 0;
}

/* line 958, src/assets/scss/components/_components.buttons.scss */
.icon-btn-hover.hover-style-1:hover i {
  right: -3.125rem !important;
}

/* line 990, src/assets/scss/components/_components.buttons.scss */
.demo-components {
  font-size: 0.875rem;
  letter-spacing: normal;
  padding: 10px;
  background-color: #fff;
  border: 1px solid rgba(72, 94, 144, 0.16);
  position: relative;
  border-radius: 4px;
}

/* line 1000, src/assets/scss/components/_components.buttons.scss */
.demo-components::before {
  content: attr(data-label);
  display: block;
  position: absolute;
  top: -11px;
  left: 30px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  background-color: inherit;
  color: #8392a5;
  padding: 0 5px;
}

@media (min-width: 992px) {
  /* line 1016, src/assets/scss/components/_components.buttons.scss */
  .demo-components {
    padding: 30px;
  }
}

/* line 1022, src/assets/scss/components/_components.buttons.scss */
.button-elements button,
.button-elements a.btn {
  margin: 0 5px 10px 0;
}

@keyframes animOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(35deg);
    transform: translate(73px, -1px) rotate(35deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(75deg);
    transform: translate(141px, 72px) rotate(75deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(110deg);
    transform: translate(83px, 122px) rotate(110deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(145deg);
    transform: translate(-40px, 72px) rotate(145deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes animOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(35deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(75deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(110deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(145deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    transform: translate(73px, -1px) rotate(36deg) scale(0.9);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
    transform: translate(141px, 72px) rotate(72deg) scale(1);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    transform: translate(83px, 122px) rotate(108deg) scale(1.2);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
    transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
}

@-webkit-keyframes animTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
}

@keyframes animThree {
  0% {
    -webkit-transform: translate(165px, -179px);
    transform: translate(165px, -179px);
  }

  100% {
    -webkit-transform: translate(-346px, 617px);
    transform: translate(-346px, 617px);
  }
}

@-webkit-keyframes animThree {
  0% {
    -webkit-transform: translate(165px, -179px);
  }

  100% {
    -webkit-transform: translate(-346px, 617px);
  }
}

@keyframes animFour {
  0% {
    -webkit-transform: translate(-300px, 151px) rotate(0deg);
    transform: translate(-300px, 151px) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(251px, -200px) rotate(180deg);
    transform: translate(251px, -200px) rotate(180deg);
  }
}

@-webkit-keyframes animFour {
  0% {
    -webkit-transform: translate(-300px, 151px) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(251px, -200px) rotate(180deg);
  }
}

@keyframes animFive {
  0% {
    -webkit-transform: translate(61px, -99px) rotate(0deg);
    transform: translate(61px, -99px) rotate(0deg);
  }

  21% {
    -webkit-transform: translate(4px, -190px) rotate(38deg);
    transform: translate(4px, -190px) rotate(38deg);
  }

  41% {
    -webkit-transform: translate(-139px, -200px) rotate(74deg);
    transform: translate(-139px, -200px) rotate(74deg);
  }

  60% {
    -webkit-transform: translate(-263px, -164px) rotate(108deg);
    transform: translate(-263px, -164px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-195px, -49px) rotate(144deg);
    transform: translate(-195px, -49px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(-1px, 0px) rotate(180deg);
    transform: translate(-1px, 0px) rotate(180deg);
  }
}

@-webkit-keyframes animFive {
  0% {
    -webkit-transform: translate(61px, -99px) rotate(0deg);
  }

  21% {
    -webkit-transform: translate(4px, -190px) rotate(38deg);
  }

  41% {
    -webkit-transform: translate(-139px, -200px) rotate(74deg);
  }

  60% {
    -webkit-transform: translate(-263px, -164px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-195px, -49px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(-1px, 0px) rotate(180deg);
  }
}

/* line 169, src/assets/scss/components/_components.animation.scss */
.animated-shape-wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* line 176, src/assets/scss/components/_components.animation.scss */
.animated-shape-wrap .animated-shape-item {
  position: absolute;
}

/* line 178, src/assets/scss/components/_components.animation.scss */
.animated-shape-wrap .animated-shape-item:nth-child(1) {
  background: rgba(0, 201, 157, 0.6);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 12%;
  left: 15%;
  -webkit-animation: animTwo 13s infinite linear;
  animation: animTwo 13s infinite linear;
}

/* line 187, src/assets/scss/components/_components.animation.scss */
.animated-shape-wrap .animated-shape-item:nth-child(2) {
  border: 5px solid rgba(0, 153, 229, 0.3);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: 15%;
  left: 45%;
  -webkit-animation: animOne 15s infinite linear;
  animation: animOne 15s infinite linear;
}

/* line 197, src/assets/scss/components/_components.animation.scss */
.animated-shape-wrap .animated-shape-item:nth-child(3) {
  border: 5px solid rgba(244, 34, 104, 0.3);
  width: 25px;
  height: 25px;
  bottom: 20%;
  left: 30%;
  -webkit-animation: animFour 15s infinite linear alternate;
  animation: animFour 15s infinite linear alternate;
}

/* line 206, src/assets/scss/components/_components.animation.scss */
.animated-shape-wrap .animated-shape-item:nth-child(4) {
  background: rgba(252, 162, 73, 0.8);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  bottom: 15%;
  right: 25%;
  -webkit-animation: animFive 15s infinite linear alternate;
  animation: animFive 15s infinite linear alternate;
}

/* line 216, src/assets/scss/components/_components.animation.scss */
.animated-shape-wrap .animated-shape-item:nth-child(5) {
  background: rgba(100, 45, 250, 0.4);
  width: 3px;
  height: 20px;
  top: 18%;
  right: 25%;
  -webkit-animation: animFour 15s infinite linear alternate;
  animation: animFour 15s infinite linear alternate;
}

/* line 224, src/assets/scss/components/_components.animation.scss */
.animated-shape-wrap .animated-shape-item:nth-child(5):before,
.animated-shape-wrap .animated-shape-item:nth-child(5):after {
  content: "";
  display: block;
  width: 100%;
  height: calc(50% - 2px);
  top: 6px;
  background: inherit;
  position: absolute;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* line 238, src/assets/scss/components/_components.animation.scss */
.animated-shape-wrap .animated-shape-item:nth-child(5):before {
  right: -6px;
}

/* line 241, src/assets/scss/components/_components.animation.scss */
.animated-shape-wrap .animated-shape-item:nth-child(5):after {
  left: -6px;
}

/* line 4, src/assets/scss/components/_components.icons.scss */
.icon-size-xs {
  font-size: 22px;
}

/* line 7, src/assets/scss/components/_components.icons.scss */
.icon-size-sm {
  font-size: 26px;
}

/* line 10, src/assets/scss/components/_components.icons.scss */
.icon-size-default {
  font-size: 30px;
}

/* line 13, src/assets/scss/components/_components.icons.scss */
.icon-size-md {
  font-size: 38px;
}

/* line 16, src/assets/scss/components/_components.icons.scss */
.icon-size-lg {
  font-size: 46px;
}

/* line 4, src/assets/scss/components/_components.social-icon.scss */
.social-list-default li a {
  display: block;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  overflow: hidden;
  height: 32px;
  width: 32px;
  line-height: 32px;
  border-radius: 4px;
  font-size: 1rem;
}

/* line 18, src/assets/scss/components/_components.social-icon.scss */
.social-list-default
  li:not(.bg-brand-01):not(.bg-brand-02):not(.bg-brand-03)
  a {
  color: #696969;
}

/* line 21, src/assets/scss/components/_components.social-icon.scss */
.social-list-default li:not(:hover) {
  opacity: 0.8;
}

/* line 27, src/assets/scss/components/_components.social-icon.scss */
.social-list-default .list-inline-item:not(:last-child) {
  margin-right: 0.3rem;
}

/* line 36, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color
  li:not(.primary-bg):not(.dark-bg):not(.secondary-bg):not(.accent-bg)
  a {
  color: inherit;
}

/* line 39, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li:not(:hover) {
  opacity: 0.8;
}

/* line 42, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.facebook {
  color: #4064ac !important;
}

/* line 45, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.twitter {
  color: #00a7e6 !important;
}

/* line 48, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.google {
  color: #dd4b39 !important;
}

/* line 51, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.dribbble {
  color: #ea4c89 !important;
}

/* line 54, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.vk {
  color: #45668e !important;
}

/* line 57, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.linkedin {
  color: #0077b5 !important;
}

/* line 60, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.instagram {
  color: #c13584 !important;
}

/* line 63, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.tumblr {
  color: #35465c !important;
}

/* line 66, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.flickr {
  color: #0063dc !important;
}

/* line 69, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.youtube {
  color: #ff0000 !important;
}

/* line 72, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.rss {
  color: #f26522 !important;
}

/* line 75, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.reddit {
  color: #ff4500 !important;
}

/* line 78, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.behance {
  color: #1769ff !important;
}

/* line 81, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.vimeo {
  color: #1ab7ea !important;
}

/* line 84, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.stumbleupon {
  color: #ec6e7b !important;
}

/* line 87, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.digg {
  color: #005be2 !important;
}

/* line 90, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.skype {
  color: #00aff0 !important;
}

/* line 93, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.dropbox {
  color: #007ee5 !important;
}

/* line 96, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.github {
  color: #4078c0 !important;
}

/* line 99, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.gitlab {
  color: #fca326 !important;
}

/* line 102, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color li a.pinterest {
  color: #bd081c !important;
}

/* line 107, src/assets/scss/components/_components.social-icon.scss */
.social-list-default.social-color .list-inline-item:not(:last-child) {
  margin-right: 0.3rem;
}

@-webkit-keyframes topToBottom {
  49% {
    -webkit-transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes topToBottom {
  49% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }
}

@-webkit-keyframes bottomToTop {
  49% {
    -webkit-transform: translateY(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes bottomToTop {
  49% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
}

/* line 153, src/assets/scss/components/_components.social-icon.scss */
.icon-hover-top-bottom li:hover i {
  -webkit-animation: topToBottom 0.4s both;
  animation: topToBottom 0.4s both;
}

/* line 164, src/assets/scss/components/_components.social-icon.scss */
.icon-hover-bottom-top li:hover i {
  -webkit-animation: bottomToTop 0.4s both;
  animation: bottomToTop 0.4s both;
}

/* line 178, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a {
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  color: #fff !important;
  border-radius: 100%;
}

/* line 187, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.facebook {
  background: #4064ac;
  color: #fff !important;
}

/* line 191, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.twitter {
  background: #00a7e6;
  color: #fff !important;
}

/* line 195, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.google {
  background: #dd4b39;
  color: #fff !important;
}

/* line 199, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.dribbble {
  background: #ea4c89;
  color: #fff !important;
}

/* line 203, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.vk {
  background: #45668e;
  color: #fff !important;
}

/* line 207, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.linkedin {
  background: #0077b5;
  color: #fff !important;
}

/* line 211, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.instagram {
  background: #c13584;
  color: #fff !important;
}

/* line 215, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.tumblr {
  background: #35465c;
  color: #fff !important;
}

/* line 219, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.flickr {
  background: #0063dc;
  color: #fff !important;
}

/* line 223, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.youtube {
  background: #ff0000;
  color: #fff !important;
}

/* line 227, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.rss {
  background: #f26522;
  color: #fff !important;
}

/* line 231, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.reddit {
  background: #ff4500;
  color: #fff !important;
}

/* line 235, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.behance {
  background: #1769ff;
  color: #fff !important;
}

/* line 239, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.vimeo {
  background: #1ab7ea;
  color: #fff !important;
}

/* line 243, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.stumbleupon {
  background: #ec6e7b;
  color: #fff !important;
}

/* line 247, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.digg {
  background: #005be2;
  color: #fff !important;
}

/* line 251, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.skype {
  background: #00aff0;
  color: #fff !important;
}

/* line 255, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.dropbox {
  background: #007ee5;
  color: #fff !important;
}

/* line 259, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.github {
  background: #4078c0;
  color: #fff !important;
}

/* line 263, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.gitlab {
  background: #fca326;
  color: #fff !important;
}

/* line 267, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2 li:hover a.pinterest {
  background: #bd081c;
  color: #fff !important;
}

/* line 279, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.facebook {
  background: #4064ac;
  color: #fff !important;
}

/* line 283, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.twitter {
  background: #00a7e6;
  color: #fff !important;
}

/* line 287, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.google {
  background: #dd4b39;
  color: #fff !important;
}

/* line 291, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.dribbble {
  background: #ea4c89;
  color: #fff !important;
}

/* line 295, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.vk {
  background: #45668e;
  color: #fff !important;
}

/* line 299, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.linkedin {
  background: #0077b5;
  color: #fff !important;
}

/* line 303, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.instagram {
  background: #c13584;
  color: #fff !important;
}

/* line 307, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.tumblr {
  background: #35465c;
  color: #fff !important;
}

/* line 311, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.flickr {
  background: #0063dc;
  color: #fff !important;
}

/* line 315, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.youtube {
  background: #ff0000;
  color: #fff !important;
}

/* line 319, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.rss {
  background: #f26522;
  color: #fff !important;
}

/* line 323, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.reddit {
  background: #ff4500;
  color: #fff !important;
}

/* line 327, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.behance {
  background: #1769ff;
  color: #fff !important;
}

/* line 331, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.vimeo {
  background: #1ab7ea;
  color: #fff !important;
}

/* line 335, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.stumbleupon {
  background: #ec6e7b;
  color: #fff !important;
}

/* line 339, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.digg {
  background: #005be2;
  color: #fff !important;
}

/* line 343, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.skype {
  background: #00aff0;
  color: #fff !important;
}

/* line 347, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.dropbox {
  background: #007ee5;
  color: #fff !important;
}

/* line 351, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.github {
  background: #4078c0;
  color: #fff !important;
}

/* line 355, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.gitlab {
  background: #fca326;
  color: #fff !important;
}

/* line 359, src/assets/scss/components/_components.social-icon.scss */
.background-color.social-hover-2 li a.pinterest {
  background: #bd081c;
  color: #fff !important;
}

/* line 368, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.facebook {
  background: rgba(59, 89, 152, 0.4);
  color: #4064ac !important;
}

/* line 372, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.twitter {
  background: rgba(29, 161, 242, 0.4);
  color: #00a7e6 !important;
}

/* line 376, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.google {
  background: rgba(221, 75, 57, 0.4);
  color: #dd4b39 !important;
}

/* line 380, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.dribbble {
  background: rgba(234, 76, 137, 0.4);
  color: #ea4c89 !important;
}

/* line 384, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.vk {
  background: rgba(69, 102, 142, 0.4);
  color: #45668e !important;
}

/* line 388, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.linkedin {
  background: rgba(0, 119, 181, 0.4);
  color: #0077b5 !important;
}

/* line 392, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.instagram {
  background: rgba(64, 93, 230, 0.4);
  color: #c13584 !important;
}

/* line 396, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.tumblr {
  background: rgba(53, 70, 92, 0.4);
  color: #35465c !important;
}

/* line 400, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.flickr {
  background: rgba(0, 99, 220, 0.4);
  color: #0063dc !important;
}

/* line 404, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.youtube {
  background: rgba(255, 0, 0, 0.4);
  color: #ff0000 !important;
}

/* line 408, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.rss {
  background: rgba(242, 101, 34, 0.4);
  color: #f26522 !important;
}

/* line 412, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.reddit {
  background: rgba(255, 69, 0, 0.4);
  color: #ff4500 !important;
}

/* line 416, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.behance {
  background: rgba(23, 105, 255, 0.4);
  color: #1769ff !important;
}

/* line 420, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.vimeo {
  background: rgba(26, 183, 234, 0.4);
  color: #1ab7ea !important;
}

/* line 424, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.stumbleupon {
  background: rgba(235, 73, 36, 0.4);
  color: #ec6e7b !important;
}

/* line 428, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.digg {
  background: rgba(0, 91, 226, 0.4);
  color: #005be2 !important;
}

/* line 432, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.skype {
  background: rgba(0, 175, 240, 0.4);
  color: #00aff0 !important;
}

/* line 436, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.dropbox {
  background: rgba(0, 126, 229, 0.4);
  color: #007ee5 !important;
}

/* line 440, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.github {
  background: rgba(64, 120, 192, 0.39);
  color: #4078c0 !important;
}

/* line 444, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.gitlab {
  background: rgba(252, 163, 38, 0.4);
  color: #fca326 !important;
}

/* line 448, src/assets/scss/components/_components.social-icon.scss */
.social-color.background-color li a.pinterest {
  background: rgba(189, 8, 28, 0.4);
  color: #bd081c !important;
}

/* line 456, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2.rounded-icon li a {
  border-radius: 100%;
}

/* line 461, src/assets/scss/components/_components.social-icon.scss */
.social-hover-2.rounded-icon li:hover a {
  border-radius: 4px;
}

/* line 1, src/assets/scss/components/_components.page-header.scss */
.custom-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  font-family: themify;
  content: "\e649";
  font-size: 10px;
  padding-top: 2px;
  opacity: 0.7;
  color: inherit;
}

/* line 9, src/assets/scss/components/_components.page-header.scss */
.custom-breadcrumb ol li a,
.custom-breadcrumb ol li.breadcrumb-item.active {
  opacity: 0.7;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

/* line 15, src/assets/scss/components/_components.page-header.scss */
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.65rem;
  color: #6c757d;
  content: "/";
  font-size: 12px;
}

/* line 22, src/assets/scss/components/_components.page-header.scss */
.custom-breadcrumb ol li.list-inline-item:not(:last-child) {
  margin-right: 0;
}

/* line 26, src/assets/scss/components/_components.page-header.scss */
.custom-breadcrumb ol li.breadcrumb-item {
  padding-left: 0;
}

@media (min-width: 1300px) and (max-width: 1920px) {
  /* line 2, src/assets/scss/components/hero-section/_common.scss */
  .img-custom-width {
    min-width: 600px;
  }
}

/* line 7, src/assets/scss/components/hero-section/_common.scss */
.big-text {
  position: relative;
  font-size: 100px;
  line-height: 125px;
  font-weight: 700;
}

/* line 9, src/assets/scss/components/hero-section/_common.scss */
.big-text span {
  position: absolute;
  font-size: 16px;
  top: -65px;
}

@media (min-width: 320px) and (max-width: 767px) {
  /* line 7, src/assets/scss/components/hero-section/_common.scss */
  .big-text {
    font-size: 60px;
    line-height: 80px;
  }

  /* line 18, src/assets/scss/components/hero-section/_common.scss */
  .big-text span {
    top: -35px;
  }
}

/* line 27, src/assets/scss/components/hero-section/_common.scss */
.offer-content-wrap h3 span {
  color: #f50473;
}

/* line 33, src/assets/scss/components/hero-section/_common.scss */
.offer-tag-wrap img {
  position: relative;
  display: block;
  width: 100%;
}

/* line 39, src/assets/scss/components/hero-section/_common.scss */
.offer-badge {
  position: absolute;
  top: -1%;
  right: 18%;
  background: #f50473;
  border-radius: 130px 50px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
}

/* line 47, src/assets/scss/components/hero-section/_common.scss */
.offer-content {
  width: 195px;
  height: 195px;
  text-align: center;
  position: relative;
  background: #f5f5f5;
  border-radius: 100%;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
}

/* line 56, src/assets/scss/components/hero-section/_common.scss */
.offer-content .offer-text {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  font-family: "Montserrat", sans-serif;
}

/* line 63, src/assets/scss/components/hero-section/_common.scss */
.offer-text span {
  display: block;
  color: #f50473;
  font-size: 75px;
  line-height: 80px;
  font-weight: 700;
}

/* line 71, src/assets/scss/components/hero-section/_common.scss */
.offer-text small {
  font-size: 15px;
  font-weight: 500;
}

/* line 76, src/assets/scss/components/hero-section/_common.scss */
.fancy-radius-1 {
  border-radius: 63% 37% 30% 70% / 50% 45% 55% 50%;
}

/* line 79, src/assets/scss/components/hero-section/_common.scss */
.fancy-radius-2 {
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}

/* line 3, src/assets/scss/components/hero-section/_hero-1.scss */
.hero-slider-content h1 {
  font-weight: 600;
}

.laris-hero-slider-content h2 {
  font-weight: 600;
  color: #fff !important;
}
.laris-hero-slider-content p {
  color: #fff !important;
}

/* line 9, src/assets/scss/components/hero-section/_hero-1.scss */
.single-counter-item span.h4 {
  margin-bottom: 5px;
  display: block;
  font-weight: 600;
}

/* line 14, src/assets/scss/components/hero-section/_hero-1.scss */
.single-counter-item h6 {
  font-size: 13px;
  font-weight: 500;
}

/* line 1, src/assets/scss/components/hero-section/_hero-2.scss */
.effect-1 {
  position: absolute;
  top: -70px;
  left: -70px;
  width: 400px;
  height: 400px;
}

/* line 8, src/assets/scss/components/hero-section/_hero-2.scss */
.effect-2 {
  position: absolute;
  bottom: -70px;
  right: -70px;
  width: 400px;
  height: 400px;
}

/* line 15, src/assets/scss/components/hero-section/_hero-2.scss */
.opacity-1 {
  opacity: 0.05;
}

/* line 20, src/assets/scss/components/hero-section/_hero-2.scss */
.sm-effect-1 {
  position: absolute;
  top: -60px;
  left: -20px;
  width: 180px;
  height: 180px;
}

/* line 27, src/assets/scss/components/hero-section/_hero-2.scss */
.sm-effect-2 {
  position: absolute;
  bottom: -60px;
  right: -20px;
  width: 180px;
  height: 180px;
}

@-webkit-keyframes animation-y {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes animation-y {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes animation-x {
  0% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  50% {
    -webkit-transform: translateX(-60%);
    transform: translateX(-60%);
  }

  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@keyframes animation-x {
  0% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  50% {
    -webkit-transform: translateX(-60%);
    transform: translateX(-60%);
  }

  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

/* line 25, src/assets/scss/components/hero-section/_hero-3.scss */
.shape-image {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 55px;
  z-index: -2;
  left: 268px;
  overflow: hidden;
  background-size: cover;
  right: 0;
}

@media (min-width: 320px) and (max-width: 992px) {
  /* line 37, src/assets/scss/components/hero-section/_hero-3.scss */
  .shape-image {
    display: none;
  }
}

/* line 42, src/assets/scss/components/hero-section/_hero-3.scss */
.hero-animation-img .animated-screen {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-animation: animation-y 7s ease-in infinite;
  animation: animation-y 7s ease-in infinite;
}

/* line 47, src/assets/scss/components/hero-section/_hero-3.scss */
.hero-animation-img .animated-image img {
  position: absolute;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

/* line 50, src/assets/scss/components/hero-section/_hero-3.scss */
.hero-animation-img .animated-image img.animated-img-1 {
  top: 45%;
  width: 230px;
  left: -5px;
  -webkit-animation: animation-y 4s ease-in infinite;
  animation: animation-y 4s ease-in infinite;
}

/* line 56, src/assets/scss/components/hero-section/_hero-3.scss */
.hero-animation-img .animated-image img.animated-img-2 {
  bottom: 50px;
  left: 165px;
  width: 300px;
  -webkit-animation: animation-x 3s ease-in infinite;
  animation: animation-x 3s ease-in infinite;
}

/* line 62, src/assets/scss/components/hero-section/_hero-3.scss */
.hero-animation-img .animated-image img.animated-img-3 {
  top: 15%;
  right: -100px;
  width: 300px;
  -webkit-animation: animation-x 4s ease-in infinite;
  animation: animation-x 4s ease-in infinite;
}

/* line 68, src/assets/scss/components/hero-section/_hero-3.scss */
.hero-animation-img .animated-image img.animated-img-4 {
  right: -155px;
  bottom: 136px;
  width: 290px;
  -webkit-animation: animation-x 4s ease-in infinite;
  animation: animation-x 4s ease-in infinite;
}

/* line 74, src/assets/scss/components/hero-section/_hero-3.scss */
.hero-animation-img .animated-image img.animated-img-5 {
  top: 1%;
  width: 300px;
  left: 45px;
  -webkit-animation: animation-y 4s ease-in infinite;
  animation: animation-y 4s ease-in infinite;
}

/* line 80, src/assets/scss/components/hero-section/_hero-3.scss */
.hero-animation-img .animated-image img.animated-img-6 {
  top: 92px;
  width: 300px;
  left: 300px;
  -webkit-animation: animation-y 4s ease-in infinite;
  animation: animation-y 4s ease-in infinite;
}

/* line 91, src/assets/scss/components/hero-section/_hero-3.scss */
.background-video {
  position: relative;
  display: block;
  width: 100%;
}

/* line 95, src/assets/scss/components/hero-section/_hero-3.scss */
.background-video:before {
  position: absolute;
  content: "";
  background: linear-gradient(75deg, #0c2888, #5382dc) !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.8;
}

/* line 106, src/assets/scss/components/hero-section/_hero-3.scss */
.background-video .video-content-wraper {
  position: relative;
  z-index: 3;
}

/* line 114, src/assets/scss/components/hero-section/_hero-3.scss */
.video-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* line 120, src/assets/scss/components/hero-section/_hero-3.scss */
.video-container video {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

/* line 130, src/assets/scss/components/hero-section/_hero-3.scss */
.bg-gradient-primary {
  background: #0c2888 !important;
}

/* line 134, src/assets/scss/components/hero-section/_hero-3.scss */
.fit-cover {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: -1;
}

/* line 1, src/assets/scss/components/hero-section/_hero-4.scss */
.hero-bg-shape {
  position: relative;
  display: block;
  width: 100%;
}

/* line 5, src/assets/scss/components/hero-section/_hero-4.scss */
.hero-bg-shape:before {
  position: absolute;
  content: "";
  /* background: url("../img/hero-bg-shape-1.svg") no-repeat center bottom/cover; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}

/* line 1, src/assets/scss/components/hero-section/_hero-5.scss */
.discount-banner {
  border: 2px solid #0c2888;
  position: relative;
  padding: 40px;
  border-radius: 80px 30px 80px 30px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

/* line 7, src/assets/scss/components/hero-section/_hero-5.scss */
.discount-banner h2 {
  font-size: 6rem;
  line-height: 1;
  margin-bottom: 25px;
  font-weight: 700;
}

/* line 15, src/assets/scss/components/hero-section/_hero-5.scss */
.shape-bottom img.bottom-shape {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
}

/* line 1, src/assets/scss/components/hero-section/_hero-7.scss */
.shape-img-2 {
  position: absolute;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  width: 269px;
  height: auto;
  max-height: 100%;
  max-width: 15.7vw;
}

/* line 13, src/assets/scss/components/hero-section/_hero-7.scss */
.group-shape-1,
.group-shape-2,
.group-shape-3 {
  position: absolute;
  max-height: 100%;
  right: auto;
  -webkit-animation-name: angry-animation;
  animation-name: angry-animation;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  animation-direction: normal;
}

/* line 25, src/assets/scss/components/hero-section/_hero-7.scss */
.group-shape-1 {
  left: 0;
  top: 0;
  bottom: auto;
  margin-top: 120px;
  margin-left: 50vw;
}

/* line 32, src/assets/scss/components/hero-section/_hero-7.scss */
.group-shape-2 {
  left: 9.2vw;
  top: auto;
  bottom: 10px;
}

@-webkit-keyframes angry-animation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes angry-animation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* line 1, src/assets/scss/components/hero-section/_hero-8.scss */
.counter-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-auto-flow: dense;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
  width: 100%;
  position: relative;
  bottom: -60px;
  padding: 30px 0;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

/* line 14, src/assets/scss/components/hero-section/_hero-8.scss */
.counter-wrap li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  border-right: 1px solid rgba(72, 94, 144, 0.16);
  margin: 10px 0 !important;
}

/* line 26, src/assets/scss/components/hero-section/_hero-8.scss */
.counter-wrap li:last-child {
  border-right: none;
  margin-right: 0 !important;
}

/* line 31, src/assets/scss/components/hero-section/_hero-8.scss */
.counter-wrap li .single-counter span {
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  line-height: 28px;
}

/* line 37, src/assets/scss/components/hero-section/_hero-8.scss */
.counter-wrap li .single-counter h6 {
  color: #1b2e4b;
}

/* line 43, src/assets/scss/components/hero-section/_hero-8.scss */
.counter-wrap:before {
  position: absolute;
  left: -80px;
  width: 200px;
  height: 200px;
  background: #0c2888;
  content: "";
  top: -114px;
  border-radius: 100%;
}

/* line 53, src/assets/scss/components/hero-section/_hero-8.scss */
.counter-wrap:after {
  position: absolute;
  right: -80px;
  width: 200px;
  height: 200px;
  background: #0c2888;
  content: "";
  bottom: -135px;
  border-radius: 100%;
}

/* line 1, src/assets/scss/components/hero-section/_hero-10.scss */
.background-image-wraper.bg-position-1 {
  background-position: 50% 100% !important;
}

@media (max-width: 992px) and (min-width: 320px) {
  /* line 6, src/assets/scss/components/hero-section/_hero-10.scss */
  .background-image-wraper.bg-position-1 {
    display: none;
  }

  /* line 9, src/assets/scss/components/hero-section/_hero-10.scss */
  .background-image-wraper.bg-position-2 {
    background: #0c2888 !important;
  }
}

/* line 1, src/assets/scss/components/hero-section/_hero-13.scss */
.z-2 {
  z-index: 2;
}

/* line 4, src/assets/scss/components/hero-section/_hero-13.scss */
.z-3 {
  z-index: 3;
}

/* line 7, src/assets/scss/components/hero-section/_hero-13.scss */
.z--1 {
  z-index: -1;
}

/* line 10, src/assets/scss/components/hero-section/_hero-13.scss */
.z--2 {
  z-index: -2;
}

/* line 13, src/assets/scss/components/hero-section/_hero-13.scss */
.rounded-custom {
  border-radius: 15px;
}

/* line 16, src/assets/scss/components/hero-section/_hero-13.scss */
.animation-item {
  -webkit-perspective: 950px;
  perspective: 950px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
}

/* line 24, src/assets/scss/components/hero-section/_hero-13.scss */
.hero-animated-card-1 {
  top: 45%;
  left: -75px;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-box-shadow: 0 22px 45px rgba(0, 0, 0, 0.09);
  box-shadow: 0 22px 45px rgba(0, 0, 0, 0.09);
  -webkit-animation: move-up-down 4s ease-in-out 1s infinite;
  animation: move-up-down 4s ease-in-out 1s infinite;
}

/* line 31, src/assets/scss/components/hero-section/_hero-13.scss */
.hero-animated-card-2 {
  right: -130px;
  bottom: 4%;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-box-shadow: 0 22px 45px rgba(0, 0, 0, 0.2);
  box-shadow: 0 22px 45px rgba(0, 0, 0, 0.2);
  -webkit-animation: move-up-down 3s ease-in-out 2s infinite;
  animation: move-up-down 3s ease-in-out 2s infinite;
}

/* line 38, src/assets/scss/components/hero-section/_hero-13.scss */
.hero-animated-card-3 {
  right: -5%;
  top: 15%;
  -webkit-animation: move-up-down 4s ease-in-out 3s infinite;
  animation: move-up-down 4s ease-in-out 3s infinite;
}

/* line 43, src/assets/scss/components/hero-section/_hero-13.scss */
.hero-animated-card-4 {
  top: 10%;
  left: -75px;
  width: 50%;
  -webkit-box-shadow: 0 22px 45px rgba(0, 0, 0, 0.2);
  box-shadow: 0 22px 45px rgba(0, 0, 0, 0.2);
  -webkit-animation: move-up-down 5s ease-in-out 4s infinite;
  animation: move-up-down 5s ease-in-out 4s infinite;
}

/* line 50, src/assets/scss/components/hero-section/_hero-13.scss */
.dot-shape {
  top: 45%;
  left: 20%;
  z-index: -1;
}

@-webkit-keyframes move-up-down {
  0% {
    -webkit-transform: translateX(0) translateY(5px);
    transform: translateX(0) translateY(5px);
  }

  50% {
    -webkit-transform: translateX(0) translateY(-15px);
    transform: translateX(0) translateY(-15px);
  }

  100% {
    -webkit-transform: translateX(0) translateY(5px);
    transform: translateX(0) translateY(5px);
  }
}

@keyframes move-up-down {
  0% {
    -webkit-transform: translateX(0) translateY(5px);
    transform: translateX(0) translateY(5px);
  }

  50% {
    -webkit-transform: translateX(0) translateY(-15px);
    transform: translateX(0) translateY(-15px);
  }

  100% {
    -webkit-transform: translateX(0) translateY(5px);
    transform: translateX(0) translateY(5px);
  }
}

@media (min-width: 768px) and (max-width: 1450px) {
  /* line 71, src/assets/scss/components/hero-section/_hero-13.scss */
  .hero-animated-card-2 {
    right: auto;
    bottom: 0;
  }

  /* line 75, src/assets/scss/components/hero-section/_hero-13.scss */
  .hero-animated-card-3 {
    display: none;
  }

  /* line 78, src/assets/scss/components/hero-section/_hero-13.scss */
  .img-custom-width {
    min-width: auto;
  }

  /* line 82, src/assets/scss/components/hero-section/_hero-13.scss */
  .content-img-wrap .dot-shape {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 1136px) {
  /* line 90, src/assets/scss/components/hero-section/_hero-13.scss */
  .animation-item .hero-animated-card-4 {
    width: 40%;
  }

  /* line 95, src/assets/scss/components/hero-section/_hero-13.scss */
  .content-img-wrap .dot-shape {
    display: none;
  }
}

/* line 1, src/assets/scss/components/promo-section/_common.scss */
.icon-square {
  width: 55px;
  height: 55px;
  text-align: center;
}

/* line 2, src/assets/scss/components/promo-section/_promo-1.scss */
.promo-one-single {
  position: relative;
  display: block;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

/* line 6, src/assets/scss/components/promo-section/_promo-1.scss */
.promo-one-single a {
  text-decoration: none;
  position: absolute;
  right: 15px;
  bottom: -15px;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  -webkit-box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.12);
}

/* line 19, src/assets/scss/components/promo-section/_promo-1.scss */
.promo-one-single:hover {
  background: linear-gradient(75deg, #0c2888, #5382dc) !important;
  color: #fff;
}

/* line 22, src/assets/scss/components/promo-section/_promo-1.scss */
.promo-one-single:hover h5 {
  color: #fff !important;
}

/* line 25, src/assets/scss/components/promo-section/_promo-1.scss */
.promo-one-single:hover a {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

/* line 27, src/assets/scss/components/promo-section/_promo-1.scss */
.promo-one-single:hover a:hover {
  background: #0c2888 !important;
  color: #fff;
}

/* line 1, src/assets/scss/components/promo-section/_promo-2.scss */
.single-promo-card {
  border-radius: 1rem;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
}

/* line 5, src/assets/scss/components/promo-section/_promo-2.scss */
.single-promo-card.single-promo-hover {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  overflow: hidden;
  z-index: 1;
}

/* line 9, src/assets/scss/components/promo-section/_promo-2.scss */
.single-promo-card.single-promo-hover:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
  box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
  color: #fff;
}

/* line 13, src/assets/scss/components/promo-section/_promo-2.scss */
.single-promo-card.single-promo-hover:hover span,
.single-promo-card.single-promo-hover:hover h5 {
  color: #fff !important;
}

/* line 16, src/assets/scss/components/promo-section/_promo-2.scss */
.single-promo-card.single-promo-hover:hover:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: #0c2888;
}

/* line 27, src/assets/scss/components/promo-section/_promo-2.scss */
.single-promo-card.single-promo-hover:before {
  content: "";
  position: absolute;
  right: -55px;
  width: 95px;
  height: 95px;
  bottom: -50px;
  border-radius: 35px;
  background-color: #0c2888;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

/* line 42, src/assets/scss/components/promo-section/_promo-2.scss */
.single-promo-card.single-promo-hover-2 {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

/* line 44, src/assets/scss/components/promo-section/_promo-2.scss */
.single-promo-card.single-promo-hover-2:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
  box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
  background-color: #0c2888;
  color: #fff;
}

/* line 49, src/assets/scss/components/promo-section/_promo-2.scss */
.single-promo-card.single-promo-hover-2:hover span,
.single-promo-card.single-promo-hover-2:hover h5 {
  color: #fff !important;
}

/* line 54, src/assets/scss/components/promo-section/_promo-2.scss */
.single-promo-card:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
  box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
}

/* line 1, src/assets/scss/components/about-section/_common.scss */
.check-list-wrap {
  margin-top: -0.5em;
  margin-bottom: -0.5em;
}

/* line 4, src/assets/scss/components/about-section/_common.scss */
.check-list-wrap li {
  position: relative;
  padding: 0.5rem 0 0.5rem 1.875rem;
  line-height: inherit;
}

/* line 8, src/assets/scss/components/about-section/_common.scss */
.check-list-wrap li span {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

/* line 12, src/assets/scss/components/about-section/_common.scss */
.check-list-wrap li:before {
  position: absolute;
  top: 10px;
  left: 0;
  font-family: "Font Awesome 5 Free";
  content: "\f061";
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #0c2888;
}

/* line 25, src/assets/scss/components/about-section/_common.scss */
.check-list-wrap.list-two-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* line 29, src/assets/scss/components/about-section/_common.scss */
.check-list-wrap.list-two-col li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 50%;
  padding-right: 15px;
}

/* line 42, src/assets/scss/components/about-section/_common.scss */
.list-with-icon li .list-icon-box {
  height: 65px;
  width: 65px;
  color: #0c2888;
  margin-bottom: 0;
}

/* line 66, src/assets/scss/components/about-section/_common.scss */
.dot-circle li {
  position: relative;
  list-style: none;
  padding-left: 25px;
  margin-bottom: 10px;
}

/* line 71, src/assets/scss/components/about-section/_common.scss */
.dot-circle li:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: 0;
  top: 14px;
  background: #0c2888;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media (min-width: 320px) and (max-width: 767px) {
  /* line 88, src/assets/scss/components/about-section/_common.scss */
  .list-tow-col li {
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}

/* line 96, src/assets/scss/components/about-section/_common.scss */
.image-overlap {
  position: relative;
  display: grid;
  grid-template-rows: 2fr;
}

/* line 102, src/assets/scss/components/about-section/_common.scss */
.image-overlap-item:first-of-type {
  grid-column: 1 / span 2;
  grid-row: 1 / span 3;
}

/* line 107, src/assets/scss/components/about-section/_common.scss */
.image-overlap-item:last-of-type {
  grid-column: 2 / span 2;
  grid-row: 3 / span 2;
}

/* line 112, src/assets/scss/components/about-section/_common.scss */
.image-overlap-item .dot-shape-2 {
  top: 6px;
  left: auto;
  right: 100px;
}

/* line 119, src/assets/scss/components/about-section/_common.scss */
.text-counter-list li {
  text-align: center;
  padding: 0 15px;
  border-right: 1px solid rgba(72, 94, 144, 0.16);
  min-width: 140px;
}

/* line 124, src/assets/scss/components/about-section/_common.scss */
.text-counter-list li:last-child {
  border-right: none;
}

/* line 127, src/assets/scss/components/about-section/_common.scss */
.text-counter-list li span {
  opacity: 0.6;
}

/* line 131, src/assets/scss/components/about-section/_common.scss */
.text-counter-list .list-inline-item:not(:last-child) {
  margin-right: 0;
}

/* line 4, src/assets/scss/components/feature-section/_features-1.scss */
.features-single-wrap span {
  font-size: 24px;
  color: #0c2888;
  background: #e4c9f9;
}

/* line 1, src/assets/scss/components/feature-section/_feature-new.scss */
.feature-item {
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

/* line 7, src/assets/scss/components/feature-section/_feature-new.scss */
.feature-item:hover .feature-icon {
  color: #fff;
}

/* line 9, src/assets/scss/components/feature-section/_feature-new.scss */
.feature-item:hover .feature-icon i {
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  background: #0c2888;
}

/* line 16, src/assets/scss/components/feature-section/_feature-new.scss */
.feature-icon {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  font-size: 20px;
  color: #0c2888;
  position: relative;
}

/* line 21, src/assets/scss/components/feature-section/_feature-new.scss */
.feature-icon i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

/* line 1, src/assets/scss/components/process-section/_process-1.scss */
.work-process-wrap {
  position: relative;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

/* line 10, src/assets/scss/components/process-section/_process-1.scss */
.work-process-wrap .process-icon-item {
  position: relative;

}

/* line 14, src/assets/scss/components/process-section/_process-1.scss */
.work-process-wrap .process-icon-item .process-icon {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  height: 8.75rem;
  width: 8.75rem;
  line-height: 1;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 2.1875rem 0 rgba(119, 119, 120, 0.13);
  box-shadow: 0 0 2.1875rem 0 rgba(119, 119, 120, 0.13);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

/* line 36, src/assets/scss/components/process-section/_process-1.scss */
.work-process-wrap .process-icon-item .process-icon i {
  font-size: 2.753rem;
}

/* line 40, src/assets/scss/components/process-section/_process-1.scss */
.work-process-wrap .process-icon-item .process-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-bottom: 0.7rem;
}
.work-process-wrap .process-icon-item .process-content h5{
  margin-bottom: 0.3rem;
}
/* line 45, src/assets/scss/components/process-section/_process-1.scss */
.work-process-wrap .process-icon-item svg {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 80%;
}

/* line 53, src/assets/scss/components/process-section/_process-1.scss */
.work-process-wrap .process-icon-item svg:not(:root) {
  overflow: hidden;
}

/* line 57, src/assets/scss/components/process-section/_process-1.scss */
.work-process-wrap .right-shape .d-flex {
  -ms-flex-direction: row-reverse !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  flex-direction: row-reverse !important;
}

/* line 3, src/assets/scss/components/process-section/_process-2.scss */
.work-process-wrap-2 .work-process-number {
  position: relative;
  z-index: 400;
  margin-bottom: 35px;
}

/* line 7, src/assets/scss/components/process-section/_process-2.scss */
.work-process-wrap-2 .work-process-number:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  z-index: -7;
  border: 1px dashed #fff;
}

/* line 18, src/assets/scss/components/process-section/_process-2.scss */
.work-process-wrap-2 .work-process-number span {
  text-align: center;
  height: 100px;
  width: 100px;
  display: inline-block;
  line-height: 100px;
  border-radius: 50%;
  font-size: 38px;
  color: #1b2e4b;
  background: #fff;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

/* line 34, src/assets/scss/components/process-section/_process-2.scss */
.work-process-wrap-2:hover span {
  -webkit-box-shadow: 0 0 0 10px #e5e9f2, 0 0 0 20px #c0ccda;
  box-shadow: 0 0 0 10px #e5e9f2, 0 0 0 20px #c0ccda;
}

/* line 41, src/assets/scss/components/process-section/_process-2.scss */
.work-process-item:last-child .work-process-number:before {
  display: none;
}

@media only screen and (max-width: 991.98px) {
  /* line 49, src/assets/scss/components/process-section/_process-2.scss */
  .work-process-item:nth-child(1),
  .work-process-item:nth-child(2) {
    margin-bottom: 50px;
  }

  /* line 53, src/assets/scss/components/process-section/_process-2.scss */
  .work-process-item:nth-child(2) .work-process-number::before {
    display: none;
  }
}

@media only screen and (max-width: 575.98px) {
  /* line 59, src/assets/scss/components/process-section/_process-2.scss */
  .work-process-item {
    margin-bottom: 30px;
  }

  /* line 62, src/assets/scss/components/process-section/_process-2.scss */
  .work-process-item .work-process-number::before {
    display: none !important;
  }

  /* line 65, src/assets/scss/components/process-section/_process-2.scss */
  .work-process-item:last-child {
    margin-bottom: 0;
  }
}

/* line 1, src/assets/scss/components/call-to-action-section/_common.scss */
.mask-65 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 30%;
  background: linear-gradient(75deg, #0c2888, #6294f7) !important;
}

/*video popup*/
/* line 3, src/assets/scss/components/call-to-action-section/_video-promo.scss */
.video-play-icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  position: relative;
  z-index: 4;
  display: inline-block;
  border: 2px solid #ffffff;
  cursor: pointer;
  background: #ffffff;
}

/* line 15, src/assets/scss/components/call-to-action-section/_video-promo.scss */
.video-play-icon span {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 25%;
  font-size: 23px;
  left: 2px;
}

/* line 23, src/assets/scss/components/call-to-action-section/_video-promo.scss */
.video-promo-content a.video-play-icon-without-bip:hover span {
  color: #ffffff;
}

/* line 26, src/assets/scss/components/call-to-action-section/_video-promo.scss */
.video-promo-content a.video-play-icon {
  border-radius: 50%;
  display: block;
  -webkit-animation: ripple-white 1s linear infinite;
  animation: ripple-white 1s linear infinite;
  -webkit-transition: 0.5s;
}

/* line 34, src/assets/scss/components/call-to-action-section/_video-promo.scss */
.video-promo-content a.video-play-icon-without-bip {
  -webkit-animation: none;
  animation: none;
}

/* line 37, src/assets/scss/components/call-to-action-section/_video-promo.scss */
.video-promo-content a.video-play-icon-without-bip:hover {
  -webkit-animation: ripple-white 1s linear infinite;
  animation: ripple-white 1s linear infinite;
  -webkit-transition: 0.5s;
  background-color: #0c2888;
}

@-webkit-keyframes ripple-white {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
      0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
      0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1);
  }

  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1),
      0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1),
      0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 30px rgba(255, 255, 255, 0);
  }
}

@keyframes ripple-white {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
      0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
      0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1);
  }

  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1),
      0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1),
      0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 30px rgba(255, 255, 255, 0);
  }
}

/* line 66, src/assets/scss/components/call-to-action-section/_video-promo.scss */
.mask-image {
  /* -webkit-mask-image: url("../img/image-mask-shape.svg");
  mask-image: url("../img/image-mask-shape.svg"); */
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center center;
  mask-position: center center;
  position: relative;
  display: block;
  -webkit-transform: initial;
  transform: initial;
}

/* line 79, src/assets/scss/components/call-to-action-section/_video-promo.scss */
.shape-mask {
  /* -webkit-mask-image: url("../img/image-mask-shape.svg");
  mask-image: url("../img/image-mask-shape.svg"); */
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center center;
  mask-position: center center;
  position: relative;
  display: block;
  -webkit-transform: initial;
  transform: initial;
}

/* line 91, src/assets/scss/components/call-to-action-section/_video-promo.scss */
.shape-mask img {
  width: 100%;
}

/* line 95, src/assets/scss/components/call-to-action-section/_video-promo.scss */
.mask-image img {
  vertical-align: top;
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 105, src/assets/scss/components/call-to-action-section/_video-promo.scss */
.mask-image .item-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

/* line 5, src/assets/scss/components/sereenshots-section/_common.scss */
.dot-indicator.owl-theme button.owl-dot span {
  display: inline-block;
  width: 12px;
  height: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #8392a5;
  border-radius: 50%;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

/* line 16, src/assets/scss/components/sereenshots-section/_common.scss */
.dot-indicator.owl-theme button.owl-dot:focus {
  border: none;
  outline: none;
}

/* line 21, src/assets/scss/components/sereenshots-section/_common.scss */
.dot-indicator.owl-theme button.owl-dot.active span {
  background: #0c2888;
  border: 2px solid transparent;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

/* line 30, src/assets/scss/components/sereenshots-section/_common.scss */
.dot-indicator.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 20px;
}

/*sccreenshot css*/
/* line 2, src/assets/scss/components/sereenshots-section/_screenshots-1.scss */
.screenshot-wrap {
  position: relative;
}

/* line 4, src/assets/scss/components/sereenshots-section/_screenshots-1.scss */
.screenshot-wrap .screenshot-frame {
  background-image: url("/public/assets//img/iphone-mask.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  width: 290px;
  height: 513px;
  position: absolute;
  top: -10px;
  left: calc(50% + 0.5px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 2;
  border-radius: 20px;
}

/* line 21, src/assets/scss/components/sereenshots-section/_screenshots-1.scss */
.screenshot-wrap .screen-carousel .owl-item img {
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 30px;
}

/* line 30, src/assets/scss/components/sereenshots-section/_screenshots-1.scss */
.screenshot-wrap .screen-carousel .owl-item.active.center img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media (min-width: 320px) and (max-width: 1200px) {
  /* line 41, src/assets/scss/components/sereenshots-section/_screenshots-1.scss */
  .screenshot-wrap .screenshot-frame {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  /* line 51, src/assets/scss/components/sereenshots-section/_screenshots-1.scss */
  .screenshot-wrap .screen-carousel .owl-item img {
    width: 65%;
    margin: auto;
  }
}

/* line 1, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.sale-invoice-wrap {
  background: #f5f5f5;
  padding: 20px;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 4px;
}

/* line 7, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.register-items-holder {
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 4px;
}

/* line 11, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.register-items-holder table {
  margin-bottom: 0;
}

/* line 14, src/assets/scss/components/sereenshots-section/_sale-page.scss */
tr.register-items-header {
  min-height: 25px;
  line-height: 25px;
  background-color: #f5f5f5;
  font-size: 14px;
  font-weight: 600;
}

/* line 21, src/assets/scss/components/sereenshots-section/_sale-page.scss */
tr.register-items-header th {
  border: none !important;
}

/* line 24, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.register-item-content tr td {
  padding: 8px 25px;
  font-size: 14px;
}

/* line 29, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.customer-wrap {
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 4px;
}

/* line 32, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.customer-wrap .customer-info {
  padding: 15px;
}

/* line 35, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.customer-wrap .customer-action {
  border-top: 1px solid rgba(72, 94, 144, 0.16);
}

/* line 37, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.customer-wrap .customer-action .customer-action-list {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

/* line 42, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.customer-wrap .customer-action .customer-action-list li {
  display: table-cell;
  text-align: center;
  margin: 0;
  border-right: 1px solid rgba(72, 94, 144, 0.16);
}

/* line 47, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.customer-wrap .customer-action .customer-action-list li:last-child {
  border-right: none;
}

/* line 50, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.customer-wrap .customer-action .customer-action-list li a {
  padding: 10px;
  display: block;
  font-size: 13px;
  font-weight: 600;
  color: #8392a5;
}

/* line 56, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.customer-wrap .customer-action .customer-action-list li a i {
  color: #0c2888;
}

/* line 65, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.order-form-wrap {
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 4px;
}

/* line 68, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.order-form-wrap .order-list-wrap {
  padding: 15px;
}

/* line 76, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.order-summary-list {
  margin-bottom: 0;
  list-style: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  font-size: 13px;
  line-height: 18px;
}

/* line 83, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.order-summary-list .summary-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
}

/* line 89, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.order-summary-list .summary-list-item .pull-left {
  max-width: 70%;
  -ms-flex-preferred-size: 70%;
  flex-basis: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: inherit;
}

/* line 96, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.order-summary-list .summary-list-item .pull-right {
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  text-align: right;
  padding-left: 8px;
}

/* line 101, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.summary-list-item.sub-total {
  font-weight: 600;
}

/* line 110, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.total-amount-wrap {
  border-top: 1px dashed rgba(72, 94, 144, 0.16);
  background: #f5f5f5;
}

/* line 114, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.total-amount-wrap .total-amount {
  padding: 10px 10px;
  display: inline-block;
  width: 49%;
  text-align: center;
}

/* line 119, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.total-amount-wrap .total-amount:nth-child(1) {
  border-right: 1px dashed rgba(72, 94, 144, 0.16);
}

/* line 122, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.total-amount-wrap .total-amount h5 {
  font-size: 15px;
}

/* line 125, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.total-amount-wrap .total-amount .total {
  font-size: 22px;
  color: #28a745;
  font-weight: 700;
  line-height: initial;
}

/* line 131, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.total-amount-wrap .total-amount .total.due {
  color: #0c2888;
}

/* line 137, src/assets/scss/components/sereenshots-section/_sale-page.scss */
.add-payment-wrap {
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 4px;
  padding: 15px;
}

/* line 2, src/assets/scss/components/review-section/_review-1.scss */
.testimonial-content-wrap {
  position: relative;
}

/* line 5, src/assets/scss/components/review-section/_review-1.scss */
.testimonial-content-wrap .testimonial-tb-shape {
  position: absolute;
}

/* line 7, src/assets/scss/components/review-section/_review-1.scss */
.testimonial-content-wrap .testimonial-tb-shape.shape-top {
  top: -60px;
  right: 20%;
}

/* line 11, src/assets/scss/components/review-section/_review-1.scss */
.testimonial-content-wrap .testimonial-tb-shape.shape-bottom {
  bottom: -88px;
  right: 50%;
}

/* line 19, src/assets/scss/components/review-section/_review-1.scss */
.testimonial-content-wrap .testimonial-shape:before {
  content: "";
  position: absolute;
  top: 60px;
  left: 20px;
  width: 99%;
  height: 69%;
  background: #0c2888;
  -webkit-transform: rotate(-6deg);
  transform: rotate(-6deg);
  z-index: -1;
  border-radius: 4px;
}

/* line 32, src/assets/scss/components/review-section/_review-1.scss */
.testimonial-content-wrap .testimonial-shape .testimonial-quote-wrap {
  background: #6294f7;
  padding: 30px 40px;
  z-index: 4;
  margin-top: 30px;
  border-radius: 4px;
}

/* line 39, src/assets/scss/components/review-section/_review-1.scss */
.testimonial-content-wrap
  .testimonial-shape
  .testimonial-quote-wrap
  .author-info
  .author-img {
  border-radius: 4px;
  border: 4px solid #fff;
  width: 100px;
  position: absolute;
  top: 0;
}

/* line 46, src/assets/scss/components/review-section/_review-1.scss */
.testimonial-content-wrap
  .testimonial-shape
  .testimonial-quote-wrap
  .author-info
  .media-body {
  position: relative;
  left: 120px;
}

/* line 51, src/assets/scss/components/review-section/_review-1.scss */
.testimonial-content-wrap
  .testimonial-shape
  .testimonial-quote-wrap
  .author-info
  i {
  font-size: 40px;
}

/* line 63, src/assets/scss/components/review-section/_review-1.scss */
.single-review-wrap {
  border-radius: 1rem;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

/* line 67, src/assets/scss/components/review-section/_review-1.scss */
.single-review-wrap .review-top span.ratting-color {
  font-size: 16px;
  font-weight: 700;
  color: #fca326;
}

/* line 77, src/assets/scss/components/review-section/_review-1.scss */
.client-testimonial-2 .owl-item .single-review-wrap .review-author img {
  width: 50px !important;
}

@media (min-width: 320px) and (max-width: 1199px) {
  /* line 84, src/assets/scss/components/review-section/_review-1.scss */
  .testimonial-content-wrap .testimonial-tb-shape.shape-top,
  .testimonial-content-wrap .testimonial-tb-shape.shape-bottom {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  /* line 89, src/assets/scss/components/review-section/_review-1.scss */
  .author-info i,
  .testimonial-shape:before {
    display: none;
  }
}

/* line 5, src/assets/scss/components/price-section/_price-1.scss */
.pricing-switch-wrap {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: block;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* line 13, src/assets/scss/components/price-section/_price-1.scss */
.pricing-switch-wrap span {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

/* line 18, src/assets/scss/components/price-section/_price-1.scss */
.pricing-switch-wrap span.year-switch {
  position: absolute;
  top: 0px;
  z-index: 1;
  color: #0c2888;
}

/* line 24, src/assets/scss/components/price-section/_price-1.scss */
.pricing-switch-wrap span.beforeinput {
  left: 33px;
}

/* line 26, src/assets/scss/components/price-section/_price-1.scss */
.pricing-switch-wrap span.beforeinput.text-success {
  /* color: #fff !important; */
}

/* line 30, src/assets/scss/components/price-section/_price-1.scss */
.pricing-switch-wrap span.afterinput {
  right: 34px;
}

/* line 32, src/assets/scss/components/price-section/_price-1.scss */
.pricing-switch-wrap span.afterinput.text-success {
  /* color: #fff !important; */
}

/* line 37, src/assets/scss/components/price-section/_price-1.scss */
.pricing-switch-wrap span.switch-icon {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 42px;
  border: 1px solid #0c2888;
  border-radius: 25px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin: 0 31px;
  background: #fff;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* line 48, src/assets/scss/components/price-section/_price-1.scss */
.pricing-switch-wrap span.switch-icon:after {
  content: "";
  position: absolute;
  left: 4px;
  width: 100px;
  height: 42px;
  background-color: #0c2888;
  border-radius: 25px;
  -webkit-transform: translate3d(-6px, 0, 0);
  transform: translate3d(-6px, 0, 0);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/* line 60, src/assets/scss/components/price-section/_price-1.scss */
.pricing-switch-wrap input:checked + .switch-icon:after {
  -webkit-transform: translate3d(95px, 0, 0);
  transform: translate3d(95px, 0, 0);
}

/* line 66, src/assets/scss/components/price-section/_price-1.scss */
.single-pricing-pack {
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: none;
  border-top: 4px solid transparent;
  border-radius: 1rem;
}

/* line 72, src/assets/scss/components/price-section/_price-1.scss */
.single-pricing-pack .pricing-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem;
}

/* line 76, src/assets/scss/components/price-section/_price-1.scss */
.single-pricing-pack .pricing-content .pricing-feature-list li {
  font-size: 14px;
  line-height: 30px;
}

/* line 79, src/assets/scss/components/price-section/_price-1.scss */
.single-pricing-pack .pricing-content .pricing-feature-list li span {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #434343;
}

/* line 86, src/assets/scss/components/price-section/_price-1.scss */
.single-pricing-pack .pricing-content .pricing-feature-list li.text-deem {
  opacity: 0.5;
}

/* line 91, src/assets/scss/components/price-section/_price-1.scss */
.single-pricing-pack.popular-price {
  -webkit-box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125) !important;
  box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125) !important;
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
  border-top: 4px solid #0c2888 !important;
}

/* line 96, src/assets/scss/components/price-section/_price-1.scss */
.single-pricing-pack:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125) !important;
  box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125) !important;
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
  border-top: 4px solid #0c2888;
}

/* line 103, src/assets/scss/components/price-section/_price-1.scss */
.pricing-header .price {
  font-size: 40px;
  line-height: 45px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

/* line 108, src/assets/scss/components/price-section/_price-1.scss */
.pricing-header .price span {
  font-size: 20px;
}

/* line 113, src/assets/scss/components/price-section/_price-1.scss */
.pricing-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

/* line 116, src/assets/scss/components/price-section/_price-1.scss */
.pricing-content .pricing-feature-list li {
  font-size: 14px;
  line-height: 30px;
}

/* line 119, src/assets/scss/components/price-section/_price-1.scss */
.pricing-content .pricing-feature-list li span {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #434343;
}

/* line 126, src/assets/scss/components/price-section/_price-1.scss */
.pricing-content .pricing-feature-list li.text-deem {
  opacity: 0.5;
}

/* line 134, src/assets/scss/components/price-section/_price-1.scss */
.pricing-new-plan .price-name p {
  opacity: 0.7;
}

/* line 4, src/assets/scss/components/faq-section/_common.scss */
.gray-light-bg .faq-wrap .card .card-body {
  background: #fff !important;
}

/* line 4, src/assets/scss/components/faq-section/faq-1.scss */
.faq-wrap .card {
  border: 0;
}

/* line 6, src/assets/scss/components/faq-section/faq-1.scss */
.faq-wrap .card .card-header {
  padding: 0.95rem 1.25rem;
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px 4px 0 0;
  background: linear-gradient(75deg, #0c2888, #6294f7);
  margin-bottom: 0;
  border: 1px solid rgba(72, 94, 144, 0.16);
}

/* line 14, src/assets/scss/components/faq-section/faq-1.scss */
.faq-wrap .card .card-header h6 {
  color: #fff;
}

/* line 17, src/assets/scss/components/faq-section/faq-1.scss */
.faq-wrap .card .card-header.collapsed {
  background: transparent;
}

/* line 19, src/assets/scss/components/faq-section/faq-1.scss */
.faq-wrap .card .card-header.collapsed h6 {
  color: initial;
}

/* line 22, src/assets/scss/components/faq-section/faq-1.scss */
.faq-wrap .card .card-header.collapsed:after {
  content: "\f107";
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: initial;
}

/* line 28, src/assets/scss/components/faq-section/faq-1.scss */
.faq-wrap .card .card-header:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: initial;
  content: "\f106";
  float: right;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 30px;
  text-align: center;
  /*background: #6294f7;*/
  border-radius: 4px;
}

/* line 41, src/assets/scss/components/faq-section/faq-1.scss */
.faq-wrap .card .card-body {
  background: #f5f5f5;
  border-radius: 0 0 4px 4px;
}

/* line 53, src/assets/scss/components/faq-section/faq-1.scss */
.single-faq h5 span {
  font-size: 22px;
  font-weight: 700;
}

/* line 1, src/assets/scss/components/team-section/_team-1.scss */
.progress {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  height: 12px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e5e9f2;
  border-radius: 30px;
}

/* line 11, src/assets/scss/components/team-section/_team-1.scss */
.progress-bar {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0c2888;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

/* line 24, src/assets/scss/components/team-section/_team-1.scss */
.progress-item + .progress-item {
  margin-top: 24px;
}

/* line 27, src/assets/scss/components/team-section/_team-1.scss */
.progress-item {
  margin-bottom: 20px;
}

/* line 30, src/assets/scss/components/team-section/_team-1.scss */
.progress-title > h6 {
  font-size: 0.95375rem;
  margin-bottom: 8px;
  font-weight: 500;
}

/*progressbar animation*/
/* line 39, src/assets/scss/components/team-section/_team-1.scss */
.progress span {
  display: block;
  height: 100%;
}

/* line 44, src/assets/scss/components/team-section/_team-1.scss */
.progress-line {
  background-color: #0c2888;
  -webkit-animation: progressBar 3s ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation: progressBar 3s ease-in-out;
  -moz-animation-fill-mode: both;
}

@-webkit-keyframes progressBar {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

/* line 2, src/assets/scss/components/team-section/_team-new.scss */
.single-team-wrap .team-img {
  top: -23%;
  left: 10%;
}

/* line 7, src/assets/scss/components/team-section/_team-new.scss */
.team-content-wrap {
  margin: 3rem 0;
}

/* line 11, src/assets/scss/components/team-section/_team-new.scss */
.name-designation span {
  font-size: 14px;
}

@media (min-width: 990px) and (max-width: 1199px) {
  /* line 17, src/assets/scss/components/team-section/_team-new.scss */
  .single-team-wrap .team-img {
    top: -2px;
    left: 0;
  }

  /* line 22, src/assets/scss/components/team-section/_team-new.scss */
  .team-content-wrap {
    margin: 1rem 0;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  /* line 28, src/assets/scss/components/team-section/_team-new.scss */
  .single-team-wrap .team-img {
    top: 5%;
    left: 15%;
  }

  /* line 33, src/assets/scss/components/team-section/_team-new.scss */
  .team-content-wrap {
    margin: 0.8rem 0;
  }
}

/* line 1, src/assets/scss/components/team-section/_common-rtl.scss */
.single-team-wrap .team-img {
  top: -12%;
  right: 10%;
}

@media (min-width: 990px) and (max-width: 1199px) {
  /* line 8, src/assets/scss/components/team-section/_common-rtl.scss */
  .single-team-wrap .team-img {
    top: 28px;
    left: 0;
  }

  /* line 13, src/assets/scss/components/team-section/_common-rtl.scss */
  .team-content-wrap {
    margin: 1rem 0;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  /* line 19, src/assets/scss/components/team-section/_common-rtl.scss */
  .single-team-wrap .team-img {
    top: 5%;
    left: 15%;
  }

  /* line 24, src/assets/scss/components/team-section/_common-rtl.scss */
  .team-content-wrap {
    margin: 0.8rem 0;
  }
}

/* line 3, src/assets/scss/components/blog-section/_blog-1.scss */
.single-blog-card .blog-img img {
  border-bottom: 3px solid #ec6e7b;
}

/* line 6, src/assets/scss/components/blog-section/_blog-1.scss */
.single-blog-card .blog-img .meta-date {
  position: absolute;
  right: 20px;
  width: 55px;
  height: 55px;
  background: #ec6e7b;
  border: 3px solid #ec6e7b;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  bottom: -22px;
}

/* line 17, src/assets/scss/components/blog-section/_blog-1.scss */
.single-blog-card .blog-img .meta-date strong {
  display: block;
  line-height: 4px;
  margin-top: 16px;
}

/* line 24, src/assets/scss/components/blog-section/_blog-1.scss */
.single-blog-card .card-body {
  padding: 1.75rem;
}

/* line 26, src/assets/scss/components/blog-section/_blog-1.scss */
.single-blog-card .card-body .post-meta {
  list-style: none;
  font-size: 0.8125rem;
  padding: 0;
  margin: 0;
}

/* line 31, src/assets/scss/components/blog-section/_blog-1.scss */
.single-blog-card .card-body .post-meta .meta-list {
  opacity: 0.8;
  font-size: 95%;
}

/* line 35, src/assets/scss/components/blog-section/_blog-1.scss */
.single-blog-card .card-body .post-meta .meta-list li i {
  color: #0c2888;
}

/* line 41, src/assets/scss/components/blog-section/_blog-1.scss */
.single-blog-card .card-body a {
  font-size: 16px;
  font-weight: 600;
  color: #696969;
  font-family: "Montserrat", sans-serif;
}

/* line 46, src/assets/scss/components/blog-section/_blog-1.scss */
.single-blog-card .card-body a:hover {
  color: #0c2888;
}

/* line 50, src/assets/scss/components/blog-section/_blog-1.scss */
.single-blog-card .card-body a.detail-link {
  display: inline-block;
  font-size: 14px;
}

/* line 53, src/assets/scss/components/blog-section/_blog-1.scss */
.single-blog-card .card-body a.detail-link span {
  font-size: 12px;
  vertical-align: middle;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: #0c2888;
}

/* line 60, src/assets/scss/components/blog-section/_blog-1.scss */
.single-blog-card .card-body a.detail-link:hover span {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
  margin-left: 8px;
}

/* line 1, src/assets/scss/components/blog-section/_blog-single.scss */
.row-post-thumbnail .post {
  border: 0;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

/* line 6, src/assets/scss/components/blog-section/_blog-single.scss */
.post {
  border-bottom: 1px solid #f0f0f0;
  padding: 0 0 35px;
  margin: 0 0 45px;
}

/* line 12, src/assets/scss/components/blog-section/_blog-single.scss */
.post-preview {
  padding-bottom: 5px;
  border-radius: 0.1875rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* line 18, src/assets/scss/components/blog-section/_blog-single.scss */
.post-preview img {
  border-radius: 0.1875rem;
  width: 100%;
  display: block;
}

/* line 24, src/assets/scss/components/blog-section/_blog-single.scss */
.post-header {
  margin: 15px 0 20px;
}

/* line 27, src/assets/scss/components/blog-section/_blog-single.scss */
.post-header .post-title {
  font-size: 1.5625rem;
  margin-bottom: 0;
  color: #333333;
}

/* line 32, src/assets/scss/components/blog-section/_blog-single.scss */
.post-header .post-title a {
  color: #5d5d5d;
}

/* line 35, src/assets/scss/components/blog-section/_blog-single.scss */
.post-header .post-title a:hover {
  color: #0c2888;
}

/* line 38, src/assets/scss/components/blog-section/_blog-single.scss */
.post-meta {
  list-style: none;
  font-size: 0.8125rem;
  padding: 0;
  margin: 0;
}

/* line 44, src/assets/scss/components/blog-section/_blog-single.scss */
.post-meta a,
.post-meta li {
  color: #495057;
  opacity: 0.8;
}

/* line 48, src/assets/scss/components/blog-section/_blog-single.scss */
.post-meta li i {
  color: #0c2888;
}

/* line 51, src/assets/scss/components/blog-section/_blog-single.scss */
.post-meta a:hover {
  color: #0c2888;
  opacity: 1;
}

/* line 55, src/assets/scss/components/blog-section/_blog-single.scss */
.post-meta > li {
  position: relative;
  display: inline-block;
  padding: 0 14px 0 0;
  margin: 0 14px 0 0;
}

/* line 62, src/assets/scss/components/blog-section/_blog-single.scss */
.post-meta > li:not(:last-child):after {
  position: absolute;
  content: "/";
  right: -5px;
  top: 0;
}

/* line 69, src/assets/scss/components/blog-section/_blog-single.scss */
.post-content p {
  margin-bottom: 5px;
}

/* line 72, src/assets/scss/components/blog-section/_blog-single.scss */
.post-more a span {
  vertical-align: middle;
}

/* line 76, src/assets/scss/components/blog-section/_blog-single.scss */
.post-footer {
  margin-top: 15px;
}

/*blog single css*/
/* line 81, src/assets/scss/components/blog-section/_blog-single.scss */
.post-content blockquote {
  border-left: 2px solid #eaeaea;
  padding: 10px 0 10px 40px;
  margin: 40px 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* line 87, src/assets/scss/components/blog-section/_blog-single.scss */
.post-content blockquote:hover {
  border-color: #0c2888;
}

/* line 90, src/assets/scss/components/blog-section/_blog-single.scss */
.blockquote {
  font-weight: 500;
  color: #222;
}

/* line 94, src/assets/scss/components/blog-section/_blog-single.scss */
.post-content ol {
  list-style-type: decimal;
}

/* line 97, src/assets/scss/components/blog-section/_blog-single.scss */
.post-content ul,
.post-content ol {
  margin: 15px 0 15px 40px;
}

/*comments css*/
/* line 102, src/assets/scss/components/blog-section/_blog-single.scss */
.comment,
.comment-shop {
  border-bottom: 1px solid #f0f0f0;
  padding: 30px 0 0;
}

/* line 106, src/assets/scss/components/blog-section/_blog-single.scss */
.comment-author,
.comment-author-shop {
  margin: 5px 0 0;
  height: 64px;
  width: 64px;
  float: left;
}

/* line 113, src/assets/scss/components/blog-section/_blog-single.scss */
.comment-body,
.comment-body-shop {
  position: relative;
  padding: 0 0 30px;
  margin: 0 0 0 85px;
}

/* line 118, src/assets/scss/components/blog-section/_blog-single.scss */
.comment-meta,
.comment-meta-shop {
  margin: 0 0 15px;
}

/* line 121, src/assets/scss/components/blog-section/_blog-single.scss */
.comment-meta-date {
  font-size: 13px;
}

/* line 124, src/assets/scss/components/blog-section/_blog-single.scss */
.children > .comment,
.children > .comment-shop {
  border: 0;
  border-top: 1px solid #f0f0f0;
  margin-left: 85px;
}

/* line 129, src/assets/scss/components/blog-section/_blog-single.scss */
.comment-meta-author a,
.comment-meta-author-shop a {
  font-weight: 500;
  color: #222;
}

/* line 133, src/assets/scss/components/blog-section/_blog-single.scss */
.comment-meta-date a,
.comment-meta-date-shop a {
  color: #788487;
  opacity: 0.6;
}

/* line 137, src/assets/scss/components/blog-section/_blog-single.scss */
.comment-meta-date a:hover,
.comment-meta-date a:focus,
.comment-meta-date-shop a:hover,
.comment-meta-date-shop a:focus {
  color: #0c2888;
  opacity: 1;
}

/* line 142, src/assets/scss/components/blog-section/_blog-single.scss */
.comment-reply,
.comment-reply-shop {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 13px;
}

/* line 149, src/assets/scss/components/blog-section/_blog-single.scss */
.comment-reply > a,
.comment-reply-shop > a {
  background: #f4f4f4;
  border-radius: 0.1875rem;
  padding: 5px 15px;
  display: block;
  color: #222;
}

/* line 156, src/assets/scss/components/blog-section/_blog-single.scss */
.comment-reply > a:hover,
.comment-reply > a:focus,
.comment-reply-shop > a:hover,
.comment-reply-shop > a:focus {
  background: #0c2888;
  color: #fff;
}

/* line 161, src/assets/scss/components/blog-section/_blog-single.scss */
.comment-list + .comment-respond,
.comment-list-shop + .comment-respond {
  margin-top: 30px;
}

/*blog sidebar*/
/* line 169, src/assets/scss/components/blog-section/_blog-single.scss */
.widget-search {
  padding: 0;
  border: 0;
}

/* line 173, src/assets/scss/components/blog-section/_blog-single.scss */
.widget {
  margin-bottom: 50px;
}

/* line 176, src/assets/scss/components/blog-section/_blog-single.scss */
.widget-search form {
  position: relative;
}

/* line 180, src/assets/scss/components/blog-section/_blog-single.scss */
.widget-search .search-button {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 50%;
  height: 100%;
  right: 0.875rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  color: #ddd;
}

/* line 191, src/assets/scss/components/blog-section/_blog-single.scss */
.widget-search .search-button span {
  font-size: 20px;
  line-height: 32px;
}

/* line 195, src/assets/scss/components/blog-section/_blog-single.scss */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/* line 198, src/assets/scss/components/blog-section/_blog-single.scss */
.widget .widget-title {
  margin-bottom: 24px;
}

/* line 202, src/assets/scss/components/blog-section/_blog-single.scss */
.widget .widget-title > h6 {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  color: #788487;
}

/* line 211, src/assets/scss/components/blog-section/_blog-single.scss */
.widget ul {
  margin-bottom: 0;
}

/* line 214, src/assets/scss/components/blog-section/_blog-single.scss */
.widget-categories ul li:first-child,
.widget-recent-entries ul li:first-child,
.widget-recent-entries-custom ul li:first-child {
  padding-top: 0;
}

/* line 217, src/assets/scss/components/blog-section/_blog-single.scss */
.widget-categories ul li,
.widget-recent-entries ul li,
.widget-recent-entries-custom ul li {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 0;
  margin: 0;
}

/* line 222, src/assets/scss/components/blog-section/_blog-single.scss */
.widget a {
  color: #788487;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: left;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* line 234, src/assets/scss/components/blog-section/_blog-single.scss */
.widget a:hover {
  color: #0c2888;
}

/* line 237, src/assets/scss/components/blog-section/_blog-single.scss */
.widget-recent-entries-custom .wi {
  float: left;
  width: 100px;
}

/* line 241, src/assets/scss/components/blog-section/_blog-single.scss */
.widget-recent-entries-custom .wb {
  padding-left: 16px;
  width: 100%;
}

/* line 245, src/assets/scss/components/blog-section/_blog-single.scss */
.widget-recent-entries-custom .wi,
.widget-recent-entries-custom .wb {
  vertical-align: middle;
  display: table-cell;
}

/* line 249, src/assets/scss/components/blog-section/_blog-single.scss */
.post-date {
  font-size: 12px;
  color: #a3a6a8;
  display: block;
  margin: 0;
}

/* line 255, src/assets/scss/components/blog-section/_blog-single.scss */
.tag-cloud > a,
.post-tags > a {
  background: #f5f5f5;
  border-radius: 0.1875rem;
  position: relative;
  display: inline-block;
  padding: 6px 15px;
  margin: 5px 8px 5px 0;
  text-transform: uppercase;
  font-size: 10px !important;
  color: #222;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

/* line 268, src/assets/scss/components/blog-section/_blog-single.scss */
.tag-cloud > a:hover,
.post-tags > a:hover {
  background: #0c2888;
  color: #fff;
  opacity: 1;
}

/* line 3, src/assets/scss/components/blog-section/_blog-pagination.scss */
.custom-pagination-nav ul li {
  text-align: center;
  margin-right: 10px;
}

/* line 6, src/assets/scss/components/blog-section/_blog-pagination.scss */
.custom-pagination-nav ul li:focus,
.custom-pagination-nav ul li a:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 10, src/assets/scss/components/blog-section/_blog-pagination.scss */
.custom-pagination-nav ul li a.page-link {
  width: 35px;
  height: 35px;
  line-height: 33px;
  border-radius: 100%;
  padding: inherit;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #0c2888;
}

/* line 19, src/assets/scss/components/blog-section/_blog-pagination.scss */
.custom-pagination-nav ul li a.page-link:hover {
  background: #0c2888;
  color: #fff;
}

/* line 24, src/assets/scss/components/blog-section/_blog-pagination.scss */
.custom-pagination-nav ul li.page-item:first-child .page-link,
.custom-pagination-nav ul li.page-item:last-child .page-link {
  border: none;
  background: transparent;
}

/* line 27, src/assets/scss/components/blog-section/_blog-pagination.scss */
.custom-pagination-nav ul li.page-item:first-child .page-link:hover,
.custom-pagination-nav ul li.page-item:last-child .page-link:hover {
  color: #0c2888;
}

/* line 31, src/assets/scss/components/blog-section/_blog-pagination.scss */
.custom-pagination-nav ul li.page-item.active .page-link {
  background: #0c2888;
  border-color: #0c2888;
}

/* line 5, src/assets/scss/components/contact-section/_common.scss */
.login-signup-form .form-group {
  position: relative;
}

/* line 7, src/assets/scss/components/contact-section/_common.scss */
.login-signup-form .form-group label {
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0;
}

/* line 14, src/assets/scss/components/contact-section/_common.scss */
.login-signup-form .form-group .input-group .input-icon {
  position: absolute;
  z-index: 4;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
}

/* line 21, src/assets/scss/components/contact-section/_common.scss */
.login-signup-form .form-group .input-group .input-icon span {
  padding: 16px;
  position: absolute;
  color: #8392a5;
}

/* line 28, src/assets/scss/components/contact-section/_common.scss */
.login-signup-form .form-group .input-group .textarea-icon span {
  padding: 11px 16px;
}

/* line 32, src/assets/scss/components/contact-section/_common.scss */
.login-signup-form .form-group .input-group .form-control {
  text-indent: 32px;
}

/* line 34, src/assets/scss/components/contact-section/_common.scss */
.login-signup-form .form-group .input-group .form-control:focus {
  outline: none;
  border-color: #c0ccda;
  z-index: 0;
}

/* line 39, src/assets/scss/components/contact-section/_common.scss */
.login-signup-form .form-group .input-group .form-control:not(:first-child) {
  border-radius: 4px;
}

/* line 48, src/assets/scss/components/contact-section/_common.scss */
input::-webkit-input-placeholder {
  position: relative;
  top: -1.6px;
}

/* line 53, src/assets/scss/components/contact-section/_common.scss */
input:-ms-input-placeholder {
  position: relative;
  top: -1.6px;
}

/* line 58, src/assets/scss/components/contact-section/_common.scss */
input::-ms-input-placeholder {
  position: relative;
  top: -1.6px;
}

/* line 63, src/assets/scss/components/contact-section/_common.scss */
input::-webkit-input-placeholder {
  position: relative;
  top: -1.6px;
}

input::-moz-placeholder {
  position: relative;
  top: -1.6px;
}

input:-ms-input-placeholder {
  position: relative;
  top: -1.6px;
}

input::-ms-input-placeholder {
  position: relative;
  top: -1.6px;
}

input::placeholder {
  position: relative;
  top: -1.6px;
}

/* line 5, src/assets/scss/components/contact-section/_contact-1.scss */
.contact-info-list li .contact-icon span {
  border: 1px solid #0c2888;
}

/* line 14, src/assets/scss/components/contact-section/_contact-1.scss */
.google-map {
  height: 100%;
  margin-bottom: -8px;
}

/* line 17, src/assets/scss/components/contact-section/_contact-1.scss */
.google-map iframe {
  width: 100%;
  border: 0;
  -webkit-filter: grayscale(99%);
  -webkit-backface-visibility: hidden;
}

/* line 1, src/assets/scss/components/contact-section/_login-signup.scss */
.full-height {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 15px;
}

/* line 15, src/assets/scss/components/contact-section/_login-signup.scss */
.other-login-signup {
  text-align: center;
  overflow: hidden;
}

/* line 18, src/assets/scss/components/contact-section/_login-signup.scss */
.other-login-signup .or-login-signup {
  text-align: center;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}

/* line 24, src/assets/scss/components/contact-section/_login-signup.scss */
.other-login-signup .or-login-signup:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #e5e9f2;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}

/* line 38, src/assets/scss/components/contact-section/_login-signup.scss */
.other-login-signup .or-login-signup:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #e5e9f2;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}

@media (min-width: 320px) and (max-width: 575px) {
  /* line 59, src/assets/scss/components/contact-section/_login-signup.scss */
  .login-signup-wrap.p-5 {
    padding: 1.5rem !important;
  }

  /* line 64, src/assets/scss/components/contact-section/_login-signup.scss */
  .social-login-signup li a.btn {
    margin-bottom: 5px;
  }
}

/*customer section css*/
/* line 2, src/assets/scss/components/customer-section/_customer-1.scss */
.single-customer {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-column-align: center;
  justify-items: center;
  width: 60%;
  height: auto;
  -webkit-transition: -webkit-transform 0.5s ease-in;
  transition: -webkit-transform 0.5s ease-in;
  transition: transform 0.5s ease-in;
  transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
  transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
  margin: 8px auto;
}

/* line 17, src/assets/scss/components/customer-section/_customer-1.scss */
.single-customer img {
  max-width: 100%;
  max-height: 100%;
  -webkit-filter: opacity(50%);
  /* Safari 6.0 - 9.0 */
  filter: opacity(90%);
  width: 100%;
}

/* line 25, src/assets/scss/components/customer-section/_customer-1.scss */
.single-customer:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/* line 29, src/assets/scss/components/customer-section/_customer-1.scss */
.single-customer:hover.customer-logo {
  -webkit-filter: none;
  filter: none;
  opacity: 1;
}

/* line 36, src/assets/scss/components/customer-section/_customer-1.scss */
.customer-slider-wrap {
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

/* line 41, src/assets/scss/components/customer-section/_customer-1.scss */
.customer-slider-wrap .shape-left:before {
  position: absolute;
  left: -80px;
  width: 200px;
  height: 200px;
  background: #0c2888;
  content: "";
  top: -100px;
  border-radius: 100%;
}

/* line 51, src/assets/scss/components/customer-section/_customer-1.scss */
.customer-slider-wrap .shape-left:after {
  position: absolute;
  left: 35%;
  width: 70px;
  height: 70px;
  background: #0c2888;
  content: "";
  bottom: -40px;
  border-radius: 100%;
  opacity: 0.3;
}

/* line 64, src/assets/scss/components/customer-section/_customer-1.scss */
.customer-slider-wrap .shape-right:before {
  position: absolute;
  right: 35%;
  width: 70px;
  height: 70px;
  background: #0c2888;
  content: "";
  top: -40px;
  border-radius: 100%;
  opacity: 0.3;
}

/* line 75, src/assets/scss/components/customer-section/_customer-1.scss */
.customer-slider-wrap .shape-right:after {
  position: absolute;
  right: -108px;
  width: 200px;
  height: 200px;
  background: #0c2888;
  content: "";
  bottom: -100px;
  border-radius: 100%;
}

/* line 88, src/assets/scss/components/customer-section/_customer-1.scss */
.mt--80 {
  margin-top: -80px;
}

/* line 2, src/assets/scss/components/single-pages/_404-page.scss */
.error-content-wrap .notfound-404 {
  position: relative;
  height: 280px;
  z-index: 2;
  margin-bottom: 30px;
}

/* line 7, src/assets/scss/components/single-pages/_404-page.scss */
.error-content-wrap .notfound-404 h1 {
  font-size: 230px;
  font-weight: 700;
  margin: 0;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-size: cover;
  background-position: center;
}

/* line 1, src/assets/scss/components/single-pages/_coming-soon.scss */
.subscribe-form input.button {
  position: absolute;
  right: 8px;
  border-radius: 30px;
  padding: initial;
  top: 8px;
  bottom: 8px;
  width: 30%;
}

/* line 13, src/assets/scss/components/single-pages/_coming-soon.scss */
.hero-countdown-wrap h2,
.hero-countdown-wrap h5 {
  color: #fff !important;
}

/* line 6, src/assets/scss/components/utility/_common.scss */
.countdown-wrap .row .col {
  margin: 10px;
  text-align: center;
  padding: 15px;
  background: #fff;
  border-radius: 1rem;
}

/* line 12, src/assets/scss/components/utility/_common.scss */
.countdown-wrap .row .col h2 {
  font-weight: 700;
  margin-bottom: 5px;
}

/* line 16, src/assets/scss/components/utility/_common.scss */
.countdown-wrap .row .col h2,
.countdown-wrap .row .col h5 {
  color: #0c2888 !important;
}

/* line 24, src/assets/scss/components/utility/_common.scss */
.countdown-wrap-2 .row .col {
  text-align: center;
  padding: 8px;
}

/* line 27, src/assets/scss/components/utility/_common.scss */
.countdown-wrap-2 .row .col h2 {
  font-weight: 700;
  margin-bottom: 5px;
}

/* line 31, src/assets/scss/components/utility/_common.scss */
.countdown-wrap-2 .row .col h5 {
  font-weight: 400;
}

/* line 34, src/assets/scss/components/utility/_common.scss */
.countdown-wrap-2 .row .col h2,
.countdown-wrap-2 .row .col h5 {
  color: #fff !important;
}

/* line 3, node_modules/bootstrap/scss/utilities/_align.scss */
.align-baseline {
  vertical-align: baseline !important;
}

/* line 4, node_modules/bootstrap/scss/utilities/_align.scss */
.align-top {
  vertical-align: top !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_align.scss */
.align-middle {
  vertical-align: middle !important;
}

/* line 6, node_modules/bootstrap/scss/utilities/_align.scss */
.align-bottom {
  vertical-align: bottom !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_align.scss */
.align-text-bottom {
  vertical-align: text-bottom !important;
}

/* line 8, node_modules/bootstrap/scss/utilities/_align.scss */
.align-text-top {
  vertical-align: text-top !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-primary {
  background-color: #007bff !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-secondary {
  background-color: #6c757d !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-success {
  background-color: #28a745 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-info {
  background-color: #17a2b8 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-warning {
  background-color: #ffc107 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-danger {
  background-color: #dc3545 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-light {
  background-color: #f8f9fa !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-dark {
  background-color: #343a40 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

/* line 13, node_modules/bootstrap/scss/utilities/_background.scss */
.bg-white {
  background-color: #fff !important;
}

/* line 17, node_modules/bootstrap/scss/utilities/_background.scss */
.bg-transparent {
  background-color: transparent !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_borders.scss */
.border {
  border: 1px solid rgba(72, 94, 144, 0.16) !important;
}

/* line 8, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-top {
  border-top: 1px solid rgba(72, 94, 144, 0.16) !important;
}

/* line 9, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-right {
  border-right: 1px solid rgba(72, 94, 144, 0.16) !important;
}

/* line 10, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-bottom {
  border-bottom: 1px solid rgba(72, 94, 144, 0.16) !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-left {
  border-left: 1px solid rgba(72, 94, 144, 0.16) !important;
}

/* line 13, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-0 {
  border: 0 !important;
}

/* line 14, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-top-0 {
  border-top: 0 !important;
}

/* line 15, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-right-0 {
  border-right: 0 !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-bottom-0 {
  border-bottom: 0 !important;
}

/* line 17, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-left-0 {
  border-left: 0 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-primary {
  border-color: #007bff !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-secondary {
  border-color: #6c757d !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-success {
  border-color: #28a745 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-info {
  border-color: #17a2b8 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-warning {
  border-color: #ffc107 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-danger {
  border-color: #dc3545 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-light {
  border-color: #f8f9fa !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-dark {
  border-color: #343a40 !important;
}

/* line 25, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-white {
  border-color: #fff !important;
}

/* line 33, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-sm {
  border-radius: 0.2rem !important;
}

/* line 37, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded {
  border-radius: 0.25rem !important;
}

/* line 41, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

/* line 46, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

/* line 51, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

/* line 56, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

/* line 61, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-lg {
  border-radius: 0.3rem !important;
}

/* line 65, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-circle {
  border-radius: 50% !important;
}

/* line 69, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-pill {
  border-radius: 50rem !important;
}

/* line 73, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-0 {
  border-radius: 0 !important;
}

/* line 2, node_modules/bootstrap/scss/mixins/_clearfix.scss */
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-none {
  display: none !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline {
  display: inline !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline-block {
  display: inline-block !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-block {
  display: block !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-table {
  display: table !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-table-row {
  display: table-row !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-table-cell {
  display: table-cell !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-none {
    display: none !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline {
    display: inline !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline-block {
    display: inline-block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-block {
    display: block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table {
    display: table !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table-row {
    display: table-row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table-cell {
    display: table-cell !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-none {
    display: none !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline {
    display: inline !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline-block {
    display: inline-block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-block {
    display: block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table {
    display: table !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table-row {
    display: table-row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table-cell {
    display: table-cell !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-none {
    display: none !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline {
    display: inline !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline-block {
    display: inline-block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-block {
    display: block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table {
    display: table !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table-row {
    display: table-row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table-cell {
    display: table-cell !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-none {
    display: none !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline {
    display: inline !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline-block {
    display: inline-block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-block {
    display: block !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table {
    display: table !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table-row {
    display: table-row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table-cell {
    display: table-cell !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-none {
    display: none !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline {
    display: inline !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline-block {
    display: inline-block !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-block {
    display: block !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table {
    display: table !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table-row {
    display: table-row !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table-cell {
    display: table-cell !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

/* line 3, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

/* line 10, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive::before {
  display: block;
  content: "";
}

/* line 15, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%;
}

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%;
}

/* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

/* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

/* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

/* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

/* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

/* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

/* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

/* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

/* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

/* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

/* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

/* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

/* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

/* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

/* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

/* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

/* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

/* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

/* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

/* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

/* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

/* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

/* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

/* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

/* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

/* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

/* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

/* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

/* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

/* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

/* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
.float-left {
  float: left !important;
}

/* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
.float-right {
  float: right !important;
}

/* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-left {
    float: left !important;
  }

  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-right {
    float: right !important;
  }

  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-left {
    float: left !important;
  }

  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-right {
    float: right !important;
  }

  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-left {
    float: left !important;
  }

  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-right {
    float: right !important;
  }

  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-left {
    float: left !important;
  }

  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-right {
    float: right !important;
  }

  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-none {
    float: none !important;
  }
}

/* line 4, node_modules/bootstrap/scss/utilities/_interactions.scss */
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

/* line 4, node_modules/bootstrap/scss/utilities/_interactions.scss */
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

/* line 4, node_modules/bootstrap/scss/utilities/_interactions.scss */
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* line 4, node_modules/bootstrap/scss/utilities/_overflow.scss */
.overflow-auto {
  overflow: auto !important;
}

/* line 4, node_modules/bootstrap/scss/utilities/_overflow.scss */
.overflow-hidden {
  overflow: hidden !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-static {
  position: static !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-relative {
  position: relative !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-absolute {
  position: absolute !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-fixed {
  position: fixed !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-sticky {
  position: sticky !important;
}

/* line 10, node_modules/bootstrap/scss/utilities/_position.scss */
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

/* line 18, node_modules/bootstrap/scss/utilities/_position.scss */
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  /* line 26, node_modules/bootstrap/scss/utilities/_position.scss */
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

/* line 5, node_modules/bootstrap/scss/utilities/_screenreaders.scss */
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* line 47, node_modules/@fortawesome/fontawesome-free/scss/_mixins.scss */
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* line 3, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

/* line 4, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

/* line 5, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

/* line 6, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-25 {
  width: 25% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-50 {
  width: 50% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-75 {
  width: 75% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-100 {
  width: 100% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-auto {
  width: auto !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-25 {
  height: 25% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-50 {
  height: 50% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-75 {
  height: 75% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-100 {
  height: 100% !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-auto {
  height: auto !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_sizing.scss */
.mw-100 {
  max-width: 100% !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_sizing.scss */
.mh-100 {
  max-height: 100% !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_sizing.scss */
.min-vw-100 {
  min-width: 100vw !important;
}

/* line 17, node_modules/bootstrap/scss/utilities/_sizing.scss */
.min-vh-100 {
  min-height: 100vh !important;
}

/* line 19, node_modules/bootstrap/scss/utilities/_sizing.scss */
.vw-100 {
  width: 100vw !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_sizing.scss */
.vh-100 {
  height: 100vh !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-0 {
  margin: 0 !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-0,
.my-0 {
  margin-top: 0 !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-1 {
  margin: 0.25rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-2 {
  margin: 0.5rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-3 {
  margin: 1rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-4 {
  margin: 1.5rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-5 {
  margin: 3rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-0 {
  padding: 0 !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-0,
.py-0 {
  padding-top: 0 !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-0,
.px-0 {
  padding-right: 0 !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-0,
.px-0 {
  padding-left: 0 !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-1 {
  padding: 0.25rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-2 {
  padding: 0.5rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-3 {
  padding: 1rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-4 {
  padding: 1.5rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-5 {
  padding: 3rem !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n1 {
  margin: -0.25rem !important;
}

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n2 {
  margin: -0.5rem !important;
}

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n3 {
  margin: -1rem !important;
}

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n4 {
  margin: -1.5rem !important;
}

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n5 {
  margin: -3rem !important;
}

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

/* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-auto {
  margin: auto !important;
}

/* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-auto,
.my-auto {
  margin-top: auto !important;
}

/* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

/* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

/* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-0 {
    margin: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-1 {
    margin: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-2 {
    margin: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-3 {
    margin: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-4 {
    margin: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-5 {
    margin: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-0 {
    padding: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-1 {
    padding: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-2 {
    padding: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-3 {
    padding: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-4 {
    padding: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-5 {
    padding: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n3 {
    margin: -1rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n5 {
    margin: -3rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-auto {
    margin: auto !important;
  }

  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-0 {
    margin: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-1 {
    margin: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-2 {
    margin: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-3 {
    margin: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-4 {
    margin: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-5 {
    margin: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-0 {
    padding: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-1 {
    padding: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-2 {
    padding: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-3 {
    padding: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-4 {
    padding: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-5 {
    padding: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n1 {
    margin: -0.25rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n2 {
    margin: -0.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n3 {
    margin: -1rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n4 {
    margin: -1.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n5 {
    margin: -3rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-auto {
    margin: auto !important;
  }

  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-0 {
    margin: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-1 {
    margin: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-2 {
    margin: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-3 {
    margin: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-4 {
    margin: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-5 {
    margin: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-0 {
    padding: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-1 {
    padding: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-2 {
    padding: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-3 {
    padding: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-4 {
    padding: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-5 {
    padding: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n3 {
    margin: -1rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n5 {
    margin: -3rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-auto {
    margin: auto !important;
  }

  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-0 {
    margin: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-1 {
    margin: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-2 {
    margin: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-3 {
    margin: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-4 {
    margin: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-5 {
    margin: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-0 {
    padding: 0 !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-1 {
    padding: 0.25rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-2 {
    padding: 0.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-3 {
    padding: 1rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-4 {
    padding: 1.5rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-5 {
    padding: 3rem !important;
  }

  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n3 {
    margin: -1rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n5 {
    margin: -3rem !important;
  }

  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-auto {
    margin: auto !important;
  }

  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

/* line 6, node_modules/bootstrap/scss/utilities/_stretched-link.scss */
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

/* line 7, node_modules/bootstrap/scss/utilities/_text.scss */
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_text.scss */
.text-justify {
  text-align: justify !important;
}

/* line 12, node_modules/bootstrap/scss/utilities/_text.scss */
.text-wrap {
  white-space: normal !important;
}

/* line 13, node_modules/bootstrap/scss/utilities/_text.scss */
.text-nowrap {
  white-space: nowrap !important;
}

/* line 14, node_modules/bootstrap/scss/utilities/_text.scss */
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
.text-left {
  text-align: left !important;
}

/* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
.text-right {
  text-align: right !important;
}

/* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-left {
    text-align: left !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-right {
    text-align: right !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-left {
    text-align: left !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-right {
    text-align: right !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-left {
    text-align: left !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-right {
    text-align: right !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-left {
    text-align: left !important;
  }

  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-right {
    text-align: right !important;
  }

  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-center {
    text-align: center !important;
  }
}

/* line 30, node_modules/bootstrap/scss/utilities/_text.scss */
.text-lowercase {
  text-transform: lowercase !important;
}

/* line 31, node_modules/bootstrap/scss/utilities/_text.scss */
.text-uppercase {
  text-transform: uppercase !important;
}

/* line 32, node_modules/bootstrap/scss/utilities/_text.scss */
.text-capitalize {
  text-transform: capitalize !important;
}

/* line 36, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-light {
  font-weight: 300 !important;
}

/* line 37, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-lighter {
  font-weight: lighter !important;
}

/* line 38, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-normal {
  font-weight: 400 !important;
}

/* line 39, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-bold {
  font-weight: 700 !important;
}

/* line 40, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-bolder {
  font-weight: bolder !important;
}

/* line 41, node_modules/bootstrap/scss/utilities/_text.scss */
.font-italic {
  font-style: italic !important;
}

/* line 45, node_modules/bootstrap/scss/utilities/_text.scss */
.text-white {
  color: #fff !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-primary {
  color: #007bff !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-primary:hover,
a.text-primary:focus {
  color: #0056b3 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-secondary {
  color: #6c757d !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-success {
  color: #28a745 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-success:hover,
a.text-success:focus {
  color: #19692c !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-info {
  color: #17a2b8 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-warning {
  color: #ffc107 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-danger {
  color: #dc3545 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-light {
  color: #f8f9fa !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-dark {
  color: #343a40 !important;
}

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

/* line 51, node_modules/bootstrap/scss/utilities/_text.scss */
.text-body {
  color: #696969 !important;
}

/* line 52, node_modules/bootstrap/scss/utilities/_text.scss */
.text-muted {
  color: #6c757d !important;
}

/* line 54, node_modules/bootstrap/scss/utilities/_text.scss */
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

/* line 55, node_modules/bootstrap/scss/utilities/_text.scss */
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

/* line 59, node_modules/bootstrap/scss/utilities/_text.scss */
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

/* line 63, node_modules/bootstrap/scss/utilities/_text.scss */
.text-decoration-none {
  text-decoration: none !important;
}

/* line 65, node_modules/bootstrap/scss/utilities/_text.scss */
.text-break {
  word-wrap: break-word !important;
}

/* line 71, node_modules/bootstrap/scss/utilities/_text.scss */
.text-reset {
  color: inherit !important;
}

/* line 7, node_modules/bootstrap/scss/utilities/_visibility.scss */
.visible {
  visibility: visible !important;
}

/* line 11, node_modules/bootstrap/scss/utilities/_visibility.scss */
.invisible {
  visibility: hidden !important;
}

.team-card-size {
  height: 92% !important;
}

.team-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 50%;
}

/*# sourceMappingURL=../../../scss */
/*# sourceMappingURL=main.css.map */

.curr-pointer {
  cursor: pointer !important;
}

.pharmacy-single-promo-card {
  min-height: 385px !important;
  cursor: pointer;
}
.pipeline-single-blog-card {
  min-height: 520px !important;
}

.team-profile-image {
  background-size: cover !important;
  background-position: top center !important;
  width: 125px !important;
  height: 125px !important;
  border-radius: 5px !important;
}

.team-detail-image {
  background-size: cover !important;
  background-position: top center !important;
  width: 100% !important;
  height: 500px !important;
  border-radius: 5px !important;
}

.email-link {
  font-family: "Open Sans", sans-serif;
  font-size: 0.938rem;
  font-weight: 400;
  color: #696969 !important;
}

.contact_cards_wrapper {
  height: 250px !important;
}

.laris_card_editions {
  list-style: disc !important;
  padding: 0px 15px !important;
}

.dashboard-tabs .navbar {
  justify-content: center;
  border: 1px solid #ccc;
}
.dashboard-tabs .navbar .nav-link{
  color: #333 !important;
  border: 1px solid #ccc !important;
  padding: 10px 40px;
  
  
}
.dashboard-tabs .navbar .nav-link.active{
  background: linear-gradient(75deg, #0c2888, #6294f7);
  border: 1px solid #6294f7 !important;
  color: #fff !important;
}
.dashboard-tabs .dropdown-item.active{
  
  background: linear-gradient(75deg, #0c2888, #6294f7);
}

.active_dropdown-link {
  color: white !important; /* Ensure the color is applied */
}
